import React, { useContext, useMemo } from 'react';
import {
  Alert,
  AlertTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import { BranchesEdiShape, DBBranchResponse } from '@tyrio/dto';
import { PriceComparisonContext } from '../context';
import {
  DBBranchType,
  DBSupplier,
  DBSupplierClientSettings,
} from '@prisma/client';
import _ from 'lodash';

interface OrderBranchSelectorProps {
  clientSupplier: DBSupplierClientSettings & { supplier: DBSupplier | null };
  branches?: DBBranchResponse[];
  clientSupplierId: string;
}
export const OrderBranchSelector = (props: OrderBranchSelectorProps) => {
  const ctx = useContext(PriceComparisonContext);

  const selectedBranch = (ctx.data.orderDeliveryBranches ?? {})[
    props.clientSupplierId
  ];

  const serviceAddressBranch = useMemo(() => {
    return (ctx.data.serviceAddressBranches ?? {})[props.clientSupplierId];
  }, [ctx.data.serviceAddressBranches, props.clientSupplierId]);

  const branchDetails = props.branches?.find((b) => b.id === selectedBranch);

  const branches = props.branches;
  const hasConfiguredBranches = !!(branches?.length && branches?.length > 0);

  const warehouseBranches = useMemo(() => {
    return (
      branches?.filter((b) => b.branchType.includes(DBBranchType.WAREHOUSE)) ??
      []
    );
  }, [branches]);

  const setSelectedBranch = (
    key: 'orderDeliveryBranches' | 'serviceAddressBranches',
    value: number
  ) => {
    ctx.setInputValue({
      [key]: {
        ...(ctx.data?.[key] ?? {}),
        [props.clientSupplierId]: Number(value),
      },
    });
  };

  return (
    <Grid container spacing={2} sx={{ paddingTop: 2, marginBottom: 2 }}>
      <Grid item xs={4}>
        {hasConfiguredBranches && (
          <DropdownWrapper>
            <BranchesDropdown
              label="Delivery address"
              id={`delivery-address-${props.clientSupplierId}`}
              branches={branches?.filter((branch) => {
                const config = _.get(
                  props.clientSupplier,
                  `branchEdiConfiguration.${branch.id}`
                ) as unknown as BranchesEdiShape;

                return !!config?.partyId;
              })}
              selectedBranch={selectedBranch}
              onChange={(e) => {
                if (selectedBranch === undefined) {
                  setSelectedBranch(
                    'serviceAddressBranches',
                    Number(e.target.value)
                  );
                }

                setSelectedBranch(
                  'orderDeliveryBranches',
                  Number(e.target.value)
                );
              }}
            />
            <BranchesDropdown
              label="Service address"
              id={`service-address-${props.clientSupplierId}`}
              branches={warehouseBranches}
              selectedBranch={serviceAddressBranch}
              onChange={(e) => {
                setSelectedBranch(
                  'serviceAddressBranches',
                  Number(e.target.value)
                );
              }}
            />
          </DropdownWrapper>
        )}
        {!hasConfiguredBranches && (
          <Alert severity="warning">
            <AlertTitle>Please configure delivery branches.</AlertTitle>
            Before you can place an order with a supplier, you must configure
            the branches with your supplier settings. First, you need to create
            branches under <strong>{'Settings > Branches'}</strong>, and then
            for suppliers which support EDI orders, please configure your EDI
            keys in <strong>{'Settings > EDI Order configuration'}</strong>.
          </Alert>
        )}
      </Grid>
      <Grid item xs={6}>
        {branchDetails && (
          <AddressContainer>
            {branchDetails?.branchName && <h2>{branchDetails.branchName}</h2>}
            {branchDetails?.address && <p>{branchDetails.address}</p>}
            {branchDetails?.zipCode && (
              <p>
                {branchDetails.zipCode}
                {branchDetails?.city && <>, {branchDetails.city}</>}
                {branchDetails?.country && <>, {branchDetails.country.name}</>}
              </p>
            )}
            {branchDetails?.businessPhone && (
              <p>{branchDetails.businessPhone}</p>
            )}
            {branchDetails?.email && <p>{branchDetails.email}</p>}
          </AddressContainer>
        )}
        {!!(!branchDetails && hasConfiguredBranches) && (
          <Alert severity="info">
            <AlertTitle>Please select a branch for delivery.</AlertTitle>
            Before the app can inquire for prices and dates, you must select the
            exact branch you want the products delivered to. Prices and dates
            may vary between branches, which depends completely on suppliers.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

export const BranchesDropdown = ({
  label,
  id,
  branches,
  selectedBranch,
  onChange,
}: {
  label: string;
  id: string;
  branches: DBBranchResponse[];
  selectedBranch: number;
  onChange: (val: SelectChangeEvent<number>) => void;
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}`}>{label}</InputLabel>
      <Select
        labelId={`${id}`}
        id={`${id}`}
        value={selectedBranch ?? ''}
        label={label}
        disabled={branches?.length === 0}
        onChange={(e) => {
          onChange(e);
        }}
      >
        {branches?.map((branch) => (
          <MenuItem value={branch.id} key={branch.id}>
            {branch.branchName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AddressContainer = styled.div`
  padding: 16px;

  background: #f4f6f8;
  border-radius: 16px;
  min-height: 130px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px 0;

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #212b36;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0px 0;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* Primary/Black/black-100 */

    color: #212b36;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
