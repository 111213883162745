import styled from '@emotion/styled/macro';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { WarehouseLocations } from '@tyrio/dto';
import { LandingPage, backIcon, tyrioIcons } from '@tyrio/ui-library';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WarehouseContext } from '../../context/WarehouseContext';
import { SelectCode } from './components/code-selection/SelectCode';
import MinimalLocation from './components/location/MininalLocation';
import PrintSticker from './components/sticker/PrintSticker';
import { filterLocations, format, getCurrent } from './helper/helper';
import { useWarehouseHelper } from './helper/warehouse-helper';
import { useGetWarehouses } from './query/get-branches';
import { useGetWarehouse } from './query/warehouse';
import { BinCard } from './warehouse-cards/BinCard';
import FloorCard from './warehouse-cards/FloorCard';
import SectionCard from './warehouse-cards/SectionCard';
import { SubzoneCard } from './warehouse-cards/SubzoneCard';
import { ZoneCard } from './warehouse-cards/ZoneCard';

const Overview = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();

  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [search, setSearch] = useState('');

  const {
    activeStep,
    setActiveStep,
    selected,
    setSelected,
    warehouse,
    setWarehouse,
    clearSelected,
    isNewModalOpen,
    setIsNewModalOpen,
  } = useContext(WarehouseContext);

  const {
    warehouseAreaId,
    initFloor,
    initRack,
    initSection,
    initShelf,
    initBin,
    _setWarehouse,
  } = useWarehouseHelper();

  const { warehouses, warehouseData } = useGetWarehouses({
    setSelectedWarehouse: setSelectedWarehouse,
  });

  useGetWarehouse(selectedWarehouse);

  const last = () => {
    return _.last(selected.current);
  };

  const set = (att: WarehouseLocations, index: number) => {
    if (last() === att || att === 'floor' || att === 'section') return;

    const currentSelected = selected.current.filter((_item, i) => i <= index);

    const temp = selected.locations;

    if (att === 'rack' || att === 'shelf') index--;

    Object.keys(temp).forEach((item, i) => {
      if (index <= i) _.set(temp, item, null);
    });

    setSelected({
      current: currentSelected,
      locations: temp,
    });
  };

  const getCurrentLocation = () => {
    if (!selected.locations.subzone) {
      if (selected.locations.zone?.code === 'X') return 'FITTING';

      return `ZONE ${getCurrent(selected, 'displayName')}`;
    }

    return `${getCurrent(selected, 'displayName')}`;
  };

  const getCurrentLocationLabel = () => {
    if (!selected.locations.subzone) return 'CURRENT ZONE';

    return `${format(getCurrent(selected, 'stagingType'))}`;
  };

  const filter = (val: string) => {
    if (warehouse) {
      const filtered = filterLocations(val, warehouse);

      setSelected(filtered);

      setSearch(val);
    }
  };

  return (
    <Container>
      {isNewModalOpen && <SelectCode />}
      <PrintSticker open={open} onClose={handleClose} />
      <HeaderWrapperTop>
        <Grid>
          <BackButton onClick={() => history.push('/dashboard')}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Warehouse settings</HeaderText>
        </Grid>
        {activeStep !== 'UPLOAD' && (
          <HeaderButtonsWrapper>
            <Button
              variant="text"
              color="info"
              startIcon={<PrintIcon />}
              onClick={handleOpen}
            >
              PRINT LABELS
            </Button>
            <Button
              variant="text"
              color="info"
              startIcon={<UploadIcon />}
              onClick={() => {
                setActiveStep('UPLOAD');
                setWarehouse(null);
                clearSelected();
              }}
            >
              IMPORT
            </Button>
          </HeaderButtonsWrapper>
        )}
      </HeaderWrapperTop>
      <Divider />
      <ContentContainer>
        {_.isEmpty(warehouse) && (
          <LandingPage
            icon={tyrioIcons.warehouse}
            title={'Welcome to Warehouse settings'}
            subtitle={'Please upload your warehouse excel file'}
          />
        )}
        {!_.isEmpty(warehouse) && (
          <Grid>
            {activeStep === 'UPLOAD' && (
              <Alert severity="warning">
                <AlertTitle>This is a warehouse preview.</AlertTitle>
                If everything is correct and no corrections are needed, you{' '}
                <strong>must press the save button</strong> to store this in the
                database!
              </Alert>
            )}
            <Grid>
              {activeStep !== 'UPLOAD' && (
                <Grid sx={{ display: 'flex', gap: '10px' }}>
                  <Grid sx={{ width: '50%' }}>
                    <TextField
                      value={search}
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      onChange={(e) => {
                        filter(e.target.value);
                      }}
                    />
                  </Grid>
                  {warehouseData.status === 'success' && warehouseData.data && (
                    <Grid sx={{ width: '50%' }}>
                      <FormControl fullWidth>
                        <InputLabel>Branch</InputLabel>
                        <Select
                          value={selectedWarehouse}
                          label="Branch"
                          onChange={(e) => {
                            setSelectedWarehouse(e.target.value);
                            clearSelected();
                          }}
                        >
                          {warehouses.map((s) => (
                            <MenuItem key={s.value} value={s.value}>
                              {s.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              )}
              <ContentHeader>
                <Breadcrumbs aria-label="breadcrumb">
                  {selected.current.map((item, index) => {
                    return (
                      item !== 'floor' &&
                      item !== 'section' && (
                        <Breadcrumb
                          key={item}
                          active={item !== last()}
                          onClick={() => {
                            set(item as WarehouseLocations, index);
                          }}
                        >
                          {_.capitalize(`${item}s`)}
                        </Breadcrumb>
                      )
                    );
                  })}
                </Breadcrumbs>
                {activeStep !== 'UPLOAD' && (
                  <Button
                    variant="text"
                    color="info"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setActiveStep('DETAILS');

                      if (!warehouseAreaId) return;

                      if (last() === 'zone') {
                        setIsNewModalOpen(true);
                      }

                      if (last() === 'subzone') {
                        setIsNewModalOpen(true);
                      }

                      if (last() === 'rack') {
                        const { floor, updated } = initFloor();

                        const { rack } = initRack(floor, updated);

                        _setWarehouse({ floor, rack }, updated);
                      }

                      if (last() === 'shelf') {
                        const { section, updated } = initSection();

                        const { shelf } = initShelf(section, updated);

                        _setWarehouse({ section, shelf }, updated);
                      }

                      if (last() === 'bin') {
                        const { bin, updated } = initBin();

                        _setWarehouse({ bin }, updated);
                      }
                    }}
                  >
                    ADD{' '}
                    {_.upperCase(
                      `${
                        last() === 'rack'
                          ? 'FLOOR'
                          : last() === 'shelf'
                          ? 'SECTION'
                          : last()
                      }`
                    )}
                  </Button>
                )}
              </ContentHeader>
            </Grid>

            {selected.locations.zone && (
              <Grid sx={{ marginBottom: '20px' }}>
                <MinimalLocation
                  location={getCurrentLocation()}
                  stagingType={getCurrentLocationLabel()}
                  color={getCurrent(selected, 'color')}
                />
              </Grid>
            )}

            <ZoneSubzoneWrapper>
              {!warehouse?.zones && (
                <Alert sx={{ width: '100%' }} severity="info">
                  There is no warehouse zones!
                </Alert>
              )}
              {last() === 'zone' &&
                warehouse?.zones?.map((zone) => {
                  const subzones = warehouse.subzones?.filter(
                    (subzone) => subzone.zoneId === zone.id
                  );
                  const subzoneTypes = [
                    ...new Set(
                      subzones?.map(
                        (item) =>
                          format(_.get(item.settings, 'stagingType')) as string
                      )
                    ),
                  ];

                  return (
                    <ZoneCard
                      key={zone.code}
                      zone={zone}
                      subzonesTypes={subzoneTypes ?? []}
                      subzonesCount={subzones?.length}
                    />
                  );
                })}
              {last() === 'subzone' &&
                warehouse?.subzones
                  ?.filter(
                    (item) => item.zoneId === selected.locations.zone?.id
                  )
                  .map((subzone) => {
                    return (
                      <SubzoneCard
                        key={subzone.code}
                        subzone={subzone}
                        floorsCount={
                          warehouse.floors?.filter(
                            (floor) => floor.subzoneId === subzone.id
                          ).length
                        }
                      />
                    );
                  })}
              {last() === 'rack' && (
                <RackFloorContainer>
                  <RackFloorContainer>
                    {warehouse?.floors
                      ?.filter(
                        (item) =>
                          item.subzoneId === selected.locations.subzone?.id
                      )
                      .map((floor) => {
                        return (
                          <FloorCard
                            key={floor.code}
                            floor={floor}
                            racks={warehouse?.racks?.filter(
                              (item) => item.floorId === floor.id
                            )}
                          />
                        );
                      })}
                  </RackFloorContainer>
                </RackFloorContainer>
              )}
              {last() === 'shelf' && (
                <RackFloorContainer>
                  <RackFloorContainer>
                    {warehouse?.sections
                      ?.filter(
                        (item) => item.rackId === selected.locations.rack?.id
                      )
                      .map((section) => {
                        return (
                          <SectionCard
                            key={section.code}
                            section={section}
                            shelfs={warehouse?.shelfs?.filter(
                              (item) => item.sectionId === section.id
                            )}
                          />
                        );
                      })}
                  </RackFloorContainer>
                </RackFloorContainer>
              )}
              {last() === 'bin' && (
                <BinsWrapper>
                  {warehouse?.bins?.filter(
                    (item) => item.shelfId === selected.locations.shelf?.id
                  ).length === 0 ? (
                    <Alert severity="info" sx={{ width: '100%' }}>
                      No bins on this shelf!
                    </Alert>
                  ) : (
                    warehouse?.bins
                      ?.filter(
                        (item) => item.shelfId === selected.locations.shelf?.id
                      )
                      .map((bin) => {
                        return <BinCard bin={bin} />;
                      })
                  )}
                </BinsWrapper>
              )}
            </ZoneSubzoneWrapper>
          </Grid>
        )}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 16px;
  transition: linear 0.4s;
  position: relative;
  left: 0;
  height: calc(100svh - 44px);
  width: 100%;
  overflow: scroll;
`;

const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const HeaderButtonsWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HeaderWrapperTop = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

const ContentContainer = styled.div`
  padding: 16px;
  max-height: (100svh - 44px);
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ZoneSubzoneWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const BinsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Breadcrumb = styled.span<{ active: boolean }>`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? '500' : '600')};
  &:hover {
    text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
  }
`;

const RackFloorContainer = styled.div`
  width: 100%;
  gap: 16px;
`;

export default Overview;
