import * as z from "zod"
import { DBRebateGroupType, DBCurrency, DBInvoiceType, DBPartnerSupplierType, DBParnerDeliveryTermsTypes, DBTrafficType, DBLanguage } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBCountry, RelatedDBCountryModel, CompleteDBPartnerContact, RelatedDBPartnerContactModel, CompleteDBPartnerBank, RelatedDBPartnerBankModel, CompleteDBClientRebateCalculation, RelatedDBClientRebateCalculationModel, CompleteDBPartnerLocation, RelatedDBPartnerLocationModel, CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel, CompleteDBInboundInvoice, RelatedDBInboundInvoiceModel } from "./index"

export const DBPartnerModel = z.object({
  id: z.string(),
  clientId: z.string(),
  active: z.boolean(),
  companyOfficialName: z.string(),
  businessType: z.string(),
  companyDisplayName: z.string().nullish(),
  vatPostingGroup: z.string(),
  viesValidated: z.boolean(),
  companyRegistrationNumber: z.string(),
  viesVatNumber: z.string(),
  zipCode: z.string(),
  city: z.string(),
  address: z.string(),
  remark: z.string(),
  countryId: z.string().nullish(),
  customerPartnerType: z.boolean(),
  supplierPartnerType: z.boolean(),
  customerType: z.nativeEnum(DBRebateGroupType).nullish(),
  rebateCalculationId: z.string().nullish(),
  paymentDelay: z.number().int().nullish(),
  creditLimit: z.number().nullish(),
  currency: z.nativeEnum(DBCurrency).nullish(),
  invoices: z.nativeEnum(DBInvoiceType).array(),
  emails: z.string().array(),
  supplierType: z.nativeEnum(DBPartnerSupplierType).nullish(),
  deliveryTermsType: z.nativeEnum(DBParnerDeliveryTermsTypes).nullish(),
  transactionNature: z.number().int().nullish(),
  transportMode: z.nativeEnum(DBTrafficType).nullish(),
  paymentTerms: z.string().nullish(),
  exclusivePartnership: z.boolean().nullish(),
  createB2BAccount: z.boolean().nullish(),
  language: z.nativeEnum(DBLanguage).nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  email: z.string().nullish(),
  mobilePhone: z.string().nullish(),
  businessPhone: z.string().nullish(),
  b2bRemark: z.string().nullish(),
  customerVat: z.string().nullish(),
  supplierVat: z.string().nullish(),
  erpId: z.string().nullish(),
  supplierId: z.string().nullish(),
})

export interface CompleteDBPartner extends z.infer<typeof DBPartnerModel> {
  client: CompleteDBClient
  country?: CompleteDBCountry | null
  DBPartnerContact: CompleteDBPartnerContact[]
  DBPartnerBank: CompleteDBPartnerBank[]
  rebateCalculation?: CompleteDBClientRebateCalculation | null
  DBPartnerLocation: CompleteDBPartnerLocation[]
  supplier?: CompleteDBSupplierClientSettings | null
  inboundInvoices: CompleteDBInboundInvoice[]
}

/**
 * RelatedDBPartnerModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBPartnerModel: z.ZodSchema<CompleteDBPartner> = z.lazy(() => DBPartnerModel.extend({
  client: RelatedDBClientModel,
  country: RelatedDBCountryModel.nullish(),
  DBPartnerContact: RelatedDBPartnerContactModel.array(),
  DBPartnerBank: RelatedDBPartnerBankModel.array(),
  rebateCalculation: RelatedDBClientRebateCalculationModel.nullish(),
  DBPartnerLocation: RelatedDBPartnerLocationModel.array(),
  supplier: RelatedDBSupplierClientSettingsModel.nullish(),
  inboundInvoices: RelatedDBInboundInvoiceModel.array(),
}))
