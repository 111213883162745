import { PermissionsValues, UserRolePermissions } from '@tyrio/dto';

interface UserPermission {
  id: string | number;
  label: string;
  values: PermissionsValues;
  childs?: UserPermission[] | Record<string, UserPermission>;
}

export function mergePermissions(
  defaultPermissions: UserRolePermissions[],
  userPermissions: UserPermission[]
): UserRolePermissions[] {
  if (!userPermissions || userPermissions.length === 0) {
    return [...defaultPermissions];
  }

  const findAndMerge = (
    defaultItem: UserRolePermissions,
    userItem: UserPermission
  ) => {
    if (defaultItem.label === userItem.label) {
      defaultItem.values = { ...defaultItem.values, ...userItem.values };

      if (defaultItem.childs && userItem.childs) {
        if (Array.isArray(userItem.childs)) {
          userItem.childs.forEach((userChild) => {
            const correspondingChild = defaultItem.childs?.find(
              (child) => child.label === userChild.label
            );
            if (correspondingChild) {
              findAndMerge(correspondingChild, userChild);
            }
          });
        } else {
          Object.values(userItem.childs).forEach((userChild) => {
            const correspondingChild = defaultItem.childs?.find(
              (child) => child.label === userChild.label
            );
            if (correspondingChild) {
              findAndMerge(correspondingChild, userChild);
            }
          });
        }
      }
    }
  };

  const merged = [...defaultPermissions];
  userPermissions.forEach((userItem) => {
    const correspondingItem = merged.find(
      (defaultItem) => defaultItem.label === userItem.label
    );
    if (correspondingItem) {
      findAndMerge(correspondingItem, userItem);
    }
  });

  return merged;
}
