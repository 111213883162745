import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBSupplierClientSettingsApi } from '@tyrio/dto';

export const useClientSuppliersQuery = (
  supplierIds?: string[],
  enabled?: boolean
) => {
  const data = useQuery(
    [
      'get_supplier_client_settings',
      ...(supplierIds ?? []).map((s) => `supplier_client_settings_${s}`),
    ],
    async () => {
      return await api.fetch<DBSupplierClientSettingsApi['list']>(
        'get_supplier_client_settings',
        {
          includeB2B: 'true',
          search: '',
          supplierIds: supplierIds,
          pageSize: 100,
        }
      );
    },
    {
      enabled: enabled ?? true,
    }
  );

  return data;
};
