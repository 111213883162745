import { DBRepeatUnit } from '@prisma/client';
import { DBSupplierConnectionApi } from '@tyrio/dto';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConnectionDetails = (c: any, itemValue: any) => {
  return {
    id: c.id,
    connectionName: c.connectionName,
    connectionTypes: c.connectionTypes,
    active: c.active,
    connectionProtocol: c.connectionProtocol,
    fileFormat: c.fileFormat,
    isFileZipped: c.isFileZipped,
    rulesetKey: c.rulesetKey,
    remark: c.remark,
    ftpConnection: c.ftpConnection
      ? {
          id: c.ftpConnection?.id,
          supplierFileName: itemValue.connectionDetails.fileName ?? '',
          fileLocation: itemValue.connectionDetails.fileName ?? '',
          ftpHost: itemValue.connectionDetails.ftpHost ?? '',
          ftpPort: itemValue.connectionDetails.ftpPort ?? '',
          ftpUserName: itemValue.connectionDetails.username ?? '',
          ftpPassword: itemValue.connectionDetails.password ?? '',
          startUpdateTime: itemValue.connectionDetails.startUpdateTime ?? '',
          repeatEvery: itemValue.connectionDetails.repeatEvery ?? '',
          repeatUntil: itemValue.connectionDetails.repeatUnit ?? '',
          remark: itemValue.connectionDetails.remark ?? '',
        }
      : undefined,
    apiConnection: c.apiConnection
      ? {
          apiClientId: itemValue.connectionDetails.apiClientId,
          apiKey: itemValue.connectionDetails.apiKey,
          apiClientSecret: itemValue.connectionDetails.apiClientSecret,
          apiUrl: itemValue.connectionDetails.url,
          remark: itemValue.connectionDetails.remark,
        }
      : undefined,
    httpsConnection: c.httpsConnection
      ? {
          httpsUrl: itemValue.connectionDetails.url,
          username: itemValue.connectionDetails.username,
          password: itemValue.connectionDetails.password,
          remark: itemValue.connectionDetails.remark,
          startUpdateTime: itemValue.connectionDetails.startUpdateTime ?? '',
          repeatEvery: itemValue.connectionDetails.repeatEvery ?? 5,
          repeatUntil:
            itemValue.connectionDetails.repeatUnit ?? DBRepeatUnit.HOURS,
        }
      : undefined,
    emailConnection: undefined,
    minimumUpdateFrequencyUnit: c.minimumUpdateFrequencyUnit ?? undefined,
    minimumUpdateFrequencyTime: c.minimumUpdateFrequencyTime ?? undefined,
  } as DBSupplierConnectionApi['create']['requestBody'];
};
