import { LandingPage, SettingsMenuList, tyrioIcons } from '@tyrio/ui-library';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { companySettingsMenuLinks } from '../../../../constants/companySettingsMenu.constants';
import CompanySettingsMenuSwitch from '../../features/company/CompanySettingsSwitchRouter';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import routeNames from '../../lib/routeNames';
import ShippingCourierNew from './shipping-couriers/ShippingCourierNew';
import { useGetAllShippingCouriers } from './shipping-couriers/query/get-all-couriers';
import { useGetShippingCouriers } from './shipping-couriers/query/get-shipping-couriers';
import { customToast } from '../../features/stock-list/components/Cart/CartToast';
import { PermissionsValues } from '@tyrio/dto';

interface DashboardCompanySettingsProps {
  children: React.ReactNode;
  permissionList: PermissionsValues;
}

type LandingPageConstantsType = Record<
  string,
  {
    title: string;
    subtitle: string;
    buttonText?: string;
    onClick?: () => void;
    icon?: string;
  }
>;

const LandingPageConstants = (
  setIsShippingNewModalOpen: (value: boolean) => void
) => {
  const history = useHistory();

  const { allCouriers } = useGetAllShippingCouriers();
  const { couriersData } = useGetShippingCouriers();

  const canAddNewCourier =
    allCouriers.length !== couriersData.length ? true : false;

  const obj: LandingPageConstantsType = {
    settings: {
      title: 'Welcome to settings',
      subtitle: 'Choose item from the list.',
    },
    payment_method: {
      title: 'Welcome to Payment Method',
      subtitle: 'Edit default payment types or add new',
      buttonText: 'Add new',
      onClick: () =>
        history.push(`/dashboard/company-settings/payment-method/new`),
      icon: tyrioIcons.payment,
    },
    delivery_method: {
      title: 'Welcome to Delivery Methods',
      subtitle: 'Edit default delivery methods or add new',
      buttonText: 'Add new',
      onClick: () =>
        history.push(`/dashboard/company-settings/delivery-method/new`),
      icon: tyrioIcons.deliveryIconWhite,
    },
    couriers: {
      title: 'Welcome to Shipping Couriers',
      subtitle: 'Edit shipping couriers or add new',
      icon: tyrioIcons.deliveryIconWhite,
      buttonText: 'Add new',
      onClick: () => {
        if (canAddNewCourier) setIsShippingNewModalOpen(true);
        else customToast('All available couriers are added', 'error');
      },
    },
    roles: {
      title: 'Welcome to Roles',
      subtitle: 'Choose role from the list or add new',
      buttonText: 'Add new',
      onClick: () => history.push(`/dashboard/company-settings/roles/new`),
      icon: tyrioIcons.usersIcon,
    },
    branches: {
      title: 'Welcome to Branches settings',
      subtitle: 'Choose branch from the list or create new.',
      buttonText: 'Create new branch',
      onClick: () => history.push(`/dashboard/company-settings/branches/new`),
      icon: tyrioIcons.companyBranchesIconeWhite,
    },
    users: {
      title: 'Welcome to User settings',
      subtitle: 'Choose user from the list or create new.',
      buttonText: 'Add new user',
      onClick: () => history.push(`/dashboard/company-settings/users/new`),
      icon: tyrioIcons.menuIcon4,
    },
  };

  return obj;
};

const DashboardCompanySettings = ({
  children,
  permissionList,
}: DashboardCompanySettingsProps) => {
  const location = useLocation();

  const [switchMenu, setSwitchMenu] = useState(true);

  useEffect(() => {
    let menuSwitchDisabled = true;

    const disabledSwitchMenuList = [
      '/branches',
      '/roles',
      '/payment-method',
      '/users',
      '/delivery-method',
      '/shipping-couriers',
    ];

    disabledSwitchMenuList.forEach((item) => {
      if (location.pathname.includes(item)) menuSwitchDisabled = false;
    });

    setSwitchMenu(menuSwitchDisabled);
  }, [location.pathname]);

  const showPaymentLanding = location.pathname.includes('payment-method');
  const showRolesLanding = location.pathname.includes('roles');
  const showUsersLanding = location.pathname.includes('users');
  const showDeliveryLanding = location.pathname.includes('delivery-method');
  const showShippingCouriersLanding =
    location.pathname.includes('shipping-couriers');

  const showComponent = switchMenu
    ? 'settings'
    : showShippingCouriersLanding
    ? 'couriers'
    : showPaymentLanding
    ? 'payment_method'
    : showDeliveryLanding
    ? 'delivery_method'
    : showRolesLanding
    ? 'roles'
    : showUsersLanding
    ? 'users'
    : 'branches';

  const [isShippingNewModalOpen, setIsShippingNewModalOpen] =
    useState<boolean>(false);
  const { title, subtitle, buttonText, icon, onClick } = LandingPageConstants(
    setIsShippingNewModalOpen
  )[showComponent];

  return (
    <ExpandableLayout
      sidebarComponent={
        switchMenu ? (
          <SettingsMenuList
            permissionList={permissionList}
            header="Settings"
            content={companySettingsMenuLinks}
            path={routeNames.dashboard()}
            isFetching={false}
            isError={false}
            setSwitchMenu={setSwitchMenu}
          />
        ) : (
          <CompanySettingsMenuSwitch
            setSwitchMenu={setSwitchMenu}
            switchMenu={switchMenu}
            setIsModalOpen={setIsShippingNewModalOpen}
          />
        )
      }
      landingPage={
        <LandingPage
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          icon={icon}
          onClick={onClick}
        />
      }
      modal={
        isShippingNewModalOpen && (
          <ShippingCourierNew
            setIsModalOpen={setIsShippingNewModalOpen}
            isModalOpen={isShippingNewModalOpen}
          />
        )
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default DashboardCompanySettings;
