import { sharedVars } from '@tyrio/shared-vars';
import { FormShape, TyrioSelectInputOption } from '../types';
import { createInput } from '../utils';
import { zodUserObject } from './zodObjects';

export const generateUsersForm = (
  roles: TyrioSelectInputOption[],
  userRolePermission: boolean,
  disableEditFields: boolean,
  branches: TyrioSelectInputOption[]
) => {
  const zodSchema = zodUserObject;
  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Main',
        inputs: [
          createInput({
            id: 'role',
            width: { xs: 12, lg: 6 },
            type: 'input.select',
            options: roles,
            label: 'User role *',
            disabled: userRolePermission,
          }),
          createInput({
            id: 'language',
            width: { xs: 12, lg: 6 },
            type: 'input.select',
            options: sharedVars.languages.languageOptions,
            label: 'Language',
            required: true,
          }),
          createInput({
            id: 'firstName',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'First name',
            disabled: userRolePermission,
            required: true,
          }),
          createInput({
            id: 'lastName',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Last name',
            disabled: userRolePermission,
            required: true,
          }),
          createInput({
            id: 'email',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'E-mail',
            disabled: disableEditFields,
            required: true,
          }),
          createInput({
            id: 'mobilePhone',
            width: { xs: 12, lg: 4 },
            type: 'input.phone',
            label: 'Phone number *',
            disabled: disableEditFields,
          }),
          createInput({
            id: 'businessPhone',
            width: { xs: 12, lg: 4 },
            type: 'input.phone',
            label: 'Business Phone',
          }),
          createInput({
            id: 'personalIdentificationNumber',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Personal Identification Number',
            disabled: userRolePermission,
            required: true,
          }),
          createInput({
            id: 'birthday',
            width: { xs: 12, lg: 6 },
            mode: 'calendardate',
            type: 'input.datepicker',
            label: 'Birthday',
          }),
          createInput({
            id: 'remark',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Remark',
            disabled: userRolePermission,
            hidden: userRolePermission,
          }),
          createInput({
            id: 'pin',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'PIN',
            disabled: userRolePermission,
            required: true,
          }),
        ],
      },
      {
        tabTitle: 'Branches',
        inputs: [
          createInput({
            id: 'mainBranchId',
            width: { xs: 12, lg: 6 },
            type: 'input.select',
            options: branches,
            label: 'Main branch *',
            disabled: userRolePermission,
            watchKey: 'secondaryBranches',
          }),
          createInput({
            id: 'currentBranchId',
            width: { xs: 12, lg: 6 },
            type: 'input.select',
            options: branches,
            label: 'Current branch *',
            disabled: userRolePermission,
            required: false,
            hidden: true,
          }),
          createInput({
            id: 'secondaryBranches',
            width: { xs: 12, lg: 10 },
            type: 'input.multiselect',
            options: branches,
            label: 'Secondary branches',
            disabled: userRolePermission,
            watchKey: 'mainBranchId',
          }),
          createInput({
            id: 'branch',
            width: { xs: 12, lg: 12 },
            type: 'input.checkbox',
            label: 'User can’t edit or create documents for secondary branches',
          }),
        ],
      },
    ],
  };
  return { form, zodSchema };
};
