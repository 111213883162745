import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton/IconButton';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DiscardModal, tyrioIcons } from '../../../index';
import _ from 'lodash';
import { PermissionsValues } from '@tyrio/dto';

interface MenuItemProps {
  id?: string;
  link?: string;
  source: string;
  label: string;
  isOpen: boolean;
  permissionList: PermissionsValues;
  subcategories?: {
    link: string;
    label: string;
    isVisible: boolean;
    permissionPath: string;
  }[];
  color?: string;
  selectedMenuItem?: string;
}

const MenuItem = ({
  id,
  source,
  label,
  link,
  isOpen,
  subcategories,
  color,
  selectedMenuItem,
  permissionList,
}: MenuItemProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const locationMatch = location.pathname === link;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const menuItemHeight = isOpen ? '100%' : '50px';
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const isSalesOrder = location.pathname.includes('sales-orders');
  const [nextRoute, setNextRoute] = useState<string | null>(null);
  const history = useHistory();

  let dropdownMenus = ['Catalogues', 'WMS', 'Settings', 'Purchasing', 'Sales'];

  dropdownMenus = dropdownMenus.concat(dropdownMenus.map((item) => t(item)));

  useEffect(() => {
    if (selectedMenuItem && id && subcategories && selectedMenuItem === id)
      setOpen(true);
    else setOpen(false);
  }, [id, selectedMenuItem, subcategories]);

  useEffect(() => {
    if (
      label === 'Shipping Couriers' ||
      label === 'Attributes' ||
      label === 'Countries'
    )
      setIsComingSoon(true);
    else setIsComingSoon(false);

    if (locationMatch && subcategories) setSelected(true);
    else setSelected(false);
  }, [subcategories, setSelected, selected, locationMatch, label]);

  const handleModalConfirm = () => {
    setShowWarningModal(false);
    if (nextRoute) {
      history.push(nextRoute);
      setNextRoute(null);
    }
  };

  return (
    <>
      {showWarningModal && (
        <DiscardModal
          LBAction={() => setShowWarningModal(false)}
          RBAction={handleModalConfirm}
          text={'Your order is in process'}
        />
      )}
      <Container
        open={isOpen}
        style={{
          height: menuItemHeight,
          alignItems:
            dropdownMenus.includes(label) && open ? 'flex-start' : 'center',
        }}
      >
        <ImageWrapper>
          <Image
            src={source}
            alt="icon"
            style={{
              opacity: !isComingSoon ? '1' : '0.3',
              width:
                label === 'Suppliers' || label === 'Payment methods'
                  ? '23px'
                  : '',
              filter:
                label === 'Users' || label === 'Roles'
                  ? 'invert(45%)'
                  : undefined,
            }}
          />
        </ImageWrapper>
        <AnimatePresence>
          {subcategories ? (
            <OpenedContainer
              open={isOpen}
              initial={{
                opacity: 0,
              }}
              animate={{ opacity: isOpen ? 1 : 0 }}
              exit={{ opacity: 0 }}
              data-cy={`navlink-${label}`}
              color={!isComingSoon ? color : 'gray'}
            >
              <CollapseContainer>
                <CollapseWrapper>
                  <b>{t(label)}</b>

                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {!open ? (
                      <KeyboardArrowDownIcon style={{ color: 'white' }} />
                    ) : (
                      <KeyboardArrowUpIcon style={{ color: 'white' }} />
                    )}
                  </IconButton>
                </CollapseWrapper>
                {open && (
                  <SubcaategoriesOpened>
                    {subcategories.map(
                      (el) =>
                        el.isVisible &&
                        _.get(permissionList, `${el.permissionPath}.view`) ===
                          true && (
                          <StyledNavLink
                            to={el.link}
                            key={el.link}
                            onClick={(e) => {
                              if (isSalesOrder) {
                                setShowWarningModal(true);
                                e.preventDefault();
                                if (setNextRoute) setNextRoute(el.link);
                              }
                            }}
                          >
                            <SubcategoryNavItem
                              key={el.label}
                              data-cy={`sidebar-child-${el.label}`}
                            >
                              {t(el.label)}
                              {(el.label === 'Attributes' ||
                                el.label === 'Countries') && (
                                <img
                                  src={tyrioIcons.comingSoonIcon}
                                  alt="coming-soon-icon"
                                  style={{
                                    position: 'absolute',
                                    right: '25px',
                                    opacity: '0.3',
                                    width: '50px',
                                  }}
                                />
                              )}
                            </SubcategoryNavItem>
                          </StyledNavLink>
                        )
                    )}
                  </SubcaategoriesOpened>
                )}
              </CollapseContainer>
            </OpenedContainer>
          ) : (
            <OpenedContainer
              open={isOpen}
              initial={{ opacity: 0 }}
              animate={{ opacity: isOpen ? 1 : 0 }}
              exit={{ opacity: 0 }}
              data-cy={`navlink-${label}`}
              color={!isComingSoon ? color : 'gray'}
            >
              {t(label)}
              {isComingSoon && (
                <img
                  src={tyrioIcons.comingSoonIcon}
                  alt="coming-soon-icon"
                  style={{
                    position: 'absolute',
                    right: '15px',
                    opacity: '0.3',
                    width: '50px',
                  }}
                />
              )}
            </OpenedContainer>
          )}
        </AnimatePresence>
      </Container>
    </>
  );
};

const StyledNavLink = styled(NavLink)`
  margin-bottom: 2px;

  &.active {
    background: rgba(255, 255, 255, 0.2);
    opacity: 1;
    border-radius: 8px;
  }
  :link {
    text-decoration: none;
  }
`;

const Container = styled(motion.div)<{ open: boolean }>`
  width: 100%;
  border: none;
  background: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  max-width: 230px;
  @media (max-width: 768px) {
    max-width: ${(prop) => prop.open && '100%'};
    width: 100%;
  }
`;

const CollapseWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled(motion.img)`
  margin: 0;
`;

const SubcaategoriesOpened = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const SubcategoryNavItem = styled.button`
  background: none;
  border: none;
  width: 100%;
  color: white;
  text-align: start;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;

  :hover {
    background: rgb(255, 255, 255, 0.05);
    cursor: pointer;
    border-radius: 10px;
  }
`;

const OpenedContainer = styled(motion.div)<{
  color?: string;
  isRouteActive?: boolean;
  open: boolean;
}>`
  text-decoration: none;
  ${(props) => (props.color && `color: ${props.color};`) || 'color: #ffffff;'};
  font-size: 14px;
  margin-left: 10px;
  min-width: 170px;
  display: flex;
  height: 100%;

  @media (max-width: 768px) {
    max-width: ${(prop) => prop.open && '100%'};
    width: 100%;
  }
`;

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default MenuItem;
