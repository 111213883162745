/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { ClientConnectionDataArray } from '@tyrio/dto';
import { useCallback, useState } from 'react';
import { ExpandCollapseButtons } from '../supplier-form/helpers/components';
import { ClientConnectionApiConfiguration } from './types/ClientConnectionApiConfiguration';
import { ClientConnectionFtpConfiguration } from './types/ClientConnectionFtpConfiguration';
import { ClientConnectionHttpsConfiguration } from './types/ClientConnectionHttpsConfiguration';
import { ConnectionConfigurationProps } from './typings';
import { ClientConnectionEmailConfiguration } from './types/ClientConnectionEmailConfiguration';
import { DBConnectionType } from '@prisma/client';

export const ConnectionConfiguration = ({
  data,
  getData,
  initialData,
  getValidationProps,
  clearErrors,
}: ConnectionConfigurationProps) => {
  const [connectionData, updateStateConnectionData] =
    useState<ClientConnectionDataArray>((initialData as any) || ([] as any[]));

  const [expandedData, setExpandedData] = useState<number[]>([]);

  const setConnectionData = useCallback(
    (d: ClientConnectionDataArray) => {
      updateStateConnectionData(d);
      getData(d);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectionData, getData]
  );

  const shouldShowButtons =
    data?.supplierConnections &&
    data?.supplierConnections.length > 0 &&
    (data?.supplierConnections.length !== 1 ||
      !data.supplierConnections[0].connectionTypes.includes('CATALOGUE'));

  return (
    <>
      {shouldShowButtons && (
        <ExpandCollapseButtons
          color={expandedData.length > 0 ? 'warning' : 'success'}
          onExpandedDataChange={() =>
            setExpandedData(
              expandedData.length > 0
                ? []
                : data?.supplierConnections?.map(
                    (_connection, index) => index
                  ) ?? []
            )
          }
          flag={expandedData.length > 0}
        />
      )}
      <Grid container spacing={2}>
        {data?.supplierConnections
          .filter(
            (c) => c.active // && !c.connectionTypes.includes(DBConnectionType.CATALOGUE)
          )
          .map((c, index) => {
            if (c.connectionTypes.includes(DBConnectionType.CATALOGUE))
              return null;
            return (
              <Grid item xs={12}>
                <StyledAccordion expanded={expandedData.includes(index)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => {
                      setExpandedData(
                        expandedData.includes(index)
                          ? expandedData.filter((i) => i !== index)
                          : [...expandedData, index]
                      );
                    }}
                  >
                    <TitleComponent>
                      <TitleContent>
                        {c.connectionName}
                        <Chip
                          label={c.connectionProtocol}
                          variant="filled"
                          size="small"
                          color="info"
                        />
                      </TitleContent>
                    </TitleComponent>
                  </AccordionSummary>

                  <AccordionDetails>
                    {c.connectionProtocol === 'FTP' && (
                      <ClientConnectionFtpConfiguration
                        c={c}
                        connectionData={connectionData as any}
                        setConnectionData={setConnectionData as any}
                        getValidationProps={getValidationProps}
                        clearErrors={clearErrors}
                        supplierData={data}
                      />
                    )}
                    {c.connectionProtocol === 'API' && (
                      <ClientConnectionApiConfiguration
                        c={c}
                        connectionData={connectionData as any}
                        setConnectionData={setConnectionData as any}
                        getValidationProps={getValidationProps}
                        clearErrors={clearErrors}
                        supplierData={data}
                      />
                    )}
                    {c.connectionProtocol === 'HTTPS' && (
                      <ClientConnectionHttpsConfiguration
                        c={c}
                        connectionData={connectionData as any}
                        setConnectionData={setConnectionData as any}
                        getValidationProps={getValidationProps}
                        clearErrors={clearErrors}
                        supplierData={data}
                      />
                    )}
                    {c.connectionProtocol === 'EMAIL' && (
                      <ClientConnectionEmailConfiguration
                        c={c}
                        connectionData={connectionData as any}
                        setConnectionData={setConnectionData as any}
                        getValidationProps={getValidationProps}
                        clearErrors={clearErrors}
                      />
                    )}
                  </AccordionDetails>
                </StyledAccordion>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

const TitleComponent = styled.div`
  display: block;
  width: 100%;
`;

const TitleContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > div {
    margin-left: 10px;
  }
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: 0px 1px 0px #dfe3e8;
`;
