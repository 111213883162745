import {
  DBCustomerOrderItem,
  DBWorkOrders,
  DeliveryType,
} from '@prisma/client';
import {
  DBCustomerOrderStatusEnum,
  InProcessSubStatuses,
} from './customer-order-item';
import { DBWorkOrdersCreate } from './work-orders';

export enum RitamOrderType {
  TRANSFER_ORDER,
  CUSTOMER_ORDER,
}

export enum OrderExistenceStatus {
  OnlyCustomerOrderExists,
  BothOrdersExist,
  NoOrdersExist,
  OnlyWorkOrderExists,
}

export interface QueryParamsUpdate {
  status: string;
  timeStart: number;
  timeEnd: number;
  skipTransferOrders?: string; //true or false
}

export interface RitamResponse {
  stavke: Record<string, string>[];
  nacinPlacanja: Record<string, string>[];
  usluge: Record<string, string>[];
  zaglavlje: Record<string, string>[];
}

export interface INacinPlacanja {
  tip: string;
  naziv: string;
  iznos: string;
  docId: string;
  docPlacanjaId: string;
}

export interface BranchesData {
  /**
   * [orderId]: branchErpId
   */
  purchaseOrder: Record<string, string | null>;
  workOrder: Record<string, string | null>;
  /**
   * branchErpId
   */
  defaultId: string | null;
}

export interface CreateRitamWorkOrder {
  customerOrderId: string;
  purchaseOrderIds: string[];
  splitShipment: boolean;
  branchesData: BranchesData;
  createdWorkOrders: DBWorkOrders[];

  customerOrder?:
    | (Partial<DBCustomerOrderItem> & { workOrders: Partial<DBWorkOrders>[] })
    | null;
}

export interface FinishOrder {
  workOrders: DBWorkOrdersCreate[];

  customerOrderId: string;
  purchaseOrderIds: string[];
  splitShipment: boolean;
  branchesData: BranchesData;
}

export const status_ritam: Record<string, StatusType> = {
  [DeliveryType.WMS_COLLECTION]: DBCustomerOrderStatusEnum.COLLECTED,
  [DeliveryType.WMS_DISPATCH]: DBCustomerOrderStatusEnum.SHIPPED,
  [DeliveryType.WMS_SHIPPING]: DBCustomerOrderStatusEnum.SHIPPED,
  [DeliveryType.WMS_FITTING]: DBCustomerOrderStatusEnum.FITTED,
};

export const subStatusRitam: Record<string, InProcessSubStatuses> = {
  [DeliveryType.WMS_COLLECTION]: InProcessSubStatuses.Ready_for_collection,
  [DeliveryType.WMS_DISPATCH]: InProcessSubStatuses.Ready_for_shipping,
  [DeliveryType.WMS_SHIPPING]: InProcessSubStatuses.Ready_for_shipping,
  [DeliveryType.WMS_FITTING]: InProcessSubStatuses.Ready_for_fitting,
};

export type StatusType =
  | DBCustomerOrderStatusEnum.SHIPPED
  | DBCustomerOrderStatusEnum.FITTED
  | DBCustomerOrderStatusEnum.COLLECTED
  | DBCustomerOrderStatusEnum.ORDERED;

interface DBRitamApi {
  create_order: {
    requestBody: CreateRitamWorkOrder;
    requestParams: CreateRitamWorkOrder;
    request: CreateRitamWorkOrder;
    response: string;
  };
  finish_order: {
    requestBody: FinishOrder;
    requestParams: FinishOrder;
    request: FinishOrder;
    response: { data: DBWorkOrders[]; ritamResponse: string[] };
  };
}

export { DBRitamApi };
