import styled from '@emotion/styled/macro';
import { CircularProgress, Divider, SelectChangeEvent } from '@mui/material';
import { DBInboundInvoice } from '@prisma/client';
import api from '@tyrio/api-factory';
import { InboundInvoiceApi, InboundInvoiceListRequest } from '@tyrio/dto';
import { backIcon } from '@tyrio/ui-library';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import NoDataFound from '../../components/NoData/NoDataFound';
import {
  LoaderWrapper,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import StyledPagination from '../../components/Pagination/Pagination';
import { useDebounce } from '../../hooks/useDebounce';
import { useFilter } from '../../hooks/useFilter';
import InvoiceTableFilters from './components/InvoiceTableFilters';
import { InvoiceDetails } from './details/InvoiceDetails';
import { InboundInvoiceTable } from './InboundInvoiceTable';

interface InboundInvoicenOverviewProps {
  sidebarOpen: boolean;
}

export const InboundInvoiceOverview = ({
  sidebarOpen,
}: InboundInvoicenOverviewProps) => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedInvoice, setSelectedInvoice] =
    useState<DBInboundInvoice | null>(null);

  const { filterValues, ...filtersHook } = useFilter({
    searchType: 'reference',
    searchKeyword: '',
  });

  const debouncedSearch = useDebounce({ filterValues, page, pageSize }, 500);

  const { data, isFetching } = useQuery(
    ['get_inbound_invoices', { debouncedSearch }],
    () =>
      searchInvoices({
        ...(filterValues as unknown as InboundInvoiceListRequest),
        page: page,
        pageSize: pageSize,
      }),
    {
      enabled: true,
    }
  );

  const searchInvoices = async (filterValues: InboundInvoiceListRequest) => {
    return await api.fetch<InboundInvoiceApi['list']>('get_inbound_invoices', {
      ...filterValues,
    });
  };

  const handleChangePageSize = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  const count = data?.count;

  const totalPageNumber =
    data && count
      ? count / pageSize - Math.floor(count / pageSize) === 0
        ? count / pageSize
        : Math.floor(count / pageSize) + 1
      : 1;

  return (
    <PageTemplateWrapper
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <Container open={sidebarOpen} orderSelected={selectedInvoice !== null}>
        <HeaderWrapperTop>
          <BackButton onClick={() => history.push('/dashboard')}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Inbound invoice</HeaderText>
        </HeaderWrapperTop>
        <Divider />

        <InvoiceTableFilters
          filters={filterValues}
          setFilters={filtersHook.setFilterValue}
        />

        {isFetching ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : data && data.count > 0 ? (
          <>
            <InboundInvoiceTable
              selectedInvoice={selectedInvoice}
              setSelectedInvoice={setSelectedInvoice}
              data={data.data}
            />
            <StyledPagination
              totalPageNumber={totalPageNumber}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              selectedOrderData={selectedInvoice}
              handleChangePageSize={handleChangePageSize}
              menuItems={[10, 20, 50, 100]}
            />
          </>
        ) : (
          <NoDataFound />
        )}
      </Container>

      {selectedInvoice !== null && (
        <InvoiceDetailsWrapper>
          <InvoiceDetails
            selectedInvoice={selectedInvoice}
            setSelectedInvoice={setSelectedInvoice}
          />
        </InvoiceDetailsWrapper>
      )}
    </PageTemplateWrapper>
  );
};

const Container = styled.div<{
  orderSelected: boolean;
  open: boolean;
}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  transition: linear 0.4s;
  position: relative;
  left: 0;
  height: calc(100svh - 44px);
  width: ${(prop) => (!prop.orderSelected ? '100%' : `calc(100% - 716px)`)};
`;

export const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const HeaderWrapperTop = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

const InvoiceDetailsWrapper = styled.div`
  min-width: 700px;
  max-width: 700px;
  width: 100%;
  margin-left: 16px;
  position: relative;
  right: 0;
  height: (100svh - 44px);
`;
