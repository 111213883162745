/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { Code, DBWarehouseSubzones } from '@prisma/client';
import { ZoneStagingType } from '@tyrio/dto';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { parseEnumToArray } from '../../../../../../helpers/enum-parser';
import { WarehouseContext } from '../../../../context/WarehouseContext';
import { format } from '../../helper/helper';
import { useWarehouseHelper } from '../../helper/warehouse-helper';
import { codes } from '../../steps/Details';
import { SelectStagingType } from './SelectStagingType';

export const types = ['STAGING', 'MOBILE', 'STORAGE'];

export const stagings = parseEnumToArray(ZoneStagingType).map((item) => ({
  value: item.value as string,
  label: format(item.value as string),
}));

const predefinedCodes = [
  'Y',
  'G',
  'D',
  'N',
  'P',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
];

export const codesByStagingType: Record<ZoneStagingType, Code> = {
  STATIC_STORAGE: 'Y',
  MOBILE_STORAGE: 'Y',
  MISSING: 'G',
  DAMAGED: 'D',
  RETURNS: 'N',
  PACKING: 'P',
  RECEIVING: 'R',
  SHIPPING: 'S',
  ON_HOLD: 'T',
  COLLECTION: 'U',
  DISPATCH: 'V',
  SERVICE: 'W',
  FITTING: 'X',
};

export type ActiveStep = 'type' | 'staging' | 'code';

export const SelectCode = () => {
  const {
    warehouse,
    selected,
    setActiveStep,
    isNewModalOpen,
    setIsNewModalOpen,
  } = useContext(WarehouseContext);

  const {
    zoneCode,
    subzoneCode,
    warehouseAreaId,
    initZone,
    initSubzone,
    _setWarehouse,
  } = useWarehouseHelper();

  const last = () => _.last(selected.current);

  const predefined = last() === 'subzone' ? subzoneCode() : zoneCode();
  const defaultStep = last() === 'subzone' ? 'type' : 'code';

  const [step, setStep] = useState<ActiveStep>(defaultStep);

  const [type, setType] = useState<string | null>(null);
  const [code, setCode] = useState<Code | null>(null);

  const getBgColor = (item: string) => {
    return isDisabled(item)
      ? '#F9FAFB'
      : code === item
      ? '#1976D2'
      : item === predefined
      ? '#FFFBF2'
      : 'white';
  };

  const getTxtColor = (item: string) => {
    return isDisabled(item) ? '#919EAB' : code === item ? '#fff' : '#152B47';
  };

  const data = () => {
    if (last() === 'zone') return warehouse?.zones;

    return warehouse?.subzones?.filter(
      (item) => item.zoneId === selected.locations.zone?.id
    ) as DBWarehouseSubzones[];
  };

  const isDisabled = (i: string) => {
    return (
      data()
        ?.map((item) => item.code.toString())
        .includes(i) ||
      (predefinedCodes.includes(i) && last() === 'subzone') ||
      (last() === 'zone' && i === 'X')
    );
  };

  return (
    <Modal open={isNewModalOpen} onClose={() => setIsNewModalOpen(false)}>
      <Box sx={style}>
        <Grid marginBottom={2} display="flex" justifyContent="space-between">
          <HeaderText>
            {step === 'code' ? `Select code` : `Select staging type`}
          </HeaderText>
          {last() === 'subzone' && step !== 'type' && (
            <StagingType>{type}</StagingType>
          )}
        </Grid>

        <SelectStagingType
          {...{ type, setType, step, setStep, code, setCode }}
        />
        {step === 'code' && (
          <Box
            display="flex"
            flexWrap="wrap"
            gap="6px"
            // padding="8px"
            // paddingRight="4px"
          >
            {codes?.map((item, index) => (
              <Container
                key={index}
                onClick={() => {
                  !isDisabled(item.value as string) &&
                    setCode(item.value as Code);
                }}
                background={getBgColor(item.value as string)}
                isPredefined={item.value === predefined && item.value !== code}
                isDisabled={isDisabled(item.value as string) as boolean}
                isSelected={code === item.value}
              >
                <Typography
                  fontSize={20}
                  fontWeight={400}
                  color={getTxtColor(item.value as string)}
                >
                  ZONE
                </Typography>
                <Typography
                  fontSize={24}
                  fontWeight={600}
                  color={getTxtColor(item.value as string)}
                >
                  {item.value}
                </Typography>
              </Container>
            ))}
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button
            variant="text"
            size="medium"
            color="error"
            onClick={() => {
              setIsNewModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="info"
            disabled={type === null && code === null}
            onClick={() => {
              if (!warehouseAreaId) return;
              setActiveStep('DETAILS');

              if (step === 'code' || step === 'staging') {
                if (last() === 'zone') {
                  const { zone, updated } = initZone(code as any);

                  _setWarehouse({ zone }, updated);
                }
                if (last() === 'subzone') {
                  let subzoneType = type;
                  let subzoneStagingType = type;

                  if (type === 'STORAGE') {
                    subzoneType = 'STORAGE';
                    subzoneStagingType = `STATIC_STORAGE`;
                  } else if (type === 'MOBILE') {
                    subzoneType = 'MOBILE';
                    subzoneStagingType = `MOBILE_STORAGE`;
                  } else {
                    subzoneType = 'STAGING';
                    subzoneStagingType = type;
                  }

                  const { subzone, updated } = initSubzone(
                    code as any,
                    subzoneType as string,
                    subzoneStagingType as string
                  );

                  _setWarehouse({ subzone }, updated);
                }

                setIsNewModalOpen(false);
              } else {
                if (['MOBILE', 'STORAGE'].includes(type as string)) {
                  setStep('code');
                } else setStep('staging');
              }
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 3,
};

const StagingType = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
  color: #e75524;
  cursor: default;
`;

const HeaderText = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.30000001192092896px;
`;

export const Container = styled(Box)<{
  background: string;
  isPredefined: boolean;
  isDisabled: boolean;
  isSelected: boolean;
}>`
  padding: 16px;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  border-radius: 8px;
  background: ${(props) => props.background};
  border: 1px solid
    ${(props) =>
      props.isSelected ? '#1976d2' : props.isDisabled ? '#F9FAFB' : '#dfe3e8'};
  /* flex-basis: calc(100% / 6 - 4px); */
  text-align: center;
  font-weight: 400;
  position: relative;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.isDisabled
          ? '#F9FAFB'
          : props.isPredefined
          ? `#FFCC4D`
          : '#1976d2'};
    font-weight: 500;
  }

  ${(props) =>
    props.isDisabled &&
    `
        &::after {
          content: '';
          position: absolute;
          top: 50%; 
          left: 50%;
          width: 80%;
          border-bottom: 2px solid #E75524; 
          transform: translate(-50%, -50%) rotate(-45deg);
          transform-origin: center;
        }
      `}
`;
