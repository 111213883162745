import {
  DBWarehouseBins,
  DBWarehouseFloors,
  DBWarehouseRacks,
  DBWarehouseSections,
  DBWarehouseShelfs,
  DBWarehouseSubzones,
  DBWarehouseZones,
  Prisma,
} from '@prisma/client';
import {
  Code,
  Locations,
  WarehouseBranchResponse,
  WarehouseLocations,
  WarehouseResponse,
} from '@tyrio/dto';
import _ from 'lodash';
import { useContext } from 'react';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import {
  UpdateWarehouseReq,
  WarehouseContext,
} from '../../../context/WarehouseContext';

interface Meta {
  zone: string;
  subzone: string;
  floor: number;
  rack: string;
  section: number;
  shelf: number;
  bin: string;
}

export interface CustomObject {
  code: string | number;
  id?: string;
  displayName?: string;
  meta?: Prisma.JsonValue;
}

interface InitialShape {
  code: Code;
  parent?: CustomObject | null;
  prefix?: string;
}

export type Types =
  | DBWarehouseZones
  | DBWarehouseSubzones
  | DBWarehouseFloors
  | DBWarehouseSections
  | DBWarehouseRacks
  | DBWarehouseShelfs
  | DBWarehouseBins
  | null;

export type ReqType = 'create' | 'update';

export const useWarehouseHelper = () => {
  const { selected, setSelected, warehouse, setWarehouse, req, setReq } =
    useContext(WarehouseContext);

  const warehouseAreaId = warehouse?.id as string;

  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const numberCodes = ['floors', 'sections', 'shelfs'];

  const getLetterCodes = () => parseEnumToArray(Code).map((item) => item.value);
  const getNumberCodes = () => numbers.map((item) => item);
  const check = (att: string) => numberCodes.includes(att);

  const getInitialValue = (att: string) => (check(att) ? 0 : 'A');
  const getCodeList = (att: string) =>
    check(att) ? getNumberCodes() : getLetterCodes();

  const config = (att: keyof Locations): WarehouseLocations[] => {
    const arr = [...selected.current];

    const conf = {
      zone: ['subzone'],
      subzone: ['floor', 'rack'],
      floor: [],
      rack: ['section', 'shelf'],
      section: [],
      shelf: ['bin'],
      bin: [],
    };

    return arr.concat(conf[att] as WarehouseLocations[]);
  };

  function getNextCode<T extends Record<string, CustomObject[]>>(obj: T): Code {
    const [att, arr] = Object.entries(obj)[0];
    const currents = arr.map((item) => item.code);

    let initial = getInitialValue(att);
    const codeList = getCodeList(att);

    for (const code of codeList) {
      if (!currents.includes(code)) {
        initial = code;
        break;
      }
    }

    return initial as Code;
  }

  const initLocation = (props: InitialShape) => {
    const { code } = props;

    const { qrCode, displayName } = generateQR(props);

    const id = qrCode;
    const description = '';

    return {
      id,
      warehouseAreaId,
      code,
      displayName,
      qrCode,
      description,
    };
  };

  const initialZone = (props: InitialShape): DBWarehouseZones => {
    return {
      ...initLocation(props),
      settings: {
        color: '#000000',
        isActive: true,
      },
      meta: {
        id: initLocation(props).qrCode,
      },
    };
  };

  const initialSubzone = (
    props: InitialShape,
    type?: string,
    stagingType?: string
  ) => {
    const { parent } = props;

    return {
      ...initLocation(props),
      zoneId: parent?.id,
      settings: {
        type: type,
        isActive: true,
        stagingType: stagingType,
      },
      meta: {
        id: initLocation(props).qrCode,
        zone: parent?.id,
      },
    };
  };

  const initialFloor = (props: InitialShape) => {
    const { parent } = props;

    return {
      ...initLocation(props),
      subzoneId: parent?.id,
      settings: {
        isActive: true,
      },
      meta: {
        ...(parent?.meta as unknown as Meta),
        id: initLocation(props).qrCode,
        subzone: parent?.id,
      },
    };
  };

  const initialRack = (props: InitialShape) => {
    const { parent } = props;

    return {
      ...initLocation(props),
      floorId: parent?.id,
      settings: {
        isHotel: false,
        isActive: true,
        mixedSKU: false,
        isRecommended: false,
        productCategory: [],
      },
      meta: {
        ...(parent?.meta as unknown as Meta),
        id: initLocation(props).qrCode,
        floor: parent?.id,
      },
    };
  };

  const initialSection = (props: InitialShape) => {
    const { parent } = props;

    return {
      ...initLocation(props),
      rackId: parent?.id,
      settings: {
        isActive: true,
        isHotel: false,
        mixedSKU: false,
        isRecommended: false,
        productCategory: [],
      },
      meta: {
        ...(parent?.meta as unknown as Meta),
        id: initLocation(props).qrCode,
        rack: parent?.id,
      },
    };
  };

  const initialShelf = (props: InitialShape) => {
    const { parent } = props;

    return {
      ...initLocation(props),
      sectionId: parent?.id,
      settings: {
        isActive: true,
        isHotel: false,
        mixedSKU: false,
        isRecommended: false,
        productCategory: [],
      },
      meta: {
        ...(parent?.meta as unknown as Meta),
        id: initLocation(props).qrCode,
        section: parent?.id,
      },
    };
  };

  const initialBin = (props: InitialShape) => {
    const { parent } = props;

    return {
      ...initLocation(props),
      shelfId: parent?.id,
      settings: {
        isActive: true,
      },
      meta: {
        ...(parent?.meta as unknown as Meta),
        id: initLocation(props).qrCode,
        shelf: parent?.id,
      },
    };
  };

  function startsWith(text: string, arr: string[]) {
    for (const item of arr) {
      if (text?.startsWith(item)) {
        return true;
      }
    }
    return false;
  }

  function generateQR(props: InitialShape) {
    const { parent, prefix, code } = props;

    const branchId = warehouse?.branchId;

    const locations = selected.locations;

    const objects = Object.entries(locations);

    let idx = objects.findIndex((item) => item[1] === null);

    if (startsWith(parent?.id as string, ['FLOOR', 'SECTION'])) idx++;

    const last = objects.find((_item, index) => index === idx)?.[0] ?? 'bin';

    const displayName = `${parent?.displayName ?? ''}${prefix ?? ''}${code}`;
    const qrCode = `${last?.toUpperCase()}-${branchId}-${displayName}`;

    return { qrCode, displayName };
  }

  function pushToArray<T extends Record<string, Types>>(
    temp: WarehouseBranchResponse | null,
    obj: T
  ): WarehouseResponse {
    const [att, val] = Object.entries(obj)[0];

    const array = _.get(temp, `${att}s`);

    array.push(val);

    return temp as WarehouseResponse;
  }

  function _setSelected<T extends Record<string, Types>>(obj: T) {
    const objects = Object.entries(obj);

    const final = selected.locations;

    objects.forEach((item) => {
      const [att, val] = item;

      _.set(final, `${att}`, val);
    });

    const last = _.last(Object.keys(obj));

    if (last) {
      setSelected({
        current: config(last as keyof Locations),
        locations: { ...final },
      });
    }
  }

  function _setReq<T extends Record<string, Types>>(
    operation: ReqType,
    obj: T
  ) {
    const objects = Object.entries(obj);

    const final = req[operation];

    if (!final) return;

    objects.forEach((item) => {
      const [att, val] = item;

      const current = _.get(req, `${operation}.${att}s`);

      _.set(final, `${att}s`, [...current, val?.id]);
    });

    setReq({
      ...req,
      [operation]: final,
    });
  }

  function zoneCode() {
    const zones = warehouse?.zones as [];

    const code = getNextCode({ zones });

    return code;
  }

  function subzoneCode() {
    const subzones = warehouse?.subzones?.filter(
      (item) => item.zoneId === selected.locations.zone?.id
    ) as [];

    const code = getNextCode({ subzones });

    return code;
  }

  function initZone(selectedCode?: Code) {
    const code = selectedCode ?? zoneCode();

    const zone = initialZone({ code });

    const updated = pushToArray(warehouse, { zone });

    return { zone, updated };
  }

  function initSubzone(
    selectedCode?: Code,
    type?: string,
    stagingType?: string
  ) {
    const code = selectedCode ?? subzoneCode();

    const subzone = initialSubzone(
      {
        code,
        parent: selected.locations.zone,
      },
      type,
      stagingType
    );

    const updated = pushToArray(warehouse, { subzone });

    return { subzone, updated };
  }

  function initFloor() {
    const subzone = selected.locations.subzone;

    const floors = warehouse?.floors?.filter(
      (item) => item.subzoneId === subzone?.id
    ) as [];

    const code = getNextCode({ floors });

    const floor = initialFloor({
      code,
      parent: subzone,
    }) as unknown as DBWarehouseFloors;

    const updated = pushToArray(warehouse, { floor });

    return { floor, updated };
  }

  function initRack(
    floor: DBWarehouseFloors,
    updatedWarehouse: WarehouseResponse
  ) {
    const racks = warehouse?.racks?.filter(
      (item) => item.floorId === floor.id
    ) as [];

    const code = getNextCode({ racks });

    const rack = initialRack({
      code,
      parent: floor,
      prefix: '-',
    });

    const updated = pushToArray(updatedWarehouse, { rack });

    return { rack, updated };
  }

  function initSection() {
    const rack = selected.locations.rack;

    const sections = warehouse?.sections?.filter(
      (item) => item.rackId === rack?.id
    ) as [];

    const code = getNextCode({ sections });

    const section = initialSection({
      code,
      parent: rack,
    }) as unknown as DBWarehouseSections;

    const updated = pushToArray(warehouse, { section });

    return { section, updated };
  }

  function initShelf(
    section: DBWarehouseSections,
    updatedWarehouse: WarehouseResponse
  ) {
    const shelfs = warehouse?.shelfs?.filter(
      (item) => item.sectionId === section.id
    ) as [];

    const code = getNextCode({ shelfs });

    const shelf = initialShelf({
      code,
      parent: section,
      prefix: '-',
    });

    const updated = pushToArray(updatedWarehouse, { shelf });

    return { shelf, updated };
  }

  function initBin() {
    const bins = warehouse?.bins?.filter(
      (item) => item.shelfId === selected.locations.shelf?.id
    ) as [];

    const code = getNextCode({ bins });

    const bin = initialBin({
      code,
      parent: selected.locations.shelf,
      prefix: '-',
    });

    const updated = pushToArray(warehouse, { bin });

    return { bin, updated };
  }

  function _setWarehouse<T extends Record<string, Types>>(
    obj: T,
    updatedWarehouse: WarehouseResponse,
    operation?: ReqType
  ) {
    if (!operation) operation = 'create';

    _setSelected({ ...obj });

    setWarehouse(updatedWarehouse);

    _setReq(operation, { ...obj });
  }

  const _isNewLocation = (id: string) =>
    _.flatMap(req.create, (value) => value).includes(id);

  function deleteElementsAfterItem(item: string) {
    const all = ['zone', 'subzone', 'floor', 'rack', 'section', 'shelf', 'bin'];
    const index = all.indexOf(item);
    if (index < 0 || index >= all.length) {
      return [];
    }
    return all.slice(index);
  }

  function getCascadeLocationsWarehouse(
    att: string,
    id: string,
    attName: string
  ) {
    return (_.get(warehouse, `${att}s`) as []).filter(
      (item) =>
        ((item as CustomObject).meta as unknown as Meta)?.[
          attName as keyof Meta
        ] !== id
    );
  }

  function getCascadeLocationsReq(
    arr: string[],
    att: string,
    reqType: ReqType
  ) {
    return (_.get(req, `${reqType}.${att}s`) as []).filter(
      (item) => !arr.includes(item)
    );
  }

  const _delete = (id: string) => {
    const splitted = id.split('-')[0].toLowerCase();

    const table = `${splitted}`;

    const filtered = deleteElementsAfterItem(table);

    let tempWarehouse = warehouse;
    let tempReq = req;

    filtered.forEach((item) => {
      let attName = table;

      if (item === table) attName = 'id';

      const forDelete = getCascadeLocationsWarehouse(item, id, attName);

      tempReq = {
        ...tempReq,
        create: {
          ...req.create,
          [item]: getCascadeLocationsReq(forDelete, item, 'create'),
        },
        update: {
          ...req.create,
          [item]: getCascadeLocationsReq(forDelete, item, 'update'),
        },
      } as UpdateWarehouseReq;

      tempWarehouse = {
        ...tempWarehouse,
        [`${item}s`]: forDelete,
      } as WarehouseResponse;
    });

    setWarehouse(tempWarehouse);

    const tempCurrent = selected;

    tempCurrent.current = _.dropRight(tempCurrent.current, 1);

    setReq(tempReq);

    setSelected({
      current: [...tempCurrent.current],
      locations: {
        ...selected.locations,
        [table]: null,
      },
    });
  };

  return {
    warehouseAreaId,
    initZone,
    initSubzone,
    initFloor,
    initRack,
    initSection,
    initShelf,
    initBin,
    zoneCode,
    subzoneCode,
    _setWarehouse,
    _isNewLocation,
    _delete,
  };
};
