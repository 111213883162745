import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';

import { TrackInfoPreviewModel } from '@tyrio/dto';
import statusMapping from '../../helpers/status-mapping-enums';
import { useDeleteTrackingItem } from './queries/delete-tracking-item';
import { tyrioIcons, tyrioUI } from '@tyrio/ui-library';
interface TrackingItemProps {
  trackingItemData: TrackInfoPreviewModel;
  zipCode: string | null;
  refetchTrackingInfo: () => void;
}

export const TrackingItem = ({
  trackingItemData,
  zipCode,
  refetchTrackingInfo,
}: TrackingItemProps) => {
  const imgSrc =
    trackingItemData?.courierService === 'GLS'
      ? tyrioIcons.gls
      : tyrioIcons.dpd;

  const trackingUrl =
    trackingItemData?.courierService === 'GLS'
      ? `https://gls-group.com/HR/en/parcel-tracking/?match=${trackingItemData.trackingNumber}&postalCode=${zipCode}`
      : `https://www.dpdgroup.com/hr/mydpd/my-parcels/incoming?parcelNumber=${trackingItemData.trackingNumber}`;

  let statusTitle = 'Unknown Status';
  let statusDesc = 'No description available';

  if (trackingItemData.courierServiceStatus) {
    const status = statusMapping[trackingItemData.courierServiceStatus];
    statusTitle = status?.title || 'Unknown Status';
    statusDesc = status?.desc || 'No description available';
  }

  if (trackingItemData.courierService === 'GLS') {
    statusDesc =
      trackingItemData?.statusDescription ?? 'No description available';
  }

  const formatDate = (isoDateString: string | Date) => {
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const { deleteTrackItem } = useDeleteTrackingItem(refetchTrackingInfo);

  const handleDelete = () => {
    deleteTrackItem.mutate({
      trackingNumber: trackingItemData.trackingNumber,
      courierService: trackingItemData.courierService,
    });
  };

  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        border: `1px solid ${tyrioUI.colors.black.B40}`,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        marginRight: '16px',
        marginLeft: '16px',
        marginBottom: '16px',
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          display: 'flex',
          flex: '0 0 auto',
          gap: '16px',
          justifyContent: 'center',
          padding: '12px 16px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            position: 'relative',
            width: '80px',
          }}
        >
          <img
            src={imgSrc}
            alt={trackingItemData?.courierService}
            style={{
              height: '25px',
              objectFit: 'cover',
              position: 'relative',
              width: '73.5px',
            }}
          />
        </Box>
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
            flexGrow: '1',
            position: 'relative',
          }}
        >
          <Text fontWeight={'600'} fontSize={'14px'}>
            {statusTitle}
          </Text>
          <Text fontWeight={'400'} fontSize={'14px'}>
            {statusDesc}
          </Text>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'inline-flex',
            flex: '0 0 auto',
            gap: '10px',
            height: '40px',
            justifyContent: 'flex-end',
            position: 'relative',
          }}
        >
          <a
            href={trackingUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Typography
              sx={{
                flex: '0 0 auto !important',
                color: '#1976D2',
                fontFamily: 'Barlow',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0.3px',
              }}
            >
              {trackingItemData.trackingNumber}
            </Typography>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: tyrioUI.colors.black.B10,
          display: 'flex',
          height: '40px',
          padding: '4px 24px',
          width: '100%',
          borderRadius: '0px 0px 8px 8px',
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flex: '1',
            flexGrow: '1',
            gap: '10px',
            position: 'relative',
          }}
        >
          <Text fontWeight={'400'} fontSize={'12px'}>
            Last updated
          </Text>
          <Text fontWeight={'500'} fontSize={'12px'}>
            {formatDate(trackingItemData.lastUpdated)}
          </Text>
        </Box>
        <Button
          onClick={handleDelete}
          sx={{
            color: tyrioUI.colors.red.B30,
            border: 'none',
            backgroundColor: tyrioUI.colors.black.B10,
          }}
        >
          DELETE
        </Button>
      </Box>
    </Box>
  );
};

const Text = styled(Typography)<{ fontWeight: string; fontSize: string }>`
  color: ${tyrioUI.colors.black.B100},
  font-family: Barlow;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
`;
