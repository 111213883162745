import { AppConfig } from '@tyrio/config';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initializeI18n = async (
  config: AppConfig,
  en: Record<string, any>,
  hr: Record<string, any>
) => {
  try {
    await i18n
      .use(detector)
      .use(backend)
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        fallbackLng: 'en-GB', // use en if detected lng is not available
        resources: {
          'en-GB': { translation: en },
          'hr-HR': { translation: hr },
        },
        backend: {
          // loadPath:
          //   'https://accent.tyrio-api.link/api/v1/projects/2fb5aba3-48e0-4cb7-a498-33be18461ec1/versions/latest/{{lng}}/{{ns}}.json', // Accent API endpoint
          addPath: `${config.apiUrl}/locales/add/{{lng}}/{{ns}}`,
          allowMultiLoading: false,
          crossDomain: true,
          // withCredentials: true,
          // customHeaders: {
          //   Authorization:
          //     'Bearer v5OnnZBXN5dIty6PyaGFmOTxznK4Db-NSJsUX6RD90KbG_N6I2KK0XZO0ztpSt1KTo7NJjYpfwXzhh_Gmmz_iNl_CPHWeg', // Replace with your Accent API token
          // },
        },
        debug: true, // Enable debug mode for development
        interpolation: {
          escapeValue: false, // React already protects from XSS
        },
        saveMissing: true,
        saveMissingTo: 'current',
      });

    return i18n;
  } catch (e) {
    console.log('Error while initializing internationalization - ', e);
    throw e;
  }
};

export default initializeI18n;
// path to post missing resources
//
//     addMissingUrl: 'https://your-accent-server.com/api/v1/projects/YOUR_PROJECT_ID/translations',
// customSaveMissing: function (lng, ns, key, fallbackValue) {
// },

//   fallbackLng: 'en',
// debug: true,
// saveMissing: true, // Save missing translations
// saveMissingTo: 'current', // Send missing translations to the current namespace

// fallbackLng: 'hr_HR', // use en if detected lng is not available
// saveMissing: true, // send not translated keys to endpoint
// backend: {
//   // path where resources get loaded from, or a function
//   // returning a path:
//   // function(languages, namespaces) { return customPath; }
//   // the returned path will interpolate lng, ns if provided like giving a static path
//   loadPath: `${config.apiUrl}/locales/{{lng}}/{{ns}}`,
//
//   // path to post missing resources
//   addPath: `${config.apiUrl}/locales/add/{{lng}}/{{ns}}`,
//
//   // your backend server supports multiloading
//   // /locales/resources.json?lng=de+en&ns=ns1+ns2
//   // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
//   // Returned JSON structure in this case is
//   // {
//   //  lang : {
//   //   namespaceA: {},
//   //   namespaceB: {},
//   //   ...etc
//   //  }
//   // }
//   allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
//
//   // allow cross domain requests
//   crossDomain: true,
//
//   // allow credentials on cross domain requests
//   withCredentials: false,
//   //
//   // // overrideMimeType sets request.overrideMimeType("application/json")
//   // overrideMimeType: false,
//   //
//   // // custom request headers sets request.setRequestHeader(key, value)
//   // customHeaders: {
//   //   authorization: 'foo',
//   //   // ...
//   // },
//   //
//   // // define a custom xhr function
//   // // can be used to support XDomainRequest in IE 8 and 9
//   // //
//   // // 'url' will be passed the value of 'loadPath'
//   // // 'options' will be this entire options object
//   // // 'callback' is a function that takes two parameters, 'data' and 'xhr'.
//   // //            'data' should be the key:value translation pairs for the
//   // //            requested language and namespace, or null in case of an error.
//   // //            'xhr' should be a status object, e.g. { status: 200 }
//   // // 'data' will be a key:value object used when saving missing translations
//   // ajax: function (url, options, callback, data) {},
//   //
//   // // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
//   // queryStringParams: { v: '1.3.5' },
// },
