import { DBConnectionType, DBSupplierConnection } from '@prisma/client';

export const supplier_connections: DBSupplierConnection[] = [
  {
    id: 'a85ca99b-fa29-4ff5-81dc-9c977c6a9613',
    supplierId: 'd80ae0db-ffa8-4a96-9624-dc16da96be01',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '0256f16a-fc82-4803-a5c2-22b6fa7bb99e',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'VANDENBAN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: 1,
    minimumUpdateFrequencyUnit: 'DAYS',
  },
  {
    id: 'a85ca99b-fa29-4ff5-81dc-9c977c6a9614',
    supplierId: 'd80ae0db-ffa8-4a96-9624-dc16da96be01',
    connectionName: 'Stock and Pricelist',
    connectionTypes: [DBConnectionType.STOCK, DBConnectionType.PRICELIST],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '0256f14a-fc82-4303-a5c2-22b6fa7bc99e',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'VANDENBAN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: 1,
    minimumUpdateFrequencyUnit: 'DAYS',
  },
  {
    id: '5a4b5192-7692-42de-bdc6-ed6899758b5a',
    supplierId: 'd80ae0db-ffa8-4a96-9624-dc16da96be01',
    connectionName: 'Order',
    connectionTypes: [DBConnectionType.ORDER],
    active: true,
    connectionProtocol: 'API',
    remark: '',
    ftpConnectionId: null,
    apiConnectionId: '6aa903c6-7be9-4112-9299-408eb81a80ab',
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'VANDENBAN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '35515fc6-76b1-4190-a051-a3f04cc88de0',
    supplierId: '00000000-0000-0000-0000-000000000009',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '7ef52444-ede4-47a4-9a95-de39a3ee2d7d',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'CONTINENTAL',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '5a8fb9f3-b06d-4b5c-a8ae-663fb485bec1',
    supplierId: '00000000-0000-0000-0000-000000000007',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: 'c9bc511a-1109-4475-8722-c46d601d8d80',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'KUMHO',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '987a41a2-8eaa-4e01-a3a3-e430b85b6da3',
    supplierId: '00000000-0000-0000-0000-000000000009',
    connectionName: 'Stock',
    connectionTypes: [DBConnectionType.STOCK],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '0e104b81-bd88-49f3-b8d3-f1b54b5dcd6c',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'CONTINENTAL',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: 1,
    minimumUpdateFrequencyUnit: 'DAYS',
  },
  {
    id: '452096cb-3267-4f70-822c-f950aca99cc9',
    supplierId: '00000000-0000-0000-0000-000000000009',
    connectionName: 'Pricelist',
    connectionTypes: [DBConnectionType.PRICELIST],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '42a34ff4-0d8f-4854-9af8-d235e4476476',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'CONTINENTAL',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: 1,
    minimumUpdateFrequencyUnit: 'DAYS',
  },
  {
    id: '19f2d907-99a5-402b-a813-b1a68427cf62',
    supplierId: '00000000-0000-0000-0000-000000000009',
    connectionName: 'Order',
    connectionTypes: [DBConnectionType.ORDER],
    active: true,
    connectionProtocol: 'HTTPS',
    remark: '',
    ftpConnectionId: null,
    apiConnectionId: null,
    httpsConnectionId: 'c95c680d-c983-4964-9668-ef818d423c32',
    createdAt: new Date(),
    rulesetKey: 'CONTINENTAL',
    fileFormat: 'XML',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '931b2bbf-b9da-4392-913c-9a5cebf941e6',
    supplierId: '00000000-0000-0000-0000-000000000008',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: 'bf8fc451-ddfc-4335-9b69-ee115143a1f7',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'BRIDGESTONE',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '9310aa7a-f798-4a50-94cd-f935409ef1fd',
    supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '97571dba-f69d-49d1-b932-9a4bb8dfbaf4',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'VANDENBAN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '2c22183f-fc5b-4f4e-a75c-3a53e8ebe91f',
    supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
    connectionName: 'Stock and Pricelist',
    connectionTypes: [DBConnectionType.STOCK, DBConnectionType.PRICELIST],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '6a3d781b-077c-4804-8fa4-c39bae85120e',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'VANDENBAN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: 1,
    minimumUpdateFrequencyUnit: 'DAYS',
  },
  {
    id: 'de607bcd-1bd1-4d92-a422-a5283c1be163',
    supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
    connectionName: 'Order',
    connectionTypes: [DBConnectionType.ORDER],
    active: true,
    connectionProtocol: 'HTTPS',
    remark: '',
    ftpConnectionId: null,
    apiConnectionId: null,
    httpsConnectionId: 'a58a26f1-dbf4-4132-82bb-ae6f31672e98',
    createdAt: new Date(),
    rulesetKey: 'VANDENBAN',
    fileFormat: 'XML',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '88d22d9b-fad4-421c-bbb3-7ead759bcffb',
    supplierId: '00000000-0000-0000-0000-000000000004',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: 'e107eb18-1f3e-47fe-9d2e-fc72e95b69c2',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'GOODYEAR',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '2028a06f-c970-4234-9936-beedd7b27c32',
    supplierId: '00000000-0000-0000-0000-000000000002',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '9fc86a28-e182-4e89-af31-10f2bf5d4c80',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'MICHELIN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: 'fd551397-1def-49c8-bf59-32b48a280912',
    supplierId: '00000000-0000-0000-0000-000000000003',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: '34981dc4-426a-46a1-93d1-7fe33430bd91',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'PIRELLI',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: 'c1be9f8e-5ea5-48cb-9c56-d53048e87ac9',
    supplierId: '00000000-0000-0000-0000-000000000005',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: 'be2f34a9-fc44-4858-ac71-62d558e89718',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'GUNDLACH',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
  {
    id: '91ed6e94-01ec-476b-9571-3a9576974183',
    supplierId: '00000000-0000-0000-0000-000000000006',
    connectionName: 'Catalog',
    connectionTypes: [DBConnectionType.CATALOGUE],
    active: true,
    connectionProtocol: 'FTP',
    remark: '',
    ftpConnectionId: 'becd2bbb-bc36-4dec-a7c2-2d5480c18eff',
    apiConnectionId: null,
    httpsConnectionId: null,
    createdAt: new Date(),
    rulesetKey: 'APOLLOVREDESTEIN',
    fileFormat: 'CSV',
    isFileZipped: false,
    emailConnectionId: null,
    minimumUpdateFrequencyTime: null,
    minimumUpdateFrequencyUnit: null,
  },
];
