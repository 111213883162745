import { DBWorkOrders } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBRitamApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';

export const useFinishWorkOrder = (
  onFinishSuccess: (res: {
    data: DBWorkOrders[];
    ritamResponse: string[];
  }) => void
) => {
  const { mutateAsync, status } = useMutation(
    'finish_work_order',
    (data: DBRitamApi['finish_order']['request']) =>
      api.fetch<DBRitamApi['finish_order']>('finish_work_order', { ...data }),
    {
      onSuccess: (res) => {
        onFinishSuccess(res);
        const ritamResponse = res.ritamResponse;
        ToastHelper.showToast(
          'Ritam order',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          ritamResponse.join(' - ')
        );
      },
      onError: (err) => {
        console.log('ERROR==>', err);
        ToastHelper.showToast(
          'Ritam order',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
        return;
      },
    }
  );
  return {
    finishRitamOrder: mutateAsync,
    status,
  };
};
