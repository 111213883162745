import styled from '@emotion/styled/macro';
import api from '@tyrio/api-factory';
import {
  AppointmentReq,
  CartData,
  CreateServiceInfo,
  CreateServiceReservationReq,
  DBDeliveryTypesApi,
  DBServicesApi,
  PosApi,
  ServiceInfo,
  StockShape,
} from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import _, { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { useAuth } from '../../../../context/AuthContext';
import {
  ActiveInfo,
  initializeInput,
  PosCartContext,
} from '../../../../context/PosCartContext';
import { POSContext } from '../../../../context/POSContext';
import { useWS } from '../../../../context/WSContext';
import { CartDetails } from '../../../stock-list/components/Cart/CartDetails';
import { useGetCountries } from '../../query/get-countries';
import { useCalculatePrice } from './helpers/calculation';
import { usePosCartData } from './helpers/cart-data';
import { informationList } from './InformationList/information-list';
import { ListItem } from './InformationList/ListItem';
import { OldTyresDisposal } from './OldTyres/OldTyresDisposal';
import { useGetServiceDetails } from './Steps/Appointment/queries/get-service-details';
import { AddressCard } from './Steps/DeliveryMethod/components/AddressCard';
import { InfoFooter } from './Steps/Footer';
import { InfoSteps } from './Steps/InfoSteps';

const transformRequest = (data: CartData[], key?: string): StockShape[] => {
  const path = key ?? 'branchId';
  const groupedData = _.groupBy(data, path);

  const stockShape: StockShape[] = [];

  Object.entries(groupedData).forEach(([key, value]) => {
    const transformedValue = path === 'branchId' ? Number(key) : key;

    const items = value.map((v) => v.req);

    stockShape.push({
      [path]: transformedValue,
      items,
    } as unknown as StockShape);

    return;
  });

  return stockShape;
};

export const Cart = () => {
  const { user, client } = useAuth();
  const ws = useWS();

  useGetServiceDetails();
  const { findCountryCode } = useGetCountries();

  const { modalData, servicesData, onStockData, branchesData, suppliersData } =
    usePosCartData();

  const { selectedCustomer, selectedWarehouseId, isVatIncluded } =
    useContext(POSContext);
  const {
    activeInfo,
    setActiveInfo,
    input,
    setInput,
    customerDetails,
    setCustomerDetails,
    appointmentDetails,
    deliveryAddress,
    setDeliveryAddress,
    setDeliveryPrice,
    deliveryPrice,
    setCartData,
    timer,
    clearCustomerDetails,
    clearVehicleForm,
    setPaymentDetails,
  } = useContext(PosCartContext);

  const { servicesPrice, subTotalExlVat, grandTotal, vat } = useCalculatePrice(
    modalData,
    deliveryPrice,
    isVatIncluded,
    selectedCustomer?.rebateCalculation?.rebate ?? 0,
    selectedCustomer?.rebateCalculation?.discount ?? 0
  );

  const [oldTyresDisposalServices, setOldTyresDisposalServices] = useState<
    DBServicesApi['recommended']['response'] | []
  >([]);

  const { data: activeDeliveries } = useQuery(
    ['get_deliveries_by_branch', selectedWarehouseId?.toString()],
    async () => {
      return await api.fetch<DBDeliveryTypesApi['list']>(
        'get_deliveries_by_branch',
        {
          branchId: selectedWarehouseId?.toString(),
        }
      );
    }
  );

  const finishOrder = useMutation(
    (request: PosApi['finishOrder']['request']) =>
      api.fetch<PosApi['finishOrder']>('finish_pos_order', {
        ...request,
      }),
    {
      mutationKey: 'finish_pos_order',
      onSuccess: (data: PosApi['finishOrder']['response']) => {
        setCartData([]);
        timer?.clear();
        ws.socket?.emit('remove-all-pos-cart-items');

        setInput(initializeInput());
        clearCustomerDetails();
        clearVehicleForm();
        setPaymentDetails([]);

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE,
          `Order created: ${data.response.id}`
        );

        ToastHelper.showToast(
          'Ritam order',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          data.ritamResponse.join(' - ')
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Error',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occured.'
          );
        }
        throw error;
      },
    }
  );

  const getAppointments = () => {
    const appointments = Object.values(
      appointmentDetails['appointments']
    ) as unknown as AppointmentReq[];

    const final: CreateServiceReservationReq[] = [];

    appointments.forEach((item) => {
      const services: CreateServiceInfo[] = (
        Object.values(item.services) as unknown as ServiceInfo[]
      ).map((item) => ({
        serviceId: item.id,
        quantity: item.qty,
      }));

      final.push({
        appointmentDateFrom: item.appointmentDateFrom?.toString() as string,
        appointmentDateTo: item.appointmentDateTo?.toString() as string,
        boxId: item.resource?.shelfId as string,
        remark: '',
        services: services,
      });
    });

    return final;
  };

  const handleFinish = () => {
    const my_stock = transformRequest(onStockData);
    const branches_stock = transformRequest(branchesData);
    const supplier_stock = transformRequest(suppliersData, 'supplierId');

    const requestBody: PosApi['finishOrder']['requestBody'] = {
      branchesStock: branches_stock,
      myStock: my_stock,
      supplierStock: supplier_stock,
      delivery: {
        code:
          !isEmpty(input.delivery_method) &&
          !isEmpty(input.delivery_method.deliveryType)
            ? input?.delivery_method?.deliveryType?.code ?? ''
            : '',
      },
      payment: {
        code: input.payment.length > 0 ? input?.payment?.[0].code ?? '' : '',
      },
      appointments: getAppointments(),
      services: [],
      mainWarehouseId: user?.mainBranchId ?? 1, // it would be nice to send erpBranchId also
      customer: !isEmpty(input.customer)
        ? {
            address: customerDetails['address'],
            city: customerDetails['city'],
            country: findCountryCode(customerDetails['country']) ?? 'hr',
            firstName: customerDetails['first_name'],
            lastName: customerDetails['last_name'],
            zip: customerDetails['zip_code'],
            phoneNumberDetails: {
              countryCode: '+385', // TODO: fix this!
              phoneNumber: customerDetails['mobile_phone'],
            },
            newsletter: false,
            email: customerDetails['email'],
            mobilePhone: customerDetails['mobile_phone'],
          }
        : undefined,
      deliveryAddress:
        input &&
        !isEmpty(input.delivery_method) &&
        input?.delivery_method &&
        input?.delivery_method?.address &&
        input?.delivery_method?.address?.length > 0
          ? {
              address: input?.delivery_method?.address?.[0].address ?? '',
              city: input?.delivery_method?.address?.[0].city ?? '',
              companyInfo: {
                companyName:
                  input?.delivery_method?.address?.[0].companyName ?? '',
              },
              country: 'HR',
              firstName: input?.delivery_method?.address?.[0].firstName ?? '',
              lastName: input?.delivery_method?.address?.[0].lastName ?? '',
              phoneNumberDetails: {
                countryCode: '+385', // TODO: FIX THIS!
                phoneNumber:
                  input?.delivery_method?.address?.[0].mobilePhone ?? '',
              },
              zip: input?.delivery_method?.address?.[0].zip ?? '',
            }
          : undefined,
      billingAddress: !isEmpty(input.customer)
        ? {
            address: customerDetails['address'],
            city: customerDetails['city'],
            country: findCountryCode(customerDetails['country']) ?? 'hr',
            firstName: customerDetails['first_name'],
            lastName: customerDetails['last_name'],
            zip: customerDetails['zip_code'],
            phoneNumberDetails: {
              countryCode: '+385', // TODO: fix this!
              phoneNumber: customerDetails['mobile_phone'],
            },
            email: customerDetails['email'],
            companyInfo: {
              companyName: customerDetails['company_name'] ?? '',
              vat: customerDetails['vat'] ?? '',
              companyInvoice: false,
            },
          }
        : undefined,
      summary: {
        vatAmount: grandTotal - subTotalExlVat ?? 0,
        deliveryExVat: deliveryPrice,
        discountExVat: 0,
        servicesExVat: servicesPrice,
        subtotalExVat: subTotalExlVat,
        grandTotalIncVat: grandTotal,
      },
      vehicleInfo: !_.isEmpty(input.vehicle)
        ? {
            brand: input.vehicle['brand'],
            model: input.vehicle['model'],
            year: input.vehicle['year'],
            licensePlateNumber: input.vehicle['licence_plate'],
          }
        : undefined,
    };
    finishOrder.mutate(requestBody);
  };

  useQuery(
    ['get_services_by_branch_id', selectedWarehouseId?.toString()],
    async () => {
      return await api.fetch<DBServicesApi['list']>(
        'get_services_by_branch_id',
        {
          branchId: selectedWarehouseId?.toString(),
        }
      );
    },
    {
      onSuccess: (data) => {
        const filtered: DBServicesApi['recommended']['response'] =
          data.filter((item) => item.serviceCategoryId === '13204') ?? [];
        setOldTyresDisposalServices(filtered);
      },
    }
  );

  // TODO: refactor this method to be more generic
  const getDetails = (key: string) => {
    if (key === 'customer' && !_.isEmpty(input.customer))
      return `${input.customer['company_name'] ?? ''}   ${
        input.customer['first_name'] ?? ''
      } ${input.customer['last_name'] ?? ''}   ${
        input.customer['zip_code'] ?? ''
      } ${input.customer['city'] ?? ''}`;
    else if (
      key === 'delivery_method' &&
      !_.isEmpty(input.delivery_method['deliveryType'])
    )
      return `${input.delivery_method['deliveryType']?.delivery}`;
    else if (key === 'vehicle' && !_.isEmpty(input.vehicle) && input.vehicle)
      return `${input.vehicle['brand']?.toUpperCase() ?? ''} ${
        input.vehicle['model']?.toUpperCase() ?? ''
      }`;
    else if (
      key === 'payment' &&
      !_.isEmpty(input.payment) &&
      input.payment &&
      input.payment.length > 0
    ) {
      let paymentInfo = '';
      input.payment.map((payment, index) => {
        paymentInfo += payment.selectedMethod;
        if (index < input.payment.length - 1) paymentInfo += ', ';
        return paymentInfo;
      });
      return paymentInfo;
    }
    return '';
  };

  const generateKey = (text: string) => {
    return text !== 'Delivery method'
      ? (text.toLowerCase() as unknown as ActiveInfo)
      : 'delivery_method';
  };

  useEffect(
    function updateDetails() {
      if (selectedCustomer === null && input.isTyrioCustomer) {
        setCustomerDetails({});
        setDeliveryAddress([]);
        setInput(initializeInput());
      } else if (selectedCustomer !== null && _.isEmpty(input.customer)) {
        setCustomerDetails({
          first_name: selectedCustomer?.firstName ?? '',
          last_name: selectedCustomer?.lastName ?? '',
          country:
            selectedCustomer?.countryId ?? client?.address?.countryId ?? '',
          address: selectedCustomer?.address ?? '',
          zip_code: selectedCustomer?.zipCode ?? '',
          city: selectedCustomer?.city ?? '',
          mobile_phone: selectedCustomer?.mobilePhone ?? '',
          email: selectedCustomer?.email ?? '',
          passport: '',
          customer_remark: selectedCustomer?.remark ?? '',
          internal_remark: '',
          company_name: selectedCustomer?.companyDisplayName
            ? selectedCustomer?.companyDisplayName
            : selectedCustomer?.companyOfficialName ?? '',
          vat: selectedCustomer?.viesVatNumber ?? '',
        });

        setDeliveryAddress([
          {
            address: selectedCustomer?.address ?? '',
            zip: selectedCustomer?.zipCode ?? '',
            city: selectedCustomer?.city ?? '',
            country:
              selectedCustomer?.countryId ?? client?.address?.countryId ?? '',
            firstName: selectedCustomer?.firstName ?? '',
            lastName: selectedCustomer?.lastName ?? '',
            mobilePhone: selectedCustomer?.mobilePhone ?? '',
            email: selectedCustomer?.email ?? '',
            companyName: selectedCustomer?.companyDisplayName
              ? selectedCustomer?.companyDisplayName
              : selectedCustomer?.companyOfficialName ?? '',
            fromCustomer: true,
          },
        ]);
        setInput((prevState) => ({
          ...prevState,
          isTyrioCustomer: true,
        }));
      }
    },
    [
      client?.address?.countryId,
      input,
      input.customer,
      input.isTyrioCustomer,
      selectedCustomer,
      setCustomerDetails,
      setDeliveryAddress,
      setInput,
    ]
  );

  useEffect(
    function disableDelivery() {
      if (servicesData.length > 0) {
        informationList[1].disabled = true;
        setDeliveryPrice(0);
        if (activeInfo === 'DELIVERY_METHOD') setActiveInfo('');
      } else informationList[1].disabled = false;
    },
    [activeInfo, servicesData.length, setActiveInfo, setDeliveryPrice]
  );

  useEffect(
    function updateDeliveryAddress() {
      if (!_.isEmpty(customerDetails))
        setDeliveryAddress([
          {
            address: customerDetails['address'],
            zip: customerDetails['zip_code'],
            city: customerDetails['city'],
            country: customerDetails['country'] ?? '',
            firstName: customerDetails['first_name'] ?? '',
            lastName: customerDetails['last_name'] ?? '',
            mobilePhone: customerDetails['mobile_phone'] ?? '',
            email: customerDetails['email'] ?? '',
            companyName: customerDetails['company_name'],
            fromCustomer: true,
          },
        ]);
    },
    [customerDetails, setDeliveryAddress]
  );

  useEffect(
    function updateLocaleStorage() {
      if (
        !_.isEqual(customerDetails, input.customer) &&
        input.isTyrioCustomer === true
      )
        setInput({
          customer: customerDetails,
          appointment: appointmentDetails,
          isCustomerEditDisabled: true,
          isCustomerSwitchActive: true,
          isTyrioCustomer: true,
          isSudRegCustomer: false,
          delivery_method: { address: deliveryAddress },
          vehicle: {},
          payment: [],
        });
    },
    [
      appointmentDetails,
      customerDetails,
      deliveryAddress,
      input.customer,
      input.isTyrioCustomer,
      setInput,
    ]
  );

  const shouldDisableFinish = useMemo(() => {
    if (isEmpty(input.payment)) return true;
    return false;
  }, [input.payment]);

  const renderCart = () => {
    return (
      <Wrapper>
        {modalData.length === 0 ? (
          <CartDetails type="empty" />
        ) : activeInfo === '' ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <ListWrapper>
              {informationList.map((item) => (
                <div
                  key={item.text}
                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                >
                  {getDetails(generateKey(item.text)) === '' ? (
                    <ListItem
                      key={item.text}
                      icon={item.icon}
                      text={item.text}
                      disabled={
                        item.disabled ||
                        (item.text === 'Delivery method' &&
                          activeDeliveries !== undefined &&
                          activeDeliveries?.length === 0)
                      }
                      onClick={() =>
                        setActiveInfo(
                          item.text !== 'Delivery method'
                            ? (item.text.toUpperCase() as unknown as ActiveInfo)
                            : 'DELIVERY_METHOD'
                        )
                      }
                    />
                  ) : (
                    <AddressCard
                      key={item.text}
                      icon={item.icon}
                      text={item.text}
                      details={getDetails(generateKey(item.text))}
                      onClick={() =>
                        setActiveInfo(
                          item.text !== 'Delivery method'
                            ? (item.text.toUpperCase() as unknown as ActiveInfo)
                            : 'DELIVERY_METHOD'
                        )
                      }
                      disabled={
                        item.disabled ||
                        (item.text === 'Delivery method' &&
                          activeDeliveries !== undefined &&
                          activeDeliveries?.length === 0)
                      }
                      deliveryIcon={
                        item.text === 'Delivery method'
                          ? input.delivery_method['deliveryType']?.icon
                          : ''
                      }
                      price={
                        item.text === 'Delivery method'
                          ? !isVatIncluded &&
                            input.delivery_method['deliveryType']?.price
                            ? input.delivery_method['deliveryType']?.price
                            : input.delivery_method['deliveryType']?.price
                            ? input?.delivery_method['deliveryType']?.price +
                              (input?.delivery_method['deliveryType']?.price *
                                vat) /
                                100
                            : 0
                          : 0
                      }
                      showDeliveryDetails={
                        item.text === 'Delivery method' ||
                        item.text === 'Vehicle' ||
                        item.text === 'Payment'
                      }
                      detailsText={
                        item.text === 'Vehicle' &&
                        input.vehicle?.['licence_plate']
                          ? `Licence plate:  ${input.vehicle?.['licence_plate']}`
                          : ''
                      }
                    />
                  )}
                </div>
              ))}
            </ListWrapper>
            {/* Here we are rendering only disposal tyres */}
            {oldTyresDisposalServices.length > 0 && (
              <OldTyresDisposal items={oldTyresDisposalServices} />
            )}
          </div>
        ) : (
          <div style={{ height: '100%' }}>
            {activeInfo && InfoSteps(activeInfo)}
          </div>
        )}
      </Wrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posCart"
      children={renderCart()}
      showSwitch={false}
      checked={false}
      shouldShowTitle={false}
      footer={
        activeInfo === '' &&
        modalData.length > 0 && (
          <InfoFooter
            text1={'MAKE OFFER'}
            text2={'FINISH'}
            onCancel={() => setActiveInfo('')}
            onSubmit={handleFinish}
            shouldShowAlert={modalData.length > 0}
            disableSubmit={shouldDisableFinish}
          />
        )
      }
    />
  );
};

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: white;
  height: 100%;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;
