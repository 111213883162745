import * as z from "zod"
import { DBConnectionType, DBConnectionProtocol, DBFileFormat, DBRepeatUnit } from ".prisma/client"
import { CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBFtpConnection, RelatedDBFtpConnectionModel, CompleteDBApiConnection, RelatedDBApiConnectionModel, CompleteDBHttpsConnection, RelatedDBHttpsConnectionModel, CompleteDBEmailConnection, RelatedDBEmailConnectionModel } from "./index"

export const DBSupplierConnectionModel = z.object({
  id: z.string(),
  supplierId: z.string().nullish(),
  connectionName: z.string(),
  connectionTypes: z.nativeEnum(DBConnectionType).array(),
  active: z.boolean(),
  connectionProtocol: z.nativeEnum(DBConnectionProtocol),
  fileFormat: z.nativeEnum(DBFileFormat),
  isFileZipped: z.boolean(),
  rulesetKey: z.string().nullish(),
  remark: z.string(),
  ftpConnectionId: z.string().nullish(),
  apiConnectionId: z.string().nullish(),
  httpsConnectionId: z.string().nullish(),
  emailConnectionId: z.string().nullish(),
  createdAt: z.date(),
  minimumUpdateFrequencyUnit: z.nativeEnum(DBRepeatUnit).nullish(),
  minimumUpdateFrequencyTime: z.number().int().nullish(),
})

export interface CompleteDBSupplierConnection extends z.infer<typeof DBSupplierConnectionModel> {
  supplier?: CompleteDBSupplier | null
  ftpConnection?: CompleteDBFtpConnection | null
  apiConnection?: CompleteDBApiConnection | null
  httpsConnection?: CompleteDBHttpsConnection | null
  emailConnection?: CompleteDBEmailConnection | null
}

/**
 * RelatedDBSupplierConnectionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierConnectionModel: z.ZodSchema<CompleteDBSupplierConnection> = z.lazy(() => DBSupplierConnectionModel.extend({
  supplier: RelatedDBSupplierModel.nullish(),
  ftpConnection: RelatedDBFtpConnectionModel.nullish(),
  apiConnection: RelatedDBApiConnectionModel.nullish(),
  httpsConnection: RelatedDBHttpsConnectionModel.nullish(),
  emailConnection: RelatedDBEmailConnectionModel.nullish(),
}))
