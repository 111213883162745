import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel } from "./index"

export const DBHotelModel = z.object({
  id: z.string(),
  clientId: z.string(),
  branchId: z.number().int(),
  shelfId: z.string(),
  code: z.string(),
  qty: z.number().int().nullish(),
  isActive: z.boolean(),
  inboundAt: z.date(),
  outboundAt: z.date(),
})

export interface CompleteDBHotel extends z.infer<typeof DBHotelModel> {
  client: CompleteDBClient
  branch: CompleteDBBranch
  shelf: CompleteDBWarehouseShelfs
}

/**
 * RelatedDBHotelModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBHotelModel: z.ZodSchema<CompleteDBHotel> = z.lazy(() => DBHotelModel.extend({
  client: RelatedDBClientModel,
  branch: RelatedDBBranchModel,
  shelf: RelatedDBWarehouseShelfsModel,
}))
