import styled from '@emotion/styled';
import { useState } from 'react';
import { PageTemplateWrapper } from '../../components/PageTemplate/PageTemplate';
import { CustomerOrdersSidebar } from './CustomerOrdersSidebar';
import CustomerOrderDetails from './order-steps/CustomerOrderDetails';
interface CustomerOrdersProps {
  sidebarOpen: boolean;
}

const CustomerOrdersOverview = (_props: CustomerOrdersProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);

  return (
    <PageTemplateWrapper
      style={{
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <CustomerOrderDetails
        sidebarOpen={true}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        isUploadInProcess={isUploadInProcess}
      />
      <CustomerOrdersSidebar
        setIsUploadInProcess={setIsUploadInProcess}
        isUploadInProcess={isUploadInProcess}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        isProcessOrder={false}
      />
    </PageTemplateWrapper>
  );
};

export default CustomerOrdersOverview;

export const CustomerOrdersDetailsWrapper = styled.div`
  position: relative;
  right: 0;
  height: 100%;
  min-width: 800px;
  max-width: 800px;
  width: 100%;
`;
