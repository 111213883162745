import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { DBUserRole } from '@prisma/client';
import { DBRoleApi } from '@tyrio/dto';
import { PermissionsValues, UserRolePermissions } from '@tyrio/forms';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import api from '@tyrio/api-factory';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';

import RoleCustomForm from './RoleCustomForm';
import {
  defaultAdminPermissions,
  defaultClientPermissions,
} from './types/FormData';
import { mergePermissions } from './helper';

interface RoleUpsertFormProps {
  permissions?: PermissionsValues;
  clientId: string;
}

const RoleUpsertForm = ({ permissions, clientId }: RoleUpsertFormProps) => {
  const { roleId } = useParams<{ roleId: string }>();

  // const { isLocked, lockedBy } = useLockEntity(
  //   SSE_ENTITY_KEYS.ROLE,
  //   roleId,
  //   roleId === 'new'
  // );

  const defaultPermissions = clientId
    ? defaultClientPermissions
    : defaultAdminPermissions;

  const isNewRole = window.location.pathname.split('/').pop() === 'new';

  //GET Role by Id
  const {
    data: role_data,
    isFetching: isRoleDataFetching,
    refetch: getRoleById,
  } = useQuery(
    ['role_id', roleId, 'update_role'],
    () =>
      api.fetch<DBRoleApi['getOne']>(`role_id`, {
        id: roleId,
      }),
    {
      enabled: !!roleId,
    }
  );

  useEffect(() => {
    getRoleById();
  }, [roleId, getRoleById]);

  if (isRoleDataFetching && typeof role_data !== 'undefined') {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }
  if (role_data || isNewRole) {
    const data = {
      roleName: role_data?.roleName || '',
      roleDescription: role_data?.roleDescription || '',
      permissions:
        (role_data?.permissions as unknown as UserRolePermissions[]) ||
        defaultPermissions,
      active: role_data?.active || false,
      type: role_data?.type || DBUserRole.ADMIN,
    };

    const updatedPermissions = mergePermissions(
      defaultPermissions,
      role_data?.permissions as unknown as UserRolePermissions[]
    );

    return (
      <PageTemplateContent>
        <RoleCustomForm
          roleId={roleId}
          key={`${roleId || 'new'}`}
          refetch={getRoleById}
          roleName={data.roleName}
          roleDescription={data.roleDescription}
          type={data.type}
          permissions={updatedPermissions}
          active={data.active}
          userPermissions={permissions}
        />
      </PageTemplateContent>
    );
  }
  return null;
};

export default RoleUpsertForm;
