import { Grid } from '@mui/material';
import qs from 'qs';
import StockInSearchTypeInput from '../../stock-in/filter/StockInSearchTypeInput';

interface InvoiceOrderTableFiltersProps {
  filters: qs.ParsedQs;
  setFilters: (data: Partial<qs.ParsedQs>) => void;
}

const InvoiceTableFilters = ({
  filters,
  setFilters,
}: InvoiceOrderTableFiltersProps) => {
  const listItems = [
    { value: 'reference', label: 'Reference' },
    { value: 'invoiceNumber', label: 'Invoice number' },
    { value: 'partner', label: 'Partner' },
    { value: 'branch', label: 'Branch' },
  ];

  return (
    <Grid style={{ paddingBottom: '20px' }}>
      <Grid
        container
        spacing={1.5}
        paddingLeft="16px"
        paddingTop="21px"
        paddingRight="16px"
      >
        <Grid item xs={12} lg={12}>
          <StockInSearchTypeInput
            filters={filters}
            setFilters={setFilters}
            listItems={listItems}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoiceTableFilters;
