/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled/macro';
import {
  TextField,
  Button,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useInboundInvoiceCtx } from '../../../context/InboundInvoiceContext';

interface TotalAmountProps {
  text: string;
  buttonText: string;
  value: string | number;
  setValue: Dispatch<SetStateAction<string | number>>;
  onButtonClick?: () => void;
  showSplitInvoice?: boolean;
  totalAmountIcon?: 'success' | 'error';
}

const handlePreventNegativeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  if (!isNaN(Number(value)) && Number(value) < 0) {
    e.target.value = '0';
  }
};

export const TotalAmount = ({
  text,
  buttonText,
  value,
  setValue,
  onButtonClick,
  showSplitInvoice = false,
  totalAmountIcon,
}: TotalAmountProps) => {
  const { splitInvoice, setSplitInvoice } = useInboundInvoiceCtx();
  return (
    <DashedWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text>{text}</Text>
        {showSplitInvoice && (
          <FormControlLabel
            control={
              <Switch
                data-cy="switch"
                color="info"
                size="small"
                onChange={() => setSplitInvoice(!splitInvoice)}
                checked={splitInvoice}
                disabled={false}
              />
            }
            label={'split invoice'}
          />
        )}
      </div>

      <div style={{ display: 'flex', gap: '10px' }}>
        <TextField
          label="Enter total amount"
          variant="outlined"
          autoComplete="off"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          helperText={
            !totalAmountIcon || totalAmountIcon === 'success'
              ? ''
              : 'Total amount doesn’t match'
          }
          // error={
          //   totalAmountIcon === 'success' || !totalAmountIcon ? false : true
          // }
          type={'number'}
          InputProps={{
            inputProps: {
              onInput: handlePreventNegativeInput,
            },
            endAdornment: (
              <InputAdornment position="start">
                {totalAmountIcon && totalAmountIcon === 'success' ? (
                  <CheckCircleIcon color="success" />
                ) : totalAmountIcon ? (
                  <WarningIcon color="error" />
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        <Button color="info" onClick={() => onButtonClick && onButtonClick()}>
          {buttonText}
        </Button>
      </div>
    </DashedWrapper>
  );
};

const DashedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  padding: 16px;
  gap: 20px;
`;

const Text = styled.div`
  color: #919eab;
  text-overflow: ellipsis;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
