import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const INTERSPRINT_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.group',
    condition: 'equals',
    conditionValues: [
      '11',
      '13',
      '25',
      '28',
      '29',
      '39',
      '16',
      '24',
      '37',
      '17',
      '23',
      '38',
      '33',
    ],
    conditionOutputs: [
      '30',
      '30',
      '30',
      '30',
      '30',
      '30',
      '32',
      '32',
      '32',
      '31',
      '31',
      '31',
      '34',
    ],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: "c.['brand description']",
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // SENTURY FIX
  {
    type: 'rule.condition',
    sourceKey: "c['description']",
    condition: 'contains',
    conditionValue: 'SENTURY',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'brand',
    outputValue: 'SENTURY',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.group',
    condition: 'equals',
    conditionValues: [
      '11',
      '16',
      '17',
      '25',
      '33',
      '13',
      '23',
      '24',
      '28',
      '29',
      '37,',
      '38',
      '39',
    ],
    conditionOutputs: [
      'summer',
      'summer',
      'summer',
      'summer',
      'summer',
      'winter',
      'winter',
      'winter',
      'winter',
      'allseason',
      'allseason',
      'allseason',
      'allseason',
    ],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Model
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'modelName',
    operationName: 'globalGetIntersprintModelName',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'productName2',
    template: '',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.eancode',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['ip-code']",
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  // {
  //   type: 'rule.map',
  //   sourceKey: "c.['width tyre']",
  //   targetKey: 'width',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  // {
  //   type: 'rule.replace',
  //   targetKey: 't.product.width',
  //   matchValue: ['750', '1250', '1050', '400', '350', '410', '1150', '500'],
  //   replaceValue: ['7.50', '12.50', '10.50', '4.00', '3.50', '4.10', '11.50', '5.00'],
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: "c.['aspect ratio']",
  //   targetKey: 'height',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.diameter',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'modelName',
    operationName: 'globalGetIntersprintModelName',
  },
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetIntersprintLoadSpeedIndex',
  },
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetIntersprintSpecialWidthHeight',
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: 'c.weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  // Construction
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'construction',
    template: 'R',
  },
  // Load index and Speed Index
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetIntersprintLoadSpeedIndex',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'getIntersprintSpecialMarkings',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.rfd',
    condition: 'contains',
    conditionValue: 'XL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: 'XL',
    outputType: 'string',
  },
  // ThreePMFs
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Snowgrip',
    condition: 'equals',
    conditionValues: ['Y', 'N', ''],
    conditionOutputs: [true, false, ''],
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
  },
  // iceGrip
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Icegrip',
    condition: 'equals',
    conditionValues: ['Y', 'N', ''],
    conditionOutputs: [true, false, ''],
    destination: 'product',
    targetKey: 'iceGrip',
    outputType: 'boolean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Fuel effeciency"]',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },

  // Globalni fieldovi
  {
    type: 'rule.map',
    sourceKey: "c['Wet grip']",
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['Rollnoise']",
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Noiselevel',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: "c.['C Class']",
  //   targetKey: 'vehicleClass',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: "c['EPREL-id']",
    targetKey: 'eprelId',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['EPREL-url']",
    targetKey: 'eprelUrl',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.photolink',
    targetKey: 'originalPhotos',
    delimiter: ',',
    destination: 'model',
    outputType: 'string',
  },

  ...GLOBAL_RULESET,
];
