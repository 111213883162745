import styled from '@emotion/styled/macro';
import { Badge, Chip, CircularProgress } from '@mui/material';
import {
  DBOrderDocument,
  DBWorkOrders,
  WorkOrdersStatus,
} from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBCustomerOrderStatusEnum,
  DBWorkOrdersApi,
  IWorkOrderLineItem,
  OrderStatus,
} from '@tyrio/dto';
import { arrow, documentIcon, pdfFile } from '@tyrio/ui-library';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  DocumentDetailsWrapper,
  DocumentsWrapper,
  FileIconNameWrapper,
  IconsWrapper,
  ReferenceWrapper,
} from '../../components/UploadDocuments/UploadDocuments';
import { WorkOrderStatusColors } from '../purchase-orders/helpers/generate-color';

const OrderTitle = {
  WORK_ORDER: 'Radni nalog',
};

export const WorkOrderDetails = ({
  workOrders,
  customerOrderId,
}: {
  workOrders?: DBWorkOrders[];
  customerOrderId?: string;
}) => {
  const [selectedOrder, setSelectedOrder] = useState<DBWorkOrders | null>(null);
  const [orderDocuments, setOrderDocuments] = useState<DBOrderDocument[] | []>(
    []
  );

  const {
    data: printDetails,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(
    ['get_work_orders_print_data', selectedOrder?.id],
    async () => {
      return await api.fetch<DBWorkOrdersApi['preparePrintData']>(
        'get_work_orders_print_data',
        {
          id: selectedOrder?.id ?? '',
        }
      );
    },
    {
      enabled: selectedOrder !== null,
    }
  );

  useQuery(
    ['get_work_orders_delivery_notes', customerOrderId],
    async () => {
      return await api.fetch<DBWorkOrdersApi['deliveryNotes']>(
        'get_work_orders_delivery_notes'
      );
    },
    {
      onSuccess(data) {
        if (data.length > 0)
          setOrderDocuments(
            data.filter((item) => item.customerOrderItemId === customerOrderId)
          );
      },
    }
  );

  useEffect(() => {
    if (
      selectedOrder &&
      !isLoading &&
      isSuccess &&
      printDetails &&
      printDetails !== '' &&
      printDetails.includes(selectedOrder.id)
    )
      window.open(printDetails, '_blank');
  }, [isLoading, isSuccess, printDetails, selectedOrder, selectedOrder?.id]);

  const handleClick = (item: DBWorkOrders) => {
    setSelectedOrder(item);
    refetch();
    setTimeout(() => {
      if (
        selectedOrder &&
        !isLoading &&
        isSuccess &&
        printDetails &&
        printDetails !== '' &&
        printDetails.includes(selectedOrder.id)
      )
        window.open(printDetails, '_blank');
    }, 1000);
  };

  return (
    <DocumentsWrapper disabled={false}>
      {workOrders &&
        workOrders.map((item: DBWorkOrders) => {
          const orderStatus = item.orderStatus as unknown as OrderStatus;
          const subStatus = orderStatus.subStatus;

          const status = orderStatus.status;

          const docId = item.erpOrderId;

          const hasReservation = (
            item.lineItems as unknown as IWorkOrderLineItem[]
          ).every(
            (i) => i.reservedOnLocation && !_.isEmpty(i.reservedOnLocation)
          );

          return (
            <DocumentDetailsWrapper
              key={item.id}
              onClick={() => handleClick(item)}
              style={{ cursor: 'pointer' }}
            >
              <Badge
                color={hasReservation ? 'success' : 'error'}
                variant="dot"
              />
              <FileIconNameWrapper>
                <img src={documentIcon} alt="file-icon" />
                <p>
                  {OrderTitle.WORK_ORDER}{' '}
                  {docId && (
                    <span
                      style={{ color: '#919EAB', fontSize: '14px' }}
                    >{`(${docId})`}</span>
                  )}
                </p>
              </FileIconNameWrapper>

              <ReferenceWrapper>
                <p>{item.orderNumber}</p>
              </ReferenceWrapper>

              {!isLoading ? (
                <IconsWrapper>
                  <Chip
                    size="small"
                    label={item.externalStatus}
                    sx={{
                      bgcolor:
                        WorkOrderStatusColors[
                          item.externalStatus as unknown as WorkOrdersStatus
                        ] ?? 'white',
                      color:
                        item.externalStatus === 'NEW' ? '#919EAB' : 'white',
                      border:
                        item.externalStatus === 'NEW'
                          ? '1px solid #919EAB'
                          : 'none',
                      cursor: 'pointer',
                    }}
                  />

                  {status === DBCustomerOrderStatusEnum.ORDERED && (
                    <Chip
                      size="small"
                      label={orderStatus.status}
                      sx={{
                        bgcolor: '#9370DB',
                        color: 'white',
                        border: '1px solid #9370DB',
                        cursor: 'pointer',
                      }}
                    />
                  )}

                  {subStatus !== '' && (
                    <Chip
                      size="small"
                      label={orderStatus.subStatus}
                      sx={{
                        bgcolor: 'white',
                        color: '#919EAB',
                        border: '1px solid #919EAB',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  <img
                    src={arrow}
                    alt="arrow-icon"
                    style={{ cursor: 'pointer' }}
                  />
                </IconsWrapper>
              ) : (
                <CircularProgress
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              )}
            </DocumentDetailsWrapper>
          );
        })}

      {orderDocuments &&
        orderDocuments.map((item: DBOrderDocument) => (
          <DocumentDetailsWrapper
            key={item.id}
            onClick={() => window.open(item.url, '_blank')}
            style={{ marginTop: workOrders ? '20px' : '' }}
          >
            <FileIconNameWrapper>
              <img src={pdfFile} alt="pdf-icon" />
              <p>{item.description}</p>
            </FileIconNameWrapper>
            <ReferenceWrapper>
              <p>{item.reference}</p>
            </ReferenceWrapper>
            <IconsWrapper style={{ cursor: 'pointer' }}>
              <Chip
                size="small"
                label={'Poslano'}
                sx={{
                  bgcolor: '#3EB274',
                  color: 'white',
                  cursor: 'pointer',
                }}
              />
              {item.url && (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  download
                >
                  <img
                    src={arrow}
                    alt="arrow-icon"
                    style={{ cursor: 'pointer' }}
                  />
                </a>
              )}
            </IconsWrapper>
          </DocumentDetailsWrapper>
        ))}
    </DocumentsWrapper>
  );
};

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 40%;
`;
