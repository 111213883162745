/* eslint-disable @typescript-eslint/no-empty-function */
import { SelectedLocation, WarehouseBranchResponse } from '@tyrio/dto';
import { Dispatch, SetStateAction, createContext } from 'react';

export type ActiveStep = 'UPLOAD' | 'WAREHOUSE' | 'DETAILS';

export type DataType = 'warehouse' | 'preview';

export interface UpdateWarehouseReq {
  create?: {
    zones?: string[];
    subzones?: string[];
    floors?: string[];
    racks?: string[];
    sections?: string[];
    shelfs?: string[];
    bins?: string[];
  };
  update?: {
    zones?: string[];
    subzones?: string[];
    floors?: string[];
    racks?: string[];
    sections?: string[];
    shelfs?: string[];
    bins?: string[];
  };
}

type WarehouseContextInterface = {
  warehouse: WarehouseBranchResponse | null;
  setWarehouse: Dispatch<SetStateAction<WarehouseBranchResponse | null>>;
  req: UpdateWarehouseReq;
  setReq: Dispatch<SetStateAction<UpdateWarehouseReq>>;
  activeStep: ActiveStep;
  setActiveStep: Dispatch<SetStateAction<ActiveStep>>;
  selected: SelectedLocation;
  setSelected: Dispatch<SetStateAction<SelectedLocation>>;
  isNewModalOpen: boolean;
  setIsNewModalOpen: Dispatch<SetStateAction<boolean>>;
  clearSelected: () => void;
};

export const WarehouseContext = createContext<WarehouseContextInterface>({
  activeStep: 'UPLOAD',
  setActiveStep: () => {},
  req: {
    create: {
      zones: [],
      subzones: [],
      floors: [],
      racks: [],
      sections: [],
      shelfs: [],
      bins: [],
    },
    update: {
      zones: [],
      subzones: [],
      floors: [],
      racks: [],
      sections: [],
      shelfs: [],
      bins: [],
    },
  },
  setReq: () => {},
  warehouse: null,
  setWarehouse: () => {},
  clearSelected: () => {},
  selected: {
    current: ['zone'],
    locations: {
      zone: null,
      subzone: null,
      floor: null,
      rack: null,
      section: null,
      shelf: null,
      bin: null,
    },
  },
  setSelected: () => {},
  isNewModalOpen: false,
  setIsNewModalOpen: () => {},
});

interface WarehouseProviderProps {
  children: React.ReactNode;
  value: WarehouseContextInterface;
}

const WarehouseProvider = ({ children, value }: WarehouseProviderProps) => {
  return (
    <WarehouseContext.Provider value={value}>
      {children}
    </WarehouseContext.Provider>
  );
};

export default WarehouseProvider;
