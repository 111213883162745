import { TRule } from '../../typings';

export const INTERCARS_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.operate',
    sourceKey: "c.['Manufacturer_Code']",
    destination: 'product',
    targetKey: 'ean',
    operationName: 'getIntercarsEAN',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Manufacturer_Code']",
    destination: 'product',
    targetKey: 'manufacturerCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Quantity']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
