import api from '@tyrio/api-factory';
import { DBSalesOrdersApi } from '@tyrio/dto';
import * as _ from 'lodash';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { OrderScreenV2 } from '../../pages/price-compare/components/OrderScreenV2';
import { PriceComparisonContext } from '../../pages/price-compare/components/context';
import { PriceCompareInputShape } from '../../pages/price-compare/types';
import { SalesOrderData } from './SalesOrderData';
import {
  SalesOrderQty,
  SalesOrdersFlowContext,
} from './SalesOrdersFlowContext';
import { WorkOrderContextProvide } from '../../context/WorkOrderContext';

export const SalesOrdersFlow = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const [state, setState] = useLocalStorage<{
    data: DBSalesOrdersApi['prepareOrder']['response'];
    qty: SalesOrderQty;
    /**
     * `productId => supplierId`
     */
    selected: Record<string, string>;
    screen: 'process_order' | 'purchase_order';
  }>(`sales_orders_${orderId}`, {
    data: {
      products: {},
      prices: {},
      requested: {},
      supplierQty: {},
      deliveryDates: {},
      orderRef: '',
      suppliers: {},
      purchased: {},
      warehouses: {},
      warehouseQty: {},
      lineItems: [],
      historySupplier: {},
    },
    selected: {},
    screen: 'process_order',
  });

  const [input, setInput] = useLocalStorage<PriceCompareInputShape>(
    `sales_orders_purchase_${orderId}`,
    {
      screen: 'intro',
      dirty: false,
      type: 'code',
      transportPriority: 'RUSH',
      isOrderAutomaticallyFilled: false,
      code: {
        searchType: 'ean',
      },
    }
  );

  const resetInputValue = (
    newData: Partial<PriceCompareInputShape>,
    oldValue: PriceCompareInputShape
  ) => {
    Object.entries(newData).forEach(([key, value]) => {
      if (!_.isEqual(value, _.get(oldValue, key))) _.set(oldValue, key, value);
    });
  };

  const setInputValue = useCallback(
    (newData: Partial<PriceCompareInputShape>, hardSet?: boolean) => {
      setInput((oldValue: PriceCompareInputShape) => {
        if (hardSet) {
          return Object.assign(
            {},
            { dirty: true, ...newData }
          ) as PriceCompareInputShape;
        } else {
          // return _.cloneDeep(_.mergeWith(oldValue, { dirty: true }, newData));
          resetInputValue(newData, oldValue);
          return { ...oldValue };
        }
      });
    },
    [setInput]
  );

  const { refetch } = useQuery(
    ['sales_order', orderId],
    () =>
      api.fetch<DBSalesOrdersApi['prepareOrder']>('prepare_sales_order', {
        orderId,
      }),
    {
      onSuccess: (data) => {
        const purchasedQty = data.purchased;

        const supplierQty = { ...state.qty?.supplier, ...purchasedQty };

        setState({
          ...state,
          // ...selected,
          qty: {
            supplier: supplierQty,
            stock: { ..._.get(state, `qty.stock`, {}) },
          },
          data,
        });
      },
    }
  );

  const setScreen = useCallback(
    (screen: 'process_order' | 'purchase_order') => {
      refetch();

      setState({
        ...state,
        // qty: { stock: {}, supplier: {} },
        screen,
      });
    },
    [refetch, setState, state]
  );

  const setQty = useCallback(
    (
      supplierId: string,
      productId: string,
      qty: number,
      key: 'supplier' | 'stock',
      hardSet?: boolean
    ) => {
      const purchasedQty = _.get(
        state.data.purchased,
        `${supplierId}.${productId}`,
        0
      );

      if (hardSet) {
        setState({
          ...state,
          qty: {
            ...state.qty,
            [key]: {},
          },
        });
      } else {
        setState({
          ...state,
          qty: {
            ...state.qty,
            [key]: {
              ...state.qty[key],
              [supplierId]: {
                ..._.get(state, `qty.${key}.${supplierId}`, {}),
                [productId]: _.sum([
                  qty,
                  key === 'supplier' ? purchasedQty : 0,
                ]),
              },
            },
          },
        });
      }
    },
    [setState, state]
  );

  const batchQtyUpdate = (newQuantity: SalesOrderQty) => {
    setState((prevState) => {
      const oldQuantity = prevState.qty;

      return {
        ...prevState,
        qty: _.merge(oldQuantity, newQuantity),
      };
    });
  };

  const setSelected = useCallback(
    (supplierId: string, productId: string) => {
      setState({
        ...state,
        selected: {
          ...state.selected,
          [productId]: supplierId,
        },
      });
    },
    [setState, state]
  );

  return (
    // <PageGridWrapper template="auto 600px">
    <SalesOrdersFlowContext.Provider
      value={{
        dirty: true,
        customerOrderId: orderId,
        data: state.data,
        qty: state.qty,
        setQty,
        selected: state.selected,
        setSelected,
        screen: state.screen,
        setScreen,
        batchQtyUpdate,
      }}
    >
      <WorkOrderContextProvide>
        <PriceComparisonContext.Provider
          value={{
            mode: 'sales_orders',
            data: input,
            hasPlacedOrders: false,
            suppliers: [],
            isFetchingSuppliers: false,
            dimensions: [],
            isFetchingDimensions: false,
            setInputValue,
            clearDirtyForm: () => null,
            reset: () => null,
            mutateCode: () => new Promise((res) => res),
            mutateDimensions: () => new Promise((res) => res),
            processOrder: () => null,
            isFetchingCodeRequest: false,
            isFetchingDimensionsRequest: false,
            canSidebarOpen: false,
          }}
        >
          {state.screen === 'process_order' && <SalesOrderData />}
          {state.screen === 'purchase_order' && <OrderScreenV2 />}
        </PriceComparisonContext.Provider>
      </WorkOrderContextProvide>
    </SalesOrdersFlowContext.Provider>
    // </PageGridWrapper>
  );
};
