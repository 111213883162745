import styled from '@emotion/styled/macro';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  DBConnectionType,
  DBFile,
  DBSupplierLocationType,
} from '@prisma/client';
import api, { ENDPOINTS } from '@tyrio/api-factory';
import {
  DBCountryApi,
  DBSupplierApi,
  DBSupplierApiConnectionApi,
  DBSupplierConnectionApi,
  DBSupplierContactApi,
  DBSupplierEmailConnectionApi,
  DBSupplierFtpConnectionApi,
  DBSupplierHttpsConnectionApi,
  DBSupplierLocationApi,
} from '@tyrio/dto';
import SourceContainer, {
  FormControllerWrapper,
  HeaderWrapper,
  TabsWrapper,
  ViesInputCustom,
} from '@tyrio/forms';
import { RULESET_KEYS } from '@tyrio/shared-vars';
import {
  CancelModal,
  DeleteModal,
  RouteRouterPrompt,
  Title,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
} from '@tyrio/ui-library';
import axios from 'axios';
import _, { isEqual, startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import slugify from 'slugify';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';
import addNewLogo from './assets/add-new-logo.svg';

import {
  AddNewRepeaterFieldButton,
  AlertField,
  DeleteRepeaterFieldButton,
  ExpandCollapseButtons,
  FileControlButtons,
  InputLabelStyle,
  MultiSelectInput,
  PasswordField,
  PhoneNumberInput,
  SelectList,
  TextFieldInput,
  UploadCompanyLogoButton,
} from './helpers/components';
import {
  dropdownDataDefaultProps,
  expandedDataDefaultProps,
  formDataDefaultProps,
  getDefaultObject,
  getSupplierData,
  supplierLocationsDefaultProps,
} from './helpers/default-values';
import { charsAndNumbersRegex, emailRegex } from './helpers/regex';
import { DropdownDataProps, Event, ExpandDataProps } from './helpers/types';
import { ERROR_MESSAGES, handleValidate } from './helpers/validation';

const SupplierForm = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const { supplierId } = useParams<{ supplierId: string }>();

  //STATES
  const [formData, setFormData] =
    useState<DBSupplierApi['create']['requestBody']>(formDataDefaultProps);

  const [dropdownsData, setDropdownData] = useState<DropdownDataProps>({
    ...dropdownDataDefaultProps,
  });

  const [expandedData, setExpandedData] = useState<ExpandDataProps>({
    ...expandedDataDefaultProps,
  });

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [headquarter, setHeadquarter] = useState(false);

  const isNew = useMemo(
    () => location.pathname.split('/').pop() === 'new',
    [location.pathname]
  );

  const {
    data: supplier_data,
    isFetching: isSupplierDataFetching,
    refetch: getSupplierById,
  } = useQuery(
    ['get_supplier_id', supplierId, 'get_supplier_id'],
    () =>
      api.fetch<DBSupplierApi['getOne']>(`supplier_id`, {
        id: supplierId,
      }),
    {
      onSuccess: (res) => {
        const { address, city, zipCode, countryId, supplierLocations } = res;

        const headquarterLocation = supplierLocations.filter(
          (f) => f.isHeadQuarter
        )[0];

        const obj = {
          address,
          city,
          zipCode,
          countryId,
        };

        const obj1 = {
          address: headquarterLocation?.adress,
          city: headquarterLocation?.city,
          zipCode: headquarterLocation?.zipCode,
          countryId: headquarterLocation?.countryId,
        };

        const eq = isEqual(obj, obj1);
        if (eq) setHeadquarter(true);
      },
      enabled: !!supplierId,
    }
  );

  const [companyLogoUrl, setCompanyLogoUrl] = useState(
    supplier_data?.companyLogo?.fileUrl
  );
  const [urlLoading, setUrlLoading] = useState(false);

  const updateSupplierLogo = useCallback(() => {
    if (isSupplierDataFetching === false && supplier_data) {
      setCompanyLogoUrl(supplier_data.companyLogo?.fileUrl);
      setUrlLoading(false);
    }
  }, [isSupplierDataFetching, supplier_data]);

  useEffect(() => {
    if (supplierId !== undefined) {
      setUrlLoading(true);
      updateSupplierLogo();
    }
  }, [supplierId, updateSupplierLogo]);

  //QUERIES AND MUTATIONS
  const uploadCompanyLogoMutation = useMutation(
    (formData: {
      method: string;
      url: string;
      data: FormData;
      headers: {
        Authorization: string;
        'Content-Type': string;
      };
    }) => axios.request(formData),
    {
      mutationKey: 's3_add_supplier_logo',
      onSuccess: (res: { data: DBFile }) => {
        setFormData({ ...formData, companyLogoId: res.data.id });
        setCompanyLogoUrl(res.data.fileUrl);
      },
      onError: () => {
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const createSupplierMutation = useMutation(
    () => {
      return api.fetch<DBSupplierApi['create']>('create_supplier', {
        ...formData,
      });
    },
    {
      mutationKey: 'create_supplier',
      onSuccess: (item) => {
        queryClient.refetchQueries('supplier_list');
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/supplier/${item.id}`);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setDropdownData({
          ...dropdownsData,
          countries: dropdownItems,
        });
      },
    }
  );

  const deleteSupplierMutation = useMutation(
    () =>
      api.fetch<DBSupplierApi['getOne']>('delete_supplier', {
        id: supplierId,
      }),
    {
      mutationKey: 'delete_supplier',
      onSuccess: () => {
        queryClient.refetchQueries('supplier_list');
        history.push(`/dashboard/supplier`);
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const updateSupplierMutation = useMutation(
    () => {
      return api.fetch<DBSupplierApi['create']>('update_supplier', {
        id: supplierId,
        ...formData,
      });
    },
    {
      mutationKey: 'update_supplier',
      onSuccess: () => {
        queryClient.refetchQueries('supplier_list');
        getSupplierById();
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const updateSupplierStatusMutation = useMutation(
    () =>
      api.fetch<DBSupplierApi['create']>('change_status_supplier', {
        id: supplierId,
        ...formData,
      }),
    {
      mutationKey: 'update_supplier',
      onSuccess: () => {
        queryClient.refetchQueries('supplier_list');
        getSupplierById();
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  //FUNCTIONS
  const onImageChange = (e: Event<HTMLInputElement>) => {
    if (e !== null && e.target !== null && e.target.files !== null) {
      const file = e.target.files[0];
      const form = new FormData();
      const token = window.localStorage.getItem('@@apiToken');

      form.append('file', file, 'company-logo');

      const options = {
        method: 'PUT',
        url: ENDPOINTS.s3_add_supplier_logo.uri,
        data: form,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
        },
      };

      uploadCompanyLogoMutation.mutate(options);
    }
  };

  function onValueChange<T>(
    attributeKey: keyof DBSupplierApi['create']['requestBody'],
    value: T
  ) {
    setFormData({
      ...formData,
      ...{ [attributeKey]: value },
    });
    clearErrors(attributeKey);
  }

  function onRepeaterFieldValueChange<T>(
    mainKey: keyof DBSupplierApi['create']['requestBody'],
    attributeKey:
      | keyof DBSupplierContactApi['create']['requestBody']
      | keyof DBSupplierLocationApi['create']['requestBody']
      | keyof DBSupplierConnectionApi['create']['requestBody'],
    value: T,
    index: number,
    nestedAttributeKey?:
      | keyof DBSupplierFtpConnectionApi['create']['requestBody']
      | keyof DBSupplierApiConnectionApi['create']['requestBody']
      | keyof DBSupplierHttpsConnectionApi['create']['requestBody']
      | keyof DBSupplierEmailConnectionApi['create']['requestBody']
  ) {
    const object = formData[mainKey];
    if (typeof object !== 'object' && !Array.isArray(object)) return;
    if (object !== null) {
      if (nestedAttributeKey)
        object[index][attributeKey][nestedAttributeKey] = value;
      else object[index][attributeKey] = value;
    }

    setFormData({
      ...formData,
      [mainKey]: object,
    });
    !nestedAttributeKey
      ? clearErrors(`${mainKey}-${attributeKey}-${index}`)
      : clearErrors(
          `${mainKey}-${attributeKey}-${nestedAttributeKey}-${index}`
        );
  }

  const onAddNewRepeaterField = (
    attributeKey: keyof DBSupplierApi['create']['requestBody'],
    expandedKey: keyof ExpandDataProps,
    expandedCount: number
  ) => {
    const object = formData[attributeKey];
    if (typeof object !== 'object') return;
    !!object &&
      setFormData({
        ...formData,
        [attributeKey]: [...object, { ...getDefaultObject(attributeKey) }],
      });
    setExpandedData({
      ...expandedData,
      [expandedKey]: [expandedCount],
    });
  };

  const onDeleteRepeaterField = (
    attributeKey: keyof DBSupplierApi['create']['requestBody'],
    elementIndex: number
  ) => {
    const object = formData[
      attributeKey
    ] as keyof DBSupplierApi['create']['requestBody'];
    if (typeof object !== 'object' && !Array.isArray(object)) return;
    const filteredArray = object.filter(
      (item: keyof DBSupplierApi['create']['requestBody'], index: number) =>
        index !== elementIndex
    );
    setFormData({
      ...formData,
      [attributeKey]: [...filteredArray],
    });
  };

  const onExpandedDataChange = (
    attributeKey: keyof ExpandDataProps,
    value: number[]
  ) => {
    const object = expandedData[attributeKey];
    if (typeof object !== 'object' && !Array.isArray(object)) return;
    setExpandedData({
      ...expandedData,
      [attributeKey]: value,
    });
  };

  const addLocationWhenHeadquarterIsActive = () => {
    const object = formData['supplierLocations'];
    if (isEqual(formData, formDataDefaultProps)) {
      object[0].adress = formData.address;
      object[0].city = formData.city;
      object[0].countryId = formData.countryId;
      object[0].zipCode = formData.zipCode;

      setFormData({
        ...formData,
        supplierLocations: object,
      });
    } else {
      const findHeadquarter = object.filter((o) => o.isHeadQuarter)[0];
      findHeadquarter.adress = formData.address;
      findHeadquarter.city = formData.city;
      findHeadquarter.countryId = formData.countryId;
      findHeadquarter.zipCode = formData.zipCode;
      setFormData({ ...formData });
    }
  };

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  const onCreateSupplier = async () => {
    if (headquarter) addLocationWhenHeadquarterIsActive();

    setIsSaving(true);
    const triggerResponse = await trigger();
    const fieldErrors: string[] = [];
    // TODO: figure out better way to clear these errors, clearErrors doesn't work well without manuel clearing attribute by attribute
    if (formData.slug !== '') clearErrors('slug');
    if (formData.supplierType !== '') clearErrors('supplierType');
    if (formData.city !== '') clearErrors('city');
    if (formData.address !== '') clearErrors('address');

    formData.supplierConnections.forEach((formDataConnection, idx) => {
      if (formDataConnection.fileFormat !== '')
        clearErrors(`supplierConnections-fileFormat-${idx}`);
      if (formDataConnection.connectionProtocol !== '')
        clearErrors(`supplierConnections-connectionProtocol-${idx}`);

      if (formDataConnection.rulesetKey !== '')
        clearErrors(`supplierConnections-rulesetKey-${idx}`);
    });

    if (Object.values(errors).length > 0) {
      Object.keys(errors).forEach((error) => {
        let errorKey = startCase(error);

        if (errorKey.includes('Supplier Locations'))
          errorKey = errorKey.replace('Supplier Locations ', '');

        if (errorKey.includes('Supplier Contacts'))
          errorKey = errorKey.replace('Supplier Contacts ', '');

        if (errorKey.includes('Supplier Connections'))
          errorKey = errorKey.replace('Supplier Connections ', '');

        if (errorKey.includes('Id')) errorKey = errorKey.replace('Id', '');

        if (errorKey.includes('0')) errorKey = errorKey.replace('0', '');

        fieldErrors.push(errorKey);
      });
    }

    const uniqFieldErrors = _.uniq(fieldErrors);

    if (!triggerResponse && uniqFieldErrors && uniqFieldErrors.length > 0) {
      ToastHelper.showToast(
        'Supplier',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        uniqFieldErrors.length === 1
          ? 'Field ' + uniqFieldErrors + ' has errors.'
          : 'Fields ' +
              uniqFieldErrors.map((err) => ' ' + err) +
              ' have errors.'
      );
      return;
    }
    if (supplierId) updateSupplierMutation.mutate();
    else createSupplierMutation.mutate();
  };

  const handleDeleteSupplier = () => {
    if (supplierId) setIsDeleteModalVisible(true);
  };

  const handleCancelSupplier = () => {
    if (isDirty || !isNew) {
      setIsCancelModalVisible(true);
      return;
    }
    history.push('/dashboard/supplier');
  };

  useEffect(() => {
    let data = {
      ...formDataDefaultProps,
      supplierLocations: [{ ...supplierLocationsDefaultProps }],
    };
    if (supplierId && supplier_data) {
      data = getSupplierData(supplier_data);
    }
    setFormData(data);
    reset(data);
    setExpandedData({ ...expandedDataDefaultProps });
  }, [reset, supplierId, supplier_data]);

  const supplierName = supplier_data?.companyOfficialName + ' supplier';

  const getValidationProps = (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => {
    return handleValidate(
      errors,
      register,
      key,
      validationRules,
      validationText
    );
  };

  useEffect(() => {
    if (isDirty || !isNew)
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
  }, [isDirty, isNew]);

  const companyErrors = [
    'address',
    'city',
    'companyOfficialName',
    'companyShortName',
    'countryId',
    'slug',
    'supplierType',
    'zipCode',
    'vatNumber',
  ];

  return (
    <PageTemplateContent>
      {!isCancelModalVisible && (
        <RouteRouterPrompt
          when={isDirty && !isSaving}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => true}
        />
      )}
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            history.push(`/dashboard/supplier`);
            setIsCancelModalVisible(false);
          }}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            deleteSupplierMutation.mutate();
            setIsDeleteModalVisible(false);
          }}
          itemName={supplierName as string}
        />
      )}
      {isSupplierDataFetching && supplierId ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <FormControllerWrapper>
          <HeaderWrapper>
            <Title>
              {supplier_data?.companyOfficialName
                ? supplier_data?.companyOfficialName
                : 'Create new supplier'}
            </Title>
            {supplierId && (
              <FormControlLabel
                control={
                  <Switch
                    color="info"
                    onChange={(e) => {
                      onValueChange('isActive', e.target.checked);
                      if (supplierId) updateSupplierStatusMutation.mutate();
                    }}
                    checked={formData.isActive}
                  />
                }
                label="Active"
              />
            )}
          </HeaderWrapper>
          <TabsWrapper>
            <form onSubmit={handleSubmit(onCreateSupplier)}>
              <TyrioTabs>
                <TyrioTab
                  title="Company"
                  key="company"
                  errored={
                    Object.keys(errors).find((f) =>
                      companyErrors.includes(f)
                    ) !== undefined
                  }
                >
                  <UploadLogoContainer>
                    {uploadCompanyLogoMutation.isError && (
                      <ErrorWrapper>
                        <ErrorIcon color="error" fontSize="large" />
                        <ErrorText>
                          An error occurred. Please try again!
                        </ErrorText>
                        <UploadCompanyLogoButton
                          text="ADD LOGO"
                          onImageChange={onImageChange}
                        />
                      </ErrorWrapper>
                    )}
                    {(uploadCompanyLogoMutation.isLoading ||
                      isSupplierDataFetching ||
                      urlLoading) && (
                      <LoadingWrapper>
                        <CircularProgress />
                        {uploadCompanyLogoMutation.isLoading && (
                          <LoadingText>Uploading logo...</LoadingText>
                        )}
                      </LoadingWrapper>
                    )}
                    {!uploadCompanyLogoMutation.isError &&
                      !uploadCompanyLogoMutation.isLoading &&
                      !urlLoading && (
                        <SupplierLogoWrapper>
                          <SupplierLogo
                            src={
                              formData.companyLogoId === null
                                ? addNewLogo
                                : companyLogoUrl
                            }
                            alt="supplier-logo"
                          />
                          <UploadCompanyLogoButton
                            text={
                              formData.companyLogoId
                                ? 'CHANGE LOGO'
                                : 'ADD LOGO'
                            }
                            onImageChange={onImageChange}
                          />
                        </SupplierLogoWrapper>
                      )}
                  </UploadLogoContainer>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectList
                        value={formData.supplierType}
                        label="Supplier Type *"
                        dropdownData={dropdownsData.supplierTypes}
                        onChange={(e) =>
                          onValueChange('supplierType', e.target.value)
                        }
                        validationProps={{
                          ...getValidationProps('supplierType'),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ViesInputCustom
                        key={'vatNumber'}
                        label={'VAT number *'}
                        value={formData.vatNumber}
                        register={register}
                        onChange={(e) => {
                          onValueChange('vatNumber', e.target.value);
                        }}
                        validationProps={{ ...getValidationProps('vatNumber') }}
                        error={getValidationProps('vatNumber').validationText}
                      />
                    </Grid>
                    <Grid
                      spacing={2}
                      container
                      sx={{ marginTop: '30px', marginLeft: '0px' }}
                    >
                      <Grid item xs={6}>
                        <TextFieldInput
                          value={formData.companyOfficialName}
                          label="Company Official Name *"
                          helperText="ex: Superior Industries Leichtmetallräder Germany GmbH"
                          onChange={(e) =>
                            onValueChange('companyOfficialName', e.target.value)
                          }
                          validationProps={{
                            ...getValidationProps('companyOfficialName'),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFieldInput
                          value={formData.companyShortName}
                          label="Company Short Name *"
                          helperText="ex: Superior Industries"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              companyShortName: e.target.value,
                              slug: slugify(e.target.value.toLowerCase()),
                            });
                            clearErrors('companyShortName');
                            clearErrors('slug');
                          }}
                          validationProps={{
                            ...getValidationProps('companyShortName'),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFieldInput
                          label="Slug *"
                          helperText="ex: superior-industries"
                          value={formData.slug.toLowerCase()}
                          onChange={(e) => {
                            onValueChange(
                              'slug',
                              e.target.value.toLowerCase().replace(/ /g, '-')
                            );
                          }}
                          validationProps={{
                            ...getValidationProps('slug'),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      spacing={2}
                      container
                      sx={{ marginTop: '30px', marginLeft: '0px' }}
                    >
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Headquarter"
                          control={
                            <Checkbox
                              color="info"
                              checked={headquarter}
                              onChange={() => setHeadquarter(!headquarter)}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={4.5}>
                        <MultiSelectInput
                          value={formData.countryId}
                          label="Country *"
                          dropdownData={dropdownsData.countries}
                          onChange={(value) =>
                            onValueChange('countryId', value)
                          }
                          validationProps={{
                            ...getValidationProps('countryId'),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFieldInput
                          value={formData.zipCode}
                          label="Zip Code *"
                          onChange={(e) =>
                            onValueChange('zipCode', e.target.value)
                          }
                          validationProps={{
                            ...getValidationProps('zipCode'),
                            validationRules: {
                              required: true,
                              validate: () => {
                                return charsAndNumbersRegex.test(
                                  formData.zipCode
                                );
                              },
                            },
                            validationText: 'Format is not correct!',
                          }}
                        />
                      </Grid>
                      <Grid item xs={4.5}>
                        <TextFieldInput
                          value={formData.city}
                          label="City *"
                          onChange={(e) =>
                            onValueChange('city', e.target.value)
                          }
                          validationProps={{
                            ...getValidationProps('city'),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      spacing={2}
                      container
                      sx={{ marginTop: '30px', marginLeft: '0px' }}
                    >
                      <Grid item xs={12}>
                        <TextFieldInput
                          value={formData.address}
                          label="Address *"
                          onChange={(e) =>
                            onValueChange('address', e.target.value)
                          }
                          validationProps={{
                            ...getValidationProps('address'),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      spacing={2}
                      container
                      sx={{ marginTop: '30px', marginLeft: '0px' }}
                    >
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Remark"
                          multiline={true}
                          value={formData.remark}
                          onChange={(e) =>
                            onValueChange('remark', e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </TyrioTab>
                <TyrioTab
                  title="Contacts"
                  key="contacts"
                  errored={
                    Object.keys(errors).find((f) => f.includes('Contacts')) !==
                    undefined
                  }
                >
                  {formData.supplierContacts.length > 0 ? (
                    <AccordionWrapper>
                      <ExpandCollapseButtons
                        color={
                          expandedData.contacts.length > 0
                            ? 'warning'
                            : 'success'
                        }
                        onExpandedDataChange={() =>
                          onExpandedDataChange(
                            'contacts',
                            expandedData.contacts.length > 0
                              ? []
                              : formData.supplierContacts.map(
                                  (contact, index) => index
                                )
                          )
                        }
                        flag={expandedData.contacts.length > 0}
                      />
                      {formData.supplierContacts.map((item, index) => {
                        return (
                          <Accordion
                            expanded={expandedData.contacts.includes(index)}
                            square
                            defaultExpanded
                            sx={{
                              boxShadow: '0px 1px 0px #dfe3e8',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                paddingLeft: '0px',
                              }}
                              onClick={() => {
                                onExpandedDataChange(
                                  'contacts',
                                  expandedData.contacts.includes(index)
                                    ? expandedData.contacts.filter(
                                        (i) => i !== index
                                      )
                                    : [...expandedData.contacts, index]
                                );
                              }}
                            >
                              {item.firstName !== '' ? (
                                <ContactHeader>
                                  {item.firstName + ' ' + item.lastName}
                                </ContactHeader>
                              ) : (
                                <ContactHeader>
                                  Contact #{index + 1}
                                </ContactHeader>
                              )}
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                paddingLeft: '0px',
                              }}
                            >
                              <Grid spacing={2} container>
                                <Grid item xs={6}>
                                  <TextFieldInput
                                    value={item.firstName}
                                    label="First Name *"
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange<string>(
                                        'supplierContacts',
                                        'firstName',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierContacts-firstName-${index}`
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextFieldInput
                                    value={item.lastName}
                                    label="Last Name *"
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierContacts',
                                        'lastName',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierContacts-lastName-${index}`
                                      ),
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                spacing={2}
                                container
                                sx={{ marginTop: '30px' }}
                              >
                                <Grid item xs={4}>
                                  <TextFieldInput
                                    value={item.email}
                                    label="E-mail *"
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierContacts',
                                        'email',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierContacts-email-${index}`,
                                        undefined,
                                        'Format is not correct!'
                                      ),
                                      validationRules: {
                                        validate: () => {
                                          return emailRegex.test(item.email);
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <PhoneNumberInput
                                    value={item.businessPhone}
                                    label="Business Phone"
                                    onChange={(value) => {
                                      onRepeaterFieldValueChange(
                                        'supplierContacts',
                                        'businessPhone',
                                        value,
                                        index
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <PhoneNumberInput
                                    value={item.mobilePhone}
                                    label="Mobile Phone"
                                    onChange={(value) => {
                                      onRepeaterFieldValueChange(
                                        'supplierContacts',
                                        'mobilePhone',
                                        value,
                                        index
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                spacing={2}
                                container
                                sx={{ marginTop: '30px' }}
                              >
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Remark"
                                    multiline={true}
                                    value={item.remark}
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierContacts',
                                        'remark',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                display="flex"
                                sx={{ marginTop: '20px' }}
                                justifyContent="flex-end"
                              >
                                <DeleteRepeaterFieldButton
                                  onDeleteRepeaterField={() =>
                                    onDeleteRepeaterField(
                                      'supplierContacts',
                                      index
                                    )
                                  }
                                  index={index}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </AccordionWrapper>
                  ) : (
                    <AlertField
                      text="This supplier doesn't have any contact!"
                      type="warning"
                    />
                  )}
                  <Grid
                    container
                    display="flex"
                    sx={{ marginTop: '25px' }}
                    justifyContent="flex-end"
                  ></Grid>
                  <AddNewRepeaterFieldButton
                    onAddNewRepeaterField={() =>
                      onAddNewRepeaterField(
                        'supplierContacts',
                        'contacts',
                        formData.supplierContacts.length
                      )
                    }
                    buttonText="ADD NEW CONTACT"
                  />
                </TyrioTab>
                <TyrioTab
                  title="Locations"
                  key="locations"
                  errored={
                    Object.keys(errors).find((f) => f.includes('Locations')) !==
                    undefined
                  }
                >
                  <AccordionWrapper>
                    {
                      <ExpandCollapseButtons
                        color={
                          expandedData.locations.length > 0
                            ? 'warning'
                            : 'success'
                        }
                        onExpandedDataChange={() =>
                          onExpandedDataChange(
                            'locations',
                            expandedData.locations.length > 0
                              ? []
                              : formData.supplierLocations.map(
                                  (contact, index) => index
                                )
                          )
                        }
                        flag={expandedData.locations.length > 0}
                        disabled={formData.supplierLocations.length === 1}
                      />
                    }
                    {formData.supplierLocations.map((item, index) => {
                      const firstLocationHeadquarter =
                        headquarter && index === 0;
                      return (
                        <Accordion
                          expanded={
                            expandedData.locations.includes(index) ||
                            (index === 0 &&
                              formData.supplierLocations.length === 1)
                          }
                          square
                          defaultExpanded
                          sx={{
                            boxShadow: '0px 1px 0px #dfe3e8',
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              paddingLeft: '0px',
                            }}
                            onClick={() => {
                              formData.supplierLocations.length !== 1 &&
                                onExpandedDataChange(
                                  'locations',
                                  expandedData.locations.includes(index)
                                    ? expandedData.locations.filter(
                                        (i) => i !== index
                                      )
                                    : [...expandedData.locations, index]
                                );
                            }}
                          >
                            {item.locationName !== '' ? (
                              <ContactHeader>{item.locationName}</ContactHeader>
                            ) : (
                              <ContactHeader>
                                Location #{index + 1}
                              </ContactHeader>
                            )}
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              paddingLeft: '0px',
                            }}
                          >
                            {index === 0 && (
                              <LocationAlertWrapper>
                                <AlertField
                                  text="This is supplier first and basic location, and can not be deleted!"
                                  type="info"
                                />
                              </LocationAlertWrapper>
                            )}
                            <Grid spacing={2} container>
                              <Grid item xs={3}>
                                <TextFieldInput
                                  value={item.locationId}
                                  label="Location ID *"
                                  onChange={(e) => {
                                    onRepeaterFieldValueChange(
                                      'supplierLocations',
                                      'locationId',
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  validationProps={{
                                    ...getValidationProps(
                                      `supplierLocations-locationId-${index}`
                                    ),
                                  }}
                                />
                              </Grid>
                              {index === 0 && (
                                <Grid
                                  item
                                  xs={2.5}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        defaultChecked={index === 0}
                                        checked={index === 0}
                                        value={index === 0}
                                        disabled
                                      />
                                    }
                                    label="Headquarter"
                                  />
                                </Grid>
                              )}

                              <Grid
                                item
                                xs={2.6}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="info"
                                      checked={
                                        item.supplierLocationType ===
                                        DBSupplierLocationType.CENTRAL_WAREHOUSE
                                      }
                                      defaultChecked={index === 0}
                                      onChange={(e) => {
                                        onRepeaterFieldValueChange(
                                          'supplierLocations',
                                          'supplierLocationType',
                                          e.target.checked
                                            ? DBSupplierLocationType.CENTRAL_WAREHOUSE
                                            : DBSupplierLocationType.SECONDARY_WAREHOUSE,
                                          index
                                        );
                                      }}
                                    />
                                  }
                                  label="Central Warehouse"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="info"
                                      checked={
                                        item.supplierLocationType ===
                                        DBSupplierLocationType.SECONDARY_WAREHOUSE
                                      }
                                      defaultChecked={index === 0}
                                      onChange={(e) => {
                                        onRepeaterFieldValueChange(
                                          'supplierLocations',
                                          'supplierLocationType',
                                          e.target.checked
                                            ? DBSupplierLocationType.SECONDARY_WAREHOUSE
                                            : DBSupplierLocationType.CENTRAL_WAREHOUSE,
                                          index
                                        );
                                      }}
                                    />
                                  }
                                  label="Secondary Warehouse"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              spacing={2}
                              container
                              sx={{ marginTop: '30px' }}
                            >
                              <Grid item xs={6}>
                                <TextFieldInput
                                  value={item.locationName}
                                  label="Location Name *"
                                  helperText="ex: Superior Industries - Bad Dürkheim "
                                  onChange={(e) => {
                                    formData['supplierLocations'][index][
                                      'locationName'
                                    ] = e.target.value;

                                    formData['supplierLocations'][index][
                                      'slug'
                                    ] = slugify(e.target.value.toLowerCase());

                                    setFormData({
                                      ...formData,
                                      supplierLocations:
                                        formData['supplierLocations'],
                                    });

                                    clearErrors(
                                      `supplierLocations-locationName-${index}`
                                    );
                                  }}
                                  validationProps={{
                                    ...getValidationProps(
                                      `supplierLocations-locationName-${index}`
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextFieldInput
                                  label="Slug"
                                  helperText="ex: superior-industries-germany-bad-durkheim "
                                  value={item.slug.toLowerCase()}
                                  onChange={(e) => {
                                    onRepeaterFieldValueChange(
                                      'supplierLocations',
                                      'slug',
                                      e.target.value
                                        .toLowerCase()
                                        .replace(/ /g, '-'),
                                      index
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              spacing={2}
                              container
                              sx={{ marginTop: '30px' }}
                            >
                              <Grid item xs={4.5}>
                                <MultiSelectInput
                                  value={
                                    firstLocationHeadquarter
                                      ? formData.countryId
                                      : item.countryId
                                  }
                                  label="Country *"
                                  dropdownData={dropdownsData.countries}
                                  disabled={firstLocationHeadquarter}
                                  onChange={(value) => {
                                    onRepeaterFieldValueChange(
                                      'supplierLocations',
                                      'countryId',
                                      value,
                                      index
                                    );
                                  }}
                                  validationProps={{
                                    ...getValidationProps(
                                      `supplierLocations-countryId-${index}`
                                    ),
                                    validationRules: {
                                      validate: () => {
                                        return headquarter === true
                                          ? true
                                          : item.countryId.length !== 0;
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldInput
                                  value={
                                    firstLocationHeadquarter
                                      ? formData.zipCode
                                      : item.zipCode
                                  }
                                  label="Zip Code"
                                  disabled={firstLocationHeadquarter}
                                  onChange={(e) => {
                                    onRepeaterFieldValueChange(
                                      'supplierLocations',
                                      'zipCode',
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  validationProps={{
                                    ...getValidationProps(
                                      `supplierLocations-zipCode-${index}`
                                    ),
                                    validationRules: {
                                      // required: !headquarter,
                                      validate: () => {
                                        return headquarter === true
                                          ? true
                                          : charsAndNumbersRegex.test(
                                              item.zipCode
                                            );
                                      },
                                    },
                                    validationText: 'Format is not correct!',
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4.5}>
                                <TextFieldInput
                                  value={
                                    firstLocationHeadquarter
                                      ? formData.city
                                      : item.city
                                  }
                                  label="City *"
                                  disabled={firstLocationHeadquarter}
                                  onChange={(e) => {
                                    onRepeaterFieldValueChange(
                                      'supplierLocations',
                                      'city',
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  validationProps={{
                                    ...getValidationProps(
                                      `supplierLocations-city-${index}`
                                    ),
                                    validationRules: {
                                      required: !headquarter,
                                      validate: () => {
                                        return item.city.length > 0;
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              spacing={2}
                              container
                              sx={{ marginTop: '30px' }}
                            >
                              <Grid item xs={12}>
                                <TextFieldInput
                                  value={
                                    firstLocationHeadquarter
                                      ? formData.address
                                      : item.adress
                                  }
                                  label="Address *"
                                  disabled={firstLocationHeadquarter}
                                  onChange={(e) => {
                                    onRepeaterFieldValueChange(
                                      'supplierLocations',
                                      'adress',
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  validationProps={{
                                    ...getValidationProps(
                                      `supplierLocations-adress-${index}`
                                    ),
                                    validationRules: {
                                      required: !headquarter,
                                      validate: () => {
                                        return item.adress.length > 0;
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              display="flex"
                              sx={{ marginTop: '20px' }}
                              justifyContent="flex-end"
                            >
                              <DeleteRepeaterFieldButton
                                onDeleteRepeaterField={() =>
                                  onDeleteRepeaterField(
                                    'supplierLocations',
                                    index
                                  )
                                }
                                index={index}
                                disabled={index === 0}
                              />
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </AccordionWrapper>
                  <Grid
                    container
                    display="flex"
                    sx={{ marginTop: '25px' }}
                    justifyContent="flex-end"
                  ></Grid>
                  <AddNewRepeaterFieldButton
                    onAddNewRepeaterField={() =>
                      onAddNewRepeaterField(
                        'supplierLocations',
                        'locations',
                        formData.supplierLocations.length
                      )
                    }
                    buttonText="ADD NEW LOCATION"
                  />
                </TyrioTab>
                <TyrioTab
                  title="Connections"
                  key="connections"
                  errored={
                    Object.keys(errors).find((f) =>
                      f.includes('Connections')
                    ) !== undefined
                  }
                >
                  {formData.supplierConnections.length > 0 ? (
                    <AccordionWrapper>
                      <ExpandCollapseButtons
                        color={
                          expandedData.connections.length > 0
                            ? 'warning'
                            : 'success'
                        }
                        onExpandedDataChange={() =>
                          onExpandedDataChange(
                            'connections',
                            expandedData.connections.length > 0
                              ? []
                              : formData.supplierConnections.map(
                                  (contact, index) => index
                                )
                          )
                        }
                        flag={expandedData.connections.length > 0}
                      />
                      {formData.supplierConnections.map((connection, index) => {
                        const isPricelistOrStock =
                          connection.connectionTypes.includes('PRICELIST') ||
                          connection.connectionTypes.includes('STOCK');

                        return (
                          <Accordion
                            expanded={expandedData.connections.includes(index)}
                            square
                            defaultExpanded
                            sx={{
                              boxShadow: '0px 1px 0px #dfe3e8',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                paddingLeft: '0px',
                              }}
                              onClick={() => {
                                onExpandedDataChange(
                                  'connections',
                                  expandedData.connections.includes(index)
                                    ? expandedData.connections.filter(
                                        (i) => i !== index
                                      )
                                    : [...expandedData.connections, index]
                                );
                              }}
                            >
                              {connection.connectionName !== '' ? (
                                <ContactHeader>
                                  {connection.connectionName}
                                </ContactHeader>
                              ) : (
                                <ContactHeader>
                                  Connection #{index + 1}
                                </ContactHeader>
                              )}
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                paddingLeft: '0px',
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextFieldInput
                                    value={connection.connectionName}
                                    label="Connection name *"
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'connectionName',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierConnections-connectionName-${index}`
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{ display: 'flex', gap: '15px' }}
                                >
                                  <SelectList
                                    value={connection.fileFormat}
                                    label="File Format *"
                                    dropdownData={dropdownsData.fileFormats}
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'fileFormat',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierConnections-fileFormat-${index}`
                                      ),
                                    }}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id={`supplierConnections-isFileZipped-${index}`}
                                        defaultChecked={
                                          connection.isFileZipped ?? false
                                        }
                                        value={connection.isFileZipped}
                                        disabled={false}
                                        color="info"
                                        onChange={(e) =>
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'isFileZipped',
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      />
                                    }
                                    label={'Zipped'}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth>
                                    <InputLabelStyle
                                      isError={
                                        errors[
                                          `supplierConnections-connectionTypes-${index}`
                                        ] ?? false
                                      }
                                      color={
                                        errors[
                                          `supplierConnections-connectionTypes-${index}`
                                        ]
                                          ? 'error'
                                          : 'primary'
                                      }
                                    >
                                      Connection Type *
                                    </InputLabelStyle>
                                    <Select
                                      {...register(
                                        `supplierConnections-connectionTypes-${index}`,
                                        {
                                          required: true,
                                        }
                                      )}
                                      error={
                                        errors[
                                          `supplierConnections-connectionTypes-${index}`
                                        ]
                                      }
                                      SelectDisplayProps={{
                                        style: {
                                          padding:
                                            connection.connectionTypes.length >
                                            0
                                              ? '12px 14px'
                                              : '',
                                        },
                                      }}
                                      fullWidth
                                      label="Connection Type"
                                      multiple
                                      value={connection.connectionTypes}
                                      onChange={(e) => {
                                        onRepeaterFieldValueChange(
                                          'supplierConnections',
                                          'connectionTypes',
                                          e.target.value,
                                          index
                                        );
                                      }}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={{
                                            position: 'absolute',
                                            right: '18px',
                                          }}
                                          onClick={() => {
                                            onRepeaterFieldValueChange(
                                              'supplierConnections',
                                              'connectionTypes',
                                              [],
                                              index
                                            );
                                          }}
                                        >
                                          <IconButton>
                                            <CancelIcon
                                              sx={{
                                                color: 'rgba(0, 0, 0, 0.26)',
                                                position: 'absolute',
                                                right: '18px',
                                              }}
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      renderValue={(selected) => (
                                        <ChipsWrapper>
                                          {(selected as string[]).map(
                                            (value) => (
                                              <Chip
                                                key={value}
                                                label={value}
                                                clickable
                                                deleteIcon={
                                                  <CancelIcon
                                                    onMouseDown={(event) =>
                                                      event.stopPropagation()
                                                    }
                                                  />
                                                }
                                                onDelete={() => {
                                                  onRepeaterFieldValueChange(
                                                    'supplierConnections',
                                                    'connectionTypes',
                                                    connection.connectionTypes.filter(
                                                      (current) =>
                                                        current !== value
                                                    ),
                                                    index
                                                  );
                                                }}
                                                sx={{
                                                  backgroundColor: 'white',
                                                  border:
                                                    '1px solid rgba(0, 0, 0, 0.26);',
                                                }}
                                              />
                                            )
                                          )}
                                        </ChipsWrapper>
                                      )}
                                    >
                                      {dropdownsData.connectionTypes.map(
                                        (item) => (
                                          <MenuItem
                                            key={item.value}
                                            value={item.value}
                                          >
                                            <Checkbox
                                              color="info"
                                              checked={connection.connectionTypes.includes(
                                                DBConnectionType[
                                                  item.value as keyof typeof DBConnectionType
                                                ]
                                              )}
                                            />
                                            <ListItemText
                                              primary={item.label}
                                            />
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                    {errors[
                                      `supplierConnections-connectionTypes-${index}`
                                    ] && (
                                      <FormHelperText
                                        sx={{ color: '#d32f2f;' }}
                                      >
                                        This field is required!
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                  <SelectList
                                    value={connection.rulesetKey ?? ''}
                                    label="Ruleset name *"
                                    dropdownData={Object.keys(RULESET_KEYS).map(
                                      (key) => ({
                                        label: key,
                                        value: key,
                                      })
                                    )}
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'rulesetKey',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierConnections-rulesetKey-${index}`
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Switch
                                    color="info"
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'active',
                                        e.target.checked,
                                        index
                                      );
                                    }}
                                    checked={connection.active}
                                  />
                                  Active
                                </Grid>
                              </Grid>
                              <Grid
                                spacing={2}
                                container
                                sx={{ marginTop: '5px' }}
                              >
                                <Grid item xs={3}>
                                  <SelectList
                                    value={connection.connectionProtocol}
                                    label="Connection Protocol *"
                                    dropdownData={dropdownsData.connectionProtocols.filter(
                                      (item) => item.label !== 'B2B'
                                    )}
                                    disabled={
                                      supplierId !== undefined &&
                                      connection.id !== undefined
                                    }
                                    validationProps={{
                                      ...getValidationProps(
                                        `supplierConnections-connectionProtocol-${index}`
                                      ),
                                      validationRules: {
                                        required:
                                          supplierId === undefined &&
                                          connection.id === undefined,
                                      },
                                    }}
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'connectionProtocol',
                                        e.target.value,
                                        index
                                      );
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'apiConnection',
                                        undefined,
                                        index
                                      );
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'httpsConnection',
                                        undefined,
                                        index
                                      );
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'ftpConnection',
                                        undefined,
                                        index
                                      );
                                      if (e.target.value === 'FTP') {
                                        onRepeaterFieldValueChange(
                                          'supplierConnections',
                                          'ftpConnection',
                                          {
                                            ...getDefaultObject(
                                              'ftpConnection'
                                            ),
                                          },
                                          index
                                        );
                                      }
                                      if (e.target.value === 'API') {
                                        onRepeaterFieldValueChange(
                                          'supplierConnections',
                                          'apiConnection',
                                          {
                                            ...getDefaultObject(
                                              'apiConnection'
                                            ),
                                          },
                                          index
                                        );
                                      }
                                      if (e.target.value === 'HTTPS') {
                                        onRepeaterFieldValueChange(
                                          'supplierConnections',
                                          'httpsConnection',
                                          {
                                            ...getDefaultObject(
                                              'httpsConnection'
                                            ),
                                          },
                                          index
                                        );
                                      }

                                      if (e.target.value === 'EMAIL') {
                                        onRepeaterFieldValueChange(
                                          'supplierConnections',
                                          'emailConnection',
                                          {
                                            ...getDefaultObject(
                                              'emailConnection'
                                            ),
                                          },
                                          index
                                        );
                                      }
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={9}>
                                  <TextField
                                    fullWidth
                                    label="Remark"
                                    multiline={true}
                                    value={connection.remark}
                                    onChange={(e) => {
                                      onRepeaterFieldValueChange(
                                        'supplierConnections',
                                        'remark',
                                        e.target.value,
                                        index
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              {isPricelistOrStock && (
                                <Grid>
                                  <Grid
                                    container
                                    display="flex"
                                    sx={{ marginTop: '20px' }}
                                  >
                                    <Grid item xs={12}>
                                      <Alert severity="warning">
                                        <strong>
                                          {connection.connectionTypes.join(
                                            ' AND '
                                          )}
                                        </strong>{' '}
                                        connection type, must have defined
                                        minimum frequency update unit and time!
                                      </Alert>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={2}
                                    display="flex"
                                    sx={{ marginTop: '10px' }}
                                  >
                                    <Grid item xs={6}>
                                      <TextFieldInput
                                        value={
                                          connection.minimumUpdateFrequencyTime
                                        }
                                        type="number"
                                        label="Minimum update frequency time"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'minimumUpdateFrequencyTime',
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-minimumUpdateFrequencyTime-${index}`
                                          ),
                                          validationRules: {
                                            required: isPricelistOrStock,
                                            min: 0,
                                          },
                                          validationText:
                                            'Format is not correct!',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <SelectList
                                        value={
                                          connection.minimumUpdateFrequencyUnit
                                        }
                                        label="Minimum update frequency unit"
                                        dropdownData={dropdownsData.timeUnits}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'minimumUpdateFrequencyUnit',
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-minimumUpdateFrequencyUnit-${index}`
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}

                              <Grid
                                container
                                display="flex"
                                sx={{ marginTop: '20px' }}
                                justifyContent="flex-end"
                              >
                                <DeleteRepeaterFieldButton
                                  onDeleteRepeaterField={() =>
                                    onDeleteRepeaterField(
                                      'supplierConnections',
                                      index
                                    )
                                  }
                                  index={index}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </AccordionWrapper>
                  ) : (
                    <AlertField
                      text="This supplier doesn't have any connection!"
                      type="warning"
                    />
                  )}
                  <Grid
                    container
                    display="flex"
                    sx={{ marginTop: '25px' }}
                    justifyContent="flex-end"
                  ></Grid>
                  <AddNewRepeaterFieldButton
                    onAddNewRepeaterField={() =>
                      onAddNewRepeaterField(
                        'supplierConnections',
                        'connections',
                        formData.supplierConnections.length
                      )
                    }
                    buttonText="ADD NEW CONNECTION"
                  />
                </TyrioTab>
                <TyrioTab title="Catalogue Settings" key="catalogue-settings">
                  {formData.supplierConnections.length > 0 ? (
                    <AccordionWrapper>
                      <ExpandCollapseButtons
                        color={
                          expandedData.catalogueSettings.length > 0
                            ? 'warning'
                            : 'success'
                        }
                        onExpandedDataChange={() =>
                          onExpandedDataChange(
                            'catalogueSettings',
                            expandedData.catalogueSettings.length > 0
                              ? []
                              : formData.supplierConnections.map(
                                  (contact, index) => index
                                )
                          )
                        }
                        flag={expandedData.catalogueSettings.length > 0}
                      />
                      {formData.supplierConnections.map((connection, index) => {
                        const isCatalogConnection =
                          connection.connectionTypes.includes('CATALOGUE');

                        if (!isCatalogConnection) return null;

                        return (
                          <Accordion
                            expanded={expandedData.catalogueSettings.includes(
                              index
                            )}
                            square
                            defaultExpanded
                            sx={{
                              boxShadow: '0px 1px 0px #dfe3e8',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                paddingLeft: '0px',
                              }}
                              onClick={() => {
                                onExpandedDataChange(
                                  'catalogueSettings',
                                  expandedData.catalogueSettings.includes(index)
                                    ? expandedData.catalogueSettings.filter(
                                        (i) => i !== index
                                      )
                                    : [...expandedData.catalogueSettings, index]
                                );
                              }}
                            >
                              <ContactHeader>
                                <Grid
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      width: '97%',
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: '#212B36',
                                          fontSize: '18px',
                                          fontWeight: '500',
                                          lineHeight: '28px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          letterSpacing: '0.3px',
                                        }}
                                      >
                                        {connection.connectionName !== ''
                                          ? connection.connectionName
                                          : `Connection #${index + 1}`}
                                      </Typography>
                                      <Chip
                                        variant="filled"
                                        size="small"
                                        label={
                                          connection.connectionProtocol
                                            ? connection.connectionProtocol
                                            : 'PROTOCOL IS NOT DEFINED'
                                        }
                                        color={
                                          connection.connectionProtocol
                                            ? 'info'
                                            : 'warning'
                                        }
                                        sx={{ marginLeft: '7px' }}
                                      />
                                    </Grid>
                                    <StatusBadge
                                      color={
                                        connection.active
                                          ? '#56C489'
                                          : '#E64B4B'
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </ContactHeader>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                paddingLeft: '0px',
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={7}>
                                  <FormControl fullWidth>
                                    <InputLabel>Connection Type</InputLabel>
                                    <Select
                                      disabled
                                      SelectDisplayProps={{
                                        style: {
                                          padding:
                                            connection.connectionTypes.length >
                                            0
                                              ? '12px 14px'
                                              : '',
                                        },
                                      }}
                                      fullWidth
                                      label="Connection Type"
                                      multiple
                                      value={connection.connectionTypes}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={{
                                            position: 'absolute',
                                            right: '18px',
                                          }}
                                        >
                                          <IconButton>
                                            <CancelIcon
                                              sx={{
                                                color: 'rgba(0, 0, 0, 0.26)',
                                                position: 'absolute',
                                                right: '18px',
                                              }}
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      renderValue={(selected) => (
                                        <ChipsWrapper>
                                          {(selected as string[]).map(
                                            (value) => (
                                              <Chip
                                                key={value}
                                                label={value}
                                                clickable
                                                deleteIcon={
                                                  <CancelIcon
                                                    onMouseDown={(event) =>
                                                      event.stopPropagation()
                                                    }
                                                  />
                                                }
                                                sx={{
                                                  backgroundColor: 'white',
                                                  border:
                                                    '1px solid rgba(0, 0, 0, 0.26);',
                                                }}
                                              />
                                            )
                                          )}
                                        </ChipsWrapper>
                                      )}
                                    >
                                      {dropdownsData.connectionTypes.map(
                                        (item) => (
                                          <MenuItem
                                            key={item.value}
                                            value={item.value}
                                          >
                                            <Checkbox
                                              checked={connection.connectionTypes.includes(
                                                DBConnectionType[
                                                  item.value as keyof typeof DBConnectionType
                                                ]
                                              )}
                                            />
                                            <ListItemText
                                              primary={item.label}
                                            />
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <FormControl fullWidth>
                                    <InputLabel>Connection Protocol</InputLabel>
                                    <Select
                                      disabled
                                      label="Connection Protocol"
                                      value={connection.connectionProtocol}
                                    >
                                      {dropdownsData.connectionProtocols.map(
                                        (option) => (
                                          <MenuItem
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <SelectList
                                    disabled
                                    value={connection.fileFormat}
                                    label="File Format"
                                    dropdownData={dropdownsData.fileFormats}
                                  />
                                </Grid>
                              </Grid>
                              {connection.ftpConnection === undefined &&
                                connection.apiConnection === undefined &&
                                connection.httpsConnection === undefined &&
                                connection.emailConnection === undefined && (
                                  <NoConnectionTypeAlertWrapper>
                                    <AlertField
                                      text="Connection protocol is not defined!"
                                      type="warning"
                                    />
                                  </NoConnectionTypeAlertWrapper>
                                )}
                              {connection.emailConnection && (
                                <ConnectionSettingsWrapper>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={12}>
                                      <TextFieldInput
                                        label="Supplier email"
                                        type="email"
                                        value={
                                          connection.emailConnection
                                            .supplierEmail
                                        }
                                        disabled={isCatalogConnection}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'emailConnection',
                                            e.target.value,
                                            index,
                                            'supplierEmail'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </ConnectionSettingsWrapper>
                              )}
                              {connection.ftpConnection && (
                                <ConnectionSettingsWrapper>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={12}>
                                      <TextFieldInput
                                        label="File Location"
                                        helperText="eg. /files/CONTI_PRICAT.csv"
                                        disabled={!isCatalogConnection}
                                        value={
                                          connection.ftpConnection.fileLocation
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'fileLocation'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-ftpConnection-fileLocation-${index}`
                                          ),
                                          validationRules: isCatalogConnection
                                            ? {
                                                minLength:
                                                  connection.fileFormat.length +
                                                  2,
                                              }
                                            : {},
                                          validationText: `Must have atleast ${
                                            connection.fileFormat.length + 2
                                          } characters.`,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={6}>
                                      <TextFieldInput
                                        value={connection.ftpConnection.ftpHost}
                                        label="FTP host"
                                        helperText="eg. ftp.goodyear.eu"
                                        disabled={!isCatalogConnection}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'ftpHost'
                                          );
                                        }}
                                        validationProps={
                                          !isCatalogConnection
                                            ? undefined
                                            : {
                                                ...getValidationProps(
                                                  `supplierConnections-ftpConnection-ftpHost-${index}`
                                                ),
                                              }
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        label="FTP port"
                                        type="number"
                                        disabled={!isCatalogConnection}
                                        helperText="you can leave empty if your port is 21 or you don’t know it"
                                        value={connection.ftpConnection.ftpPort}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'ftpPort'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={4}>
                                      <TextFieldInput
                                        value={
                                          connection.ftpConnection.ftpUserName
                                        }
                                        label="FTP username"
                                        disabled={!isCatalogConnection}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'ftpUserName'
                                          );
                                        }}
                                        validationProps={
                                          !isCatalogConnection
                                            ? undefined
                                            : {
                                                ...getValidationProps(
                                                  `supplierConnections-ftpConnection-ftpUserName-${index}`
                                                ),
                                              }
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <PasswordField
                                        label="FTP password"
                                        disabled={!isCatalogConnection}
                                        value={
                                          connection.ftpConnection.ftpPassword
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'ftpPassword'
                                          );
                                        }}
                                        validationProps={
                                          !isCatalogConnection
                                            ? undefined
                                            : {
                                                ...getValidationProps(
                                                  `supplierConnections-ftpConnection-ftpPassword-${index}`
                                                ),
                                              }
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={5}>
                                      <DateTimePicker
                                        label="Start update At"
                                        disabled={!isCatalogConnection}
                                        value={
                                          connection.ftpConnection
                                            .startUpdateTime
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e,
                                            index,
                                            'startUpdateTime'
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            helperText="Choose date and time of first update"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextFieldInput
                                        type="number"
                                        label="Repeat Every"
                                        disabled={!isCatalogConnection}
                                        helperText="How often to repeat"
                                        value={
                                          connection.ftpConnection.repeatEvery
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            parseInt(e.target.value),
                                            index,
                                            'repeatEvery'
                                          );
                                        }}
                                        validationProps={
                                          !isCatalogConnection
                                            ? undefined
                                            : {
                                                ...getValidationProps(
                                                  `supplierConnections-ftpConnection-repeatEvery-${index}`
                                                ),
                                                validationRules: {
                                                  required: true,
                                                  min: 1,
                                                },
                                                validationText:
                                                  ERROR_MESSAGES.MIN_LENGTH,
                                              }
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <SelectList
                                        value={
                                          connection.ftpConnection.repeatUntil
                                        }
                                        label="Time Unit"
                                        disabled={!isCatalogConnection}
                                        dropdownData={dropdownsData.timeUnits}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'repeatUntil'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <FileControlButtons
                                    supplierId={formData.id}
                                    supplierName={formData.companyOfficialName}
                                    connection={connection}
                                  />
                                  <Grid
                                    container
                                    sx={{
                                      marginTop: '30px',
                                    }}
                                  >
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Remark"
                                        multiline={true}
                                        value={connection.ftpConnection.remark}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'ftpConnection',
                                            e.target.value,
                                            index,
                                            'remark'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </ConnectionSettingsWrapper>
                              )}
                              {connection.apiConnection && (
                                <ConnectionSettingsWrapper>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={12}>
                                      <SelectList
                                        disabled={!!supplierId}
                                        value={
                                          connection.apiConnection
                                            .connectionType
                                        }
                                        label="API type"
                                        dropdownData={
                                          dropdownsData.apiConnectionTypes
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'connectionType'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={6}>
                                      <TextFieldInput
                                        value={connection.apiConnection.apiUrl}
                                        label="API Url"
                                        helperText="ex:  https://api.example.com"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'apiUrl'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-apiConnection-apiUrl-${index}`
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextFieldInput
                                        value={connection.apiConnection.apiKey}
                                        label="API Key"
                                        helperText="ex: 378f48c7a2aaa1268da17b32e606b086"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'apiKey'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-apiConnection-apiKey-${index}`
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={6}>
                                      <TextFieldInput
                                        value={
                                          connection.apiConnection.apiClientId
                                        }
                                        label="API Client ID"
                                        helperText="ex: 4a461028ce7f85399fdd1a4a9d6c7159"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'apiClientId'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-apiConnection-apiClientId-${index}`
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <PasswordField
                                        label="API Client Secret"
                                        helperText="ex: d76fb4f0718750b9d702beb53a4163ab"
                                        value={
                                          connection.apiConnection
                                            .apiClientSecret
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'apiClientSecret'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-apiConnection-apiClientSecret-${index}`
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={5}>
                                      <DateTimePicker
                                        label="Start update At"
                                        value={
                                          connection.apiConnection
                                            .startUpdateTime
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e,
                                            index,
                                            'startUpdateTime'
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            helperText="Choose date and time of first update"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextFieldInput
                                        type="number"
                                        label="Repeat Every"
                                        helperText="How often to repeat"
                                        value={
                                          connection.apiConnection.repeatEvery
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            parseInt(e.target.value),
                                            index,
                                            'repeatEvery'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-apiConnection-repeatEvery-${index}`
                                          ),
                                          validationRules: {
                                            required: true,
                                            min: 1,
                                          },
                                          validationText:
                                            ERROR_MESSAGES.MIN_LENGTH,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <SelectList
                                        value={
                                          connection.apiConnection.repeatUntil
                                        }
                                        label="Time Unit"
                                        dropdownData={dropdownsData.timeUnits}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'repeatUntil'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <FileControlButtons
                                    supplierId={formData.id}
                                    supplierName={formData.companyOfficialName}
                                    connection={connection}
                                  />
                                  <Grid container sx={{ marginTop: '30px' }}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Remark"
                                        multiline={true}
                                        value={connection.apiConnection.remark}
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'apiConnection',
                                            e.target.value,
                                            index,
                                            'remark'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </ConnectionSettingsWrapper>
                              )}
                              {connection.httpsConnection && (
                                <ConnectionSettingsWrapper>
                                  <Grid container sx={{ marginTop: '30px' }}>
                                    <Grid item xs={12}>
                                      <TextFieldInput
                                        value={
                                          connection.httpsConnection.httpsUrl
                                        }
                                        label="HTTPS url"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e.target.value,
                                            index,
                                            'httpsUrl'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-httpsConnection-httpsUrl-${index}`
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={4}>
                                      <TextFieldInput
                                        value={
                                          connection.httpsConnection.username
                                        }
                                        label="Username"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e.target.value,
                                            index,
                                            'username'
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <PasswordField
                                        label="Password"
                                        value={
                                          connection.httpsConnection.password
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e.target.value,
                                            index,
                                            'password'
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextFieldInput
                                        value={
                                          connection.httpsConnection.customerId
                                        }
                                        label="Customer ID"
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e.target.value,
                                            index,
                                            'customerId'
                                          );
                                        }}
                                        helperText="Refers to EDI Wheel integration customer ID"
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    container
                                    sx={{ marginTop: '30px' }}
                                  >
                                    <Grid item xs={5}>
                                      <DateTimePicker
                                        label="Start update At"
                                        value={
                                          connection.httpsConnection
                                            .startUpdateTime
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e,
                                            index,
                                            'startUpdateTime'
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            helperText="Choose date and time of first update"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextFieldInput
                                        type="number"
                                        label="Repeat Every"
                                        helperText="How often to repeat"
                                        value={
                                          connection.httpsConnection.repeatEvery
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            parseInt(e.target.value),
                                            index,
                                            'repeatEvery'
                                          );
                                        }}
                                        validationProps={{
                                          ...getValidationProps(
                                            `supplierConnections-httpsConnection-repeatEvery-${index}`
                                          ),
                                          validationRules: {
                                            required: true,
                                            min: 1,
                                          },
                                          validationText:
                                            ERROR_MESSAGES.MIN_LENGTH,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <SelectList
                                        label="Time Unit"
                                        dropdownData={dropdownsData.timeUnits}
                                        value={
                                          connection.httpsConnection.repeatUntil
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e.target.value,
                                            index,
                                            'repeatUntil'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <FileControlButtons
                                    supplierId={formData.id}
                                    supplierName={formData.companyOfficialName}
                                    connection={connection}
                                  />
                                  <Grid container sx={{ marginTop: '30px' }}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Remark"
                                        multiline={true}
                                        value={
                                          connection.httpsConnection.remark
                                        }
                                        onChange={(e) => {
                                          onRepeaterFieldValueChange(
                                            'supplierConnections',
                                            'httpsConnection',
                                            e.target.value,
                                            index,
                                            'remark'
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </ConnectionSettingsWrapper>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </AccordionWrapper>
                  ) : (
                    <AlertField
                      text="This supplier doesn't have any connection!"
                      type="warning"
                    />
                  )}
                </TyrioTab>
              </TyrioTabs>
            </form>
            <SourceContainer
              loading={false}
              onSubmit={() => {
                onCreateSupplier();
              }}
              disabledDelete={supplierId === undefined}
              disableSave={uploadCompanyLogoMutation.isLoading}
              data={{
                data_source: 'Manual by User',
                created: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
                last_edited: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
              }}
              onDelete={handleDeleteSupplier}
              onCancel={handleCancelSupplier}
            />
          </TabsWrapper>
        </FormControllerWrapper>
      )}
    </PageTemplateContent>
  );
};

const UploadLogoContainer = styled.div`
  width: 388px;
  height: 116px;
  border: 1px dashed #e6e8f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
`;

const SupplierLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const LoadingText = styled.span`
  margin-top: 10px;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorText = styled.span`
  margin-bottom: 5px;
  margin-top: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
`;

const SupplierLogo = styled.img`
  margin-right: 26px;
  max-width: 230px;
  min-width: 130px;
  height: 50px;
`;

export const ContactHeader = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '28px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.3px',
  color: '#212B36',
  width: '100%',
}));

const LocationAlertWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  gap: 7px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NoConnectionTypeAlertWrapper = styled.div`
  margin-top: 20px;
`;

const ConnectionSettingsWrapper = styled.div``;

export const AccordionWrapper = styled.div``;

export const StatusBadge = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 7px;
  background-color: ${(props) => props.color};
`;

export default SupplierForm;
