import React from 'react';
import routeNames from '../../lib/routeNames';
import { useHistory } from 'react-router-dom';

const NoPermissionsPage = () => {
  const history = useHistory();

  return (
    <div>
      <h1>Error</h1>
      <h1>No Permissions Page</h1>
      <button onClick={() => history.push(routeNames.dashboard())}>
        Back to dashboard
      </button>
    </div>
  );
};

export default NoPermissionsPage;
