import styled from '@emotion/styled';
import { SelectedLocation, WarehouseBranchResponse } from '@tyrio/dto';
import { useState } from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import WarehouseProvider, {
  ActiveStep,
  UpdateWarehouseReq,
} from '../../context/WarehouseContext';
import Overview from '../../features/warehouse/Overview';
import { Menu } from '../../features/warehouse/components/menu/Menu';
import { Steps } from '../../features/warehouse/steps/Steps';

const Warehouse = () => {
  const [activeStep, setActiveStep] = useState<ActiveStep>('UPLOAD');
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
  const [warehouse, setWarehouse] = useState<WarehouseBranchResponse | null>(
    null
  );
  const [req, setReq] = useState<UpdateWarehouseReq>({
    create: {
      zones: [],
      subzones: [],
      floors: [],
      racks: [],
      sections: [],
      shelfs: [],
      bins: [],
    },
    update: {
      zones: [],
      subzones: [],
      floors: [],
      racks: [],
      sections: [],
      shelfs: [],
      bins: [],
    },
  });

  const [selected, setSelected] = useState<SelectedLocation>({
    current: ['zone'],
    locations: {
      zone: null,
      subzone: null,
      floor: null,
      rack: null,
      section: null,
      shelf: null,
      bin: null,
    },
  });

  const width = '650px';

  const clearSelected = () => {
    setSelected({
      current: ['zone'],
      locations: {
        zone: null,
        subzone: null,
        floor: null,
        rack: null,
        section: null,
        shelf: null,
        bin: null,
      },
    });
  };

  return (
    <PageTemplateWrapper>
      <WarehouseProvider
        value={{
          req,
          setReq,
          clearSelected,
          activeStep,
          setActiveStep,
          warehouse,
          setWarehouse,
          selected,
          setSelected,
          isNewModalOpen,
          setIsNewModalOpen,
        }}
      >
        <PageTemplateContent
          style={{
            width: 'calc(100% - 650px)',
            transition: 'all 0.3s ease',
          }}
        >
          <Overview />
        </PageTemplateContent>

        <PageTemplateContent
          style={{
            background: '#f5f6f8',
            minWidth: width,
            maxWidth: width,
            marginRight: '0px',
            height: 'calc(100svh - 44px)',
            display: 'flex',
            zIndex: 99,
            transition: 'all 0.3s ease',
          }}
        >
          <DrawerWrapper>{activeStep && Steps(activeStep)}</DrawerWrapper>
          <Menu activeStep={activeStep} setActiveStep={setActiveStep} />
        </PageTemplateContent>
      </WarehouseProvider>
    </PageTemplateWrapper>
  );
};

const DrawerWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  position: relative;
`;

export default Warehouse;
