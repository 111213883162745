import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import { usePosCartCtx } from '../../../../../../context/PosCartContext';
import { AddressTextField } from '../../../../../customer-orders/components/AddressCard/AddressTextField';
import { checkIfValidPhoneNumber } from '../../../../../supplier-form/helpers/regex';

import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { InputOption } from '../../../../../../../../helpers/enum-parser';
import { vehicleInputFields } from '../../../../constants/vehicle-constants';
import {
  CustomerFormWrapper,
  RowWrapper,
} from '../../../../styles/CustomerForm.style';
import { useGetBrands } from '../Appointment/queries/get-brands';
import { useGetModels } from '../Appointment/queries/get-models';

interface VehicleFormProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

export const VehicleForm = ({ setIsDirty }: VehicleFormProps) => {
  // CONTEXT DATA
  const { vehicleDetails, setVehicleDetails } = usePosCartCtx();

  //   FUNCTIONS
  const handleChange = (id: string, value: string) => {
    if (id === 'mobile_phone') {
      const isValid = handlePhoneNumberValidation('+' + value);
      if (!isValid) return;
    }
    setVehicleDetails({
      ...vehicleDetails,
      [id]: value,
    });
    setIsDirty(true);
  };

  const handlePhoneNumberValidation = (e: string) => {
    if (!checkIfValidPhoneNumber(e) || e.length > 13) {
      return false;
    } else {
      return true;
    }
  };

  const { brands } = useGetBrands();
  const { models } = useGetModels({ brand: vehicleDetails['brand'] ?? '' });

  return (
    <CustomerFormWrapper>
      {vehicleInputFields.map((fields) => {
        return (
          <RowWrapper>
            {fields.map((field) => {
              if (field.id === 'brand' || field.id === 'model')
                return (
                  <Box sx={{ width: field.customWidth }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        options={field.id === 'model' ? models : brands}
                        value={{
                          value: vehicleDetails[field.id],
                          label: _.startCase(
                            _.toLower(vehicleDetails[field.id])
                          ),
                        }}
                        onChange={(e, x) => {
                          const val =
                            x === null
                              ? ''
                              : ((x as InputOption).value as string);

                          setVehicleDetails({
                            ...vehicleDetails,
                            [field.id]: val,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Vehicle ${field.id}`}
                            onChange={(e) => {
                              setVehicleDetails({
                                ...vehicleDetails,
                                [field.id]: e.target.value as string,
                              });
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                );
              else
                return (
                  <Box
                    sx={{
                      width: field.customWidth,
                      display: 'flex',
                    }}
                  >
                    <AddressTextField
                      id={field.id}
                      placeholder={field.placeholder}
                      value={vehicleDetails[field.id] ?? ''}
                      handleChange={({ target: { id, value } }) =>
                        handleChange(id, value)
                      }
                      disabled={false}
                      customPadding={'16px 8px'}
                      topLabelBorder={5}
                      fontSize={'16px'}
                      fontWeight={400}
                      helperText={field?.helperText}
                    />
                  </Box>
                );
            })}
          </RowWrapper>
        );
      })}
    </CustomerFormWrapper>
  );
};
