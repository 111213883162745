import styled from '@emotion/styled/macro';
import { Chip } from '@mui/material';
import { arrow, deleteDocument, hoveredEye } from '@tyrio/ui-library';
import { Dispatch, SetStateAction } from 'react';

export interface DocumentCardData {
  url: string;
  idx: string;
  reference: string;
  isHovered: Record<string, boolean>;
  setIsHovered: Dispatch<SetStateAction<Record<string, boolean>>>;
  hasMarginTop?: boolean;
  fileName: string;
  chipLabel?: string;
  documentIcon: string;
  showArrowIcon?: boolean;
  onDelete?: () => void;
  itemType?: string;
  showDelete?: boolean;
  docId?: string;
  shouldDownload?: boolean;
}

export const DocumentCard = ({
  url,
  idx,
  reference,
  isHovered,
  setIsHovered,
  hasMarginTop = false,
  fileName,
  chipLabel = 'POSLANO',
  documentIcon,
  showArrowIcon = false,
  onDelete,
  itemType,
  showDelete = false,
  docId,
  shouldDownload = true,
}: DocumentCardData) => {
  return (
    <DocumentDetailsWrapper
      key={idx}
      onClickCapture={() => window.open(url, '_blank')}
      onMouseEnter={() =>
        setIsHovered({
          ...isHovered,
          [reference]: true,
        })
      }
      onMouseLeave={() =>
        setIsHovered({
          ...isHovered,
          [reference]: false,
        })
      }
      style={{ marginTop: hasMarginTop ? '10px' : '' }}
    >
      <FileIconNameWrapper>
        <Image src={documentIcon} alt="pdf-icon" />
        <p>
          {fileName}
          {docId && (
            <span
              style={{ color: '#919EAB', fontSize: '14px' }}
            >{`(${docId})`}</span>
          )}
        </p>
      </FileIconNameWrapper>
      <ReferenceWrapper>
        <p>{reference}</p>
      </ReferenceWrapper>
      <IconsWrapper isHovered={isHovered[reference]}>
        {showArrowIcon && (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Chip
              size="small"
              label={chipLabel}
              sx={{
                bgcolor: '#3EB274',
                color: 'white',
                cursor: 'pointer',
              }}
            />

            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '10px',
                height: '20px',
              }}
              download={shouldDownload}
            >
              <Image src={arrow} alt="hovered-eye-icon" />
            </a>
          </div>
        )}
        {showDelete && (
          <div style={{ display: 'flex', gap: '5px' }}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex', justifyContent: 'center' }}
              download
            >
              <Image src={hoveredEye} alt="hovered-eye-icon" />
            </a>
            {itemType === 'application/pdf' && (
              <DeleteDocumentIcon
                src={deleteDocument}
                alt="delete-document-icon"
                onClick={() => {
                  if (onDelete) onDelete();
                }}
              />
            )}
          </div>
        )}
      </IconsWrapper>
    </DocumentDetailsWrapper>
  );
};

export const DocumentsWrapper = styled.div<{ disabled: boolean }>`
  // max-height: calc(100% - 44px - 30%);
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  gap: 16px;
`;

export const DocumentDetailsWrapper = styled.div`
  border: 1px solid #d2d4d2;
  border-radius: 8px;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  &:hover {
    border: 1px solid #0990d9;
    cursor: pointer;
  }
`;

export const FileIconNameWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 30%;
`;

export const ReferenceWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 40%;
`;

export const IconsWrapper = styled.div<{ isHovered?: boolean }>`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${(props) => (props.isHovered ? '15px' : '15px')};
  cursor: ${(props) => (props.isHovered ? 'pointer' : 'default')};
`;

const Image = styled.img``;

const DeleteDocumentIcon = styled.img`
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
`;
