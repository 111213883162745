import styled from '@emotion/styled/macro';
import {
  ArrowBackIos,
  Cancel,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CancelModal, Title, tyrioUI } from '@tyrio/ui-library';
import { useCallback, useEffect, useState } from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import StickyTable from '../../../components/StickyTable/StickyTable';
import { TableCellInterface } from '../../../components/StickyTable/temp';
import { ProductReviewWithStats } from '../../../features/price-comparison/Components/ProductReviewWithStats';

import {
  DBPriceCompareApi,
  DBProductApi,
  ProductDetailsDataInterface,
  SinglePriceComparisonShape,
} from '@tyrio/dto';

import { DBSupplierType } from '@prisma/client';
import DimensionStickyTable from '../../../components/StickyTable/DimensionStickyTable';
import { OrderDataInterface } from '../../price-compare/types';

interface ColumnInterface {
  Header: string;
  accessor: string;
  sticky?: string;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
}

enum SwitchType {
  LagerZero,
  Difference,
  EcoTax,
  Shipping,
  Bonus,
  Code,
}

interface DashboardPriceComparisonTableProps {
  onBack: (data: boolean) => void;
  receivedData: DBPriceCompareApi['compareCode']['response'];
  nonReceivedData: string;
  dataWithQty?: { value: string; qty: number }[];
  onProcessOrderClicked: (data: OrderDataInterface) => void;
  compareByDimensions: boolean;
}

export const DashboardPriceComparisonTable = (
  props: DashboardPriceComparisonTableProps
) => {
  const [receivedData] = useState(props.receivedData);
  const [nonReceivedData, setNonReceivedData] = useState<string>(
    props.nonReceivedData
  );

  const [data] = useState(receivedData);
  const [columns, setColumns] = useState<ColumnInterface[]>([]);
  const [shouldShowCode, setShowCode] = useState(false);
  const [shouldAddBonus, setShouldAddBonus] = useState(false);
  const [shouldAddEcoTax, setShouldAddEcoTax] = useState(false);
  const [shouldAddShipping, setShouldAddShipping] = useState(false);
  const [shouldShowDifference, setShouldShowDifference] = useState(false);
  const [shouldShowLagerZero, setShouldShowLagerZero] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [shouldHideSupplierName, setShouldHideSupplierName] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<DBProductApi['getOne']['response']>();
  const [selectedProductAvailableNumber, setSelectedProductAvailableNumber] =
    useState(0);
  const [selectedProductReservedNumber, setSelectedProductReservedNumber] =
    useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedProductBackorderNumber, setSelectedProductBackorderNumber] =
    useState(0);
  const [values, setValues] =
    useState<Record<string, Record<string, number>>>();

  const [allRows, setRows] = useState<TableCellInterface[]>([]);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const generateColumns = useCallback(() => {
    const newArray: ColumnInterface[] = [];
    if (shouldShowCode) {
      const newObj: ColumnInterface = {
        Header: 'Code',
        accessor: 'code',
        sticky: 'left',
      };
      newArray.push(newObj);
    }

    const n = Object.keys(data.suppliers).length;

    const articleName: ColumnInterface = {
      Header: 'Article name',
      accessor: 'articleName',
      sticky: 'left',
      width: Math.max(
        330,
        window.innerWidth * 0.8 - 510 - n * 150 - Number(shouldShowCode) * 150
      ),
      maxWidth: 2000,
    };
    newArray.push(articleName);

    if (
      data['mainSupplier'] &&
      Object.keys(data['mainSupplier']).length !== 0
    ) {
      const id = Object.keys(data['mainSupplier'])[0];

      const newObj: ColumnInterface = {
        Header: data['mainSupplier'][`${id}`].name,
        accessor: 'mainSupplier',
        sticky: 'left',
      };
      newArray.push(newObj);
    }

    Object.keys(data.suppliers)
      .sort((a, b) => {
        const supplierA =
          receivedData.suppliers[a]?.supplierType ===
          DBSupplierType.MANUFACTURER
            ? 1
            : -1;
        const supplierB =
          receivedData.suppliers[b]?.supplierType ===
          DBSupplierType.MANUFACTURER
            ? 1
            : -1;

        return supplierB - supplierA;
      })
      .forEach((value, index) => {
        const newObj: ColumnInterface = {
          Header: shouldHideSupplierName
            ? `Supplier ${index + 1}`
            : data.suppliers[value].name,
          accessor: value,
          width: 130,
        };
        newArray.push(newObj);
      });
    const total: ColumnInterface = {
      Header: 'Total',
      accessor: 'total',
      sticky: 'right',
    };
    newArray.push(total);
    setColumns(newArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, shouldShowCode, shouldHideSupplierName]);

  const helperCalculateCell = useCallback(
    (value: SinglePriceComparisonShape | undefined) => {
      if (value) {
        const quantity = value.quantity;
        if ((quantity === 0 && shouldShowLagerZero) || quantity !== 0) {
          let val = value.value || 0;

          if (shouldAddEcoTax && val) val += value.ecoTax;
          if (shouldAddBonus && val) val -= value.bonus;
          if (shouldAddShipping && val) val += value.shipping;

          return {
            value: val,
            quantity: quantity,
            bonus: value.bonus,
            shipping: value.shipping,
            ecoTax: value.ecoTax,
          };
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    [shouldAddBonus, shouldAddEcoTax, shouldAddShipping, shouldShowLagerZero]
  );

  // No matter what type of code has been chosen,
  // data with qty is always {value: ean, qty: number}.
  // I have added that part hereto keep
  // Sticky table logic simpler.
  const prepareDataWithQty = () => {
    const newArray: { value: string; qty: number }[] = [];
    if (props.dataWithQty) {
      props.dataWithQty.forEach((elem) => {
        const obj = Object.keys(receivedData.products).find(
          (productId) =>
            receivedData.products[productId].manufacturerCode === elem.value ||
            receivedData.products[productId].ean === elem.value ||
            receivedData.products[productId].sku === elem.value
        );
        if (obj)
          newArray.push({
            value: receivedData.products[obj].ean,
            qty: elem.qty,
          });
      });
    }

    return newArray;
  };

  prepareDataWithQty();
  const generateRows = useCallback(() => {
    const newArray: TableCellInterface[] = [];

    Object.keys(data.prices).forEach((price) => {
      const newObj: TableCellInterface = {};
      columns.forEach((value) => {
        if (value.accessor === 'articleName') {
          newObj[value.accessor] = {
            name: data.products[price].productName,
            className: data.products[price].model?.brand || '',
            ean: data.products[price].ean,
            manufacturerCode: data.products[price].manufacturerCode,
            id: data.products[price].uid,
            size: data.products[price].size || 'no size',
          };
        } else if (value.accessor === 'total') {
          newObj[value.accessor] = {
            value: 0,
            quantity: 0,
            bonus: 0,
            ecoTax: 0,
            shipping: 0,
          };
        } else if (value.accessor === 'code') {
          newObj[value.accessor] = {
            name: data.products[price].productName,
            className: data.products[price].model?.brand || '',
            ean: data.products[price].ean,
            manufacturerCode: data.products[price].manufacturerCode,
            id: data.products[price].uid,
            size: data.products[price].size || 'no size',
          };
        } else if (value.accessor === 'mainSupplier') {
          if (data['mainSupplier']) {
            const obj = Object.keys(data['mainSupplier']).pop();
            if (obj && newObj[value.accessor] !== null) {
              newObj[value.accessor] = helperCalculateCell(
                data.prices[price][obj]
              );
            }
          }
        } else {
          newObj[value.accessor] = helperCalculateCell(
            data.prices[price][value.accessor]
          );
        }
      });
      newArray.push(newObj);
    });

    setRows(newArray);
  }, [columns, data, helperCalculateCell]);

  useEffect(() => {
    if (data !== undefined) {
      generateColumns();
    }
  }, [generateColumns, data]);

  useEffect(() => {
    if (columns.length) {
      generateRows();
    }
  }, [columns, generateRows]);

  useEffect(() => {
    if (data !== undefined) {
      generateColumns();
    }
  }, [
    shouldShowLagerZero,
    shouldShowDifference,
    shouldShowCode,
    shouldAddShipping,
    shouldAddEcoTax,
    shouldAddBonus,
    generateColumns,
    shouldHideSupplierName,
    data,
  ]);

  const handleChangeOfSelectedRow = (productId: string) => {
    setSelectedProduct(data.products[productId]);
    let numOfAvailable = 0;
    let numOfReserved = 0;
    Object.keys(data.prices[productId]).forEach((supId) => {
      if (data.prices[productId][supId]) {
        numOfAvailable += data.prices[productId][supId].quantity;
        if (values && values[productId] && values[productId][supId]) {
          numOfReserved += Math.min(
            values[productId][supId],
            data.prices[productId][supId].quantity
          );
        }
      }
    });
    setSelectedProductAvailableNumber(numOfAvailable);
    setSelectedProductReservedNumber(numOfReserved);
  };

  const handleSwitchChange = (switchType: SwitchType, checked: boolean) => {
    switch (switchType) {
      case SwitchType.Bonus:
        setShouldAddBonus(checked);
        break;
      case SwitchType.Shipping:
        setShouldAddShipping(checked);
        break;
      case SwitchType.Difference:
        setShouldShowDifference(checked);
        break;
      case SwitchType.Code:
        setShowCode(checked);
        break;
      case SwitchType.LagerZero:
        setShouldShowLagerZero(checked);
        generateRows();
        break;
      case SwitchType.EcoTax:
        setShouldAddEcoTax(checked);
        break;
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const rows = allRows.filter((r) => {
    const data = r['articleName'] as unknown as Record<string, string>;
    const searchQuery = [
      data['className'],
      data['name'],
      data['ean'],
      data['manufacturerCode'],
    ]
      .join(' ')
      .toUpperCase();

    if (!searchValue) return true;
    return searchQuery.indexOf(searchValue?.toUpperCase()) > -1;
  });

  const handleConfirmOrder = () => {
    props.onProcessOrderClicked(prepareDataForOrder());
  };

  //TODO: Unused component check if we need this ?
  // const handleOrder = () => {
  //   if (values) {
  //     Object.keys(values).forEach((key) => {
  //       Object.keys(values[key]).forEach((supplierKey) => {
  //         const orderedItems = values[key][supplierKey];
  //         const object = Object.keys(data.prices).find(
  //           (elKey) => data.prices[elKey][supplierKey]
  //         );
  //         let availableItems = 0;

  //         if (object) availableItems = data.prices[key][supplierKey].quantity;
  //         if (availableItems < orderedItems) {
  //           return;
  //         }
  //       });
  //     });
  //   }
  // };

  const prepareDataForOrder = (): OrderDataInterface => {
    const dataForOrder: OrderDataInterface = {};
    if (values)
      Object.keys(receivedData.suppliers).forEach((supplierKey) => {
        // TODO: Replace with real data

        let hasFoundAny = false;
        const arrayOfProducts: ProductDetailsDataInterface[] = [];
        Object.keys(values).forEach((productKey) => {
          if (values[productKey][supplierKey]) {
            hasFoundAny = true;
            const newProductForOrder = {
              productName: data.products[productKey].productName,
              uid: data.products[productKey].uid,
              sku: data.products[productKey].sku,
              ipc: data.products[productKey].manufacturerCode,
              ean: data.products[productKey].ean,
              quantity: values[productKey][supplierKey],
              price: data.prices[productKey][supplierKey].value || 0,
              shipping: data.prices[productKey][supplierKey].shipping || 0,
              bonus: data.prices[productKey][supplierKey].bonus || 0,
              ecoTax: data.prices[productKey][supplierKey].ecoTax || 0,
              productYear:
                data.prices[productKey][supplierKey].productYear ||
                new Date().getFullYear(),
              invoicePrice:
                data.prices[productKey][supplierKey].invoicePrice || 0,
            };
            arrayOfProducts.push(newProductForOrder);
          }
        });

        if (hasFoundAny) {
          dataForOrder[`${supplierKey}`] = arrayOfProducts;
        }
      });
    return dataForOrder as unknown as OrderDataInterface;
  };

  return (
    <GeneralWrapper>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            setIsCancelModalVisible(false);
            props.onBack(false);
          }}
        />
      )}
      <LeftPageWrapper>
        <LeftPageTemplateContent>
          <HeaderWrapper>
            <TitleAndBackWrapper>
              <IconButton
                onClick={() => props.onBack(false)}
                style={{ width: '20px' }}
              >
                <ArrowBackIos />
              </IconButton>
              <Title>Price comparison</Title>
            </TitleAndBackWrapper>
            {nonReceivedData !== '' && (
              <NonReceivedError>
                <ErrorTextWrapper>{nonReceivedData}</ErrorTextWrapper>
                <IconButton
                  style={{ marginRight: `${tyrioUI.spacing.m}px` }}
                  onClick={() => setNonReceivedData('')}
                >
                  <Cancel style={{ color: '#D32F2F' }} />
                </IconButton>
              </NonReceivedError>
            )}
          </HeaderWrapper>
          <SearchTextField
            variant={'outlined'}
            label={'Search products'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => handleSearchChange(event.target.value)}
          ></SearchTextField>
          <TopWrapper>
            <SwitchesWrapper>
              <Tooltip title={'Show items with zero stock'} placement={'top'}>
                <SwitchWrapper
                  control={
                    <Switch
                      color="info"
                      onChange={(event, checked) =>
                        handleSwitchChange(SwitchType.LagerZero, checked)
                      }
                    />
                  }
                  label={'Stock 0'}
                />
              </Tooltip>
              <Tooltip
                title={'Show price percentage difference'}
                placement={'top'}
              >
                <SwitchWrapper
                  control={
                    <Switch
                      color="info"
                      onChange={(event, checked) =>
                        handleSwitchChange(SwitchType.Difference, checked)
                      }
                    />
                  }
                  label={'Difference %'}
                />
              </Tooltip>
              <Tooltip
                title={'Show price with eco tax included'}
                placement={'top'}
              >
                <SwitchWrapper
                  control={
                    <Switch
                      color="info"
                      onChange={(event, checked) =>
                        handleSwitchChange(SwitchType.EcoTax, checked)
                      }
                    />
                  }
                  label={'Eco tax'}
                />
              </Tooltip>
              <Tooltip
                title={'Show price with shipping included'}
                placement={'top'}
              >
                <SwitchWrapper
                  control={
                    <Switch
                      color="info"
                      onChange={(event, checked) =>
                        handleSwitchChange(SwitchType.Shipping, checked)
                      }
                    />
                  }
                  label={'Shipping'}
                />
              </Tooltip>
              <Tooltip
                title={'Show price with bonus included'}
                placement={'top'}
              >
                <SwitchWrapper
                  control={
                    <Switch
                      color="info"
                      onChange={(event, checked) =>
                        handleSwitchChange(SwitchType.Bonus, checked)
                      }
                    />
                  }
                  label={'Bonus'}
                />
              </Tooltip>
              <Tooltip title={'Show code for each product'} placement={'top'}>
                <SwitchWrapper
                  control={
                    <Switch
                      color="info"
                      onChange={(event, checked) =>
                        handleSwitchChange(SwitchType.Code, checked)
                      }
                    />
                  }
                  label={'Show code'}
                />
              </Tooltip>
            </SwitchesWrapper>
            <EyeWrapper>
              <IconButton
                onClick={() =>
                  setShouldHideSupplierName(!shouldHideSupplierName)
                }
              >
                {shouldHideSupplierName ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </EyeWrapper>
          </TopWrapper>
          {props.compareByDimensions ? (
            <DimensionStickyTable
              rawData={receivedData}
              data={rows}
              columns={columns}
              shouldShowDifference={shouldShowDifference}
              onChangeSelectedRow={(id) => handleChangeOfSelectedRow(id)}
              onChangeValues={setValues}
              initialQtyValues={[]}
              shouldShowCode={shouldShowCode}
            />
          ) : (
            <StickyTable
              rawData={receivedData}
              data={rows}
              columns={columns}
              shouldShowDifference={shouldShowDifference}
              onChangeSelectedRow={(id) => handleChangeOfSelectedRow(id)}
              onChangeValues={setValues}
              initialQtyValues={prepareDataWithQty()}
              shouldShowCode={shouldShowCode}
            />
          )}
          <Divider sx={{ margin: `${tyrioUI.spacing.l}px` }}></Divider>
          <BottomButtonsWrapper>
            <Button
              variant={'outlined'}
              color={'info'}
              startIcon={<Cancel color={'info'}></Cancel>}
              onClick={() => {
                setIsCancelModalVisible(true);
              }}
            >
              Cancel
            </Button>

            <Button
              variant={'contained'}
              color={'info'}
              onClick={() => {
                handleConfirmOrder();
              }}
            >
              Order
            </Button>
          </BottomButtonsWrapper>
        </LeftPageTemplateContent>
      </LeftPageWrapper>
      <RightPageWrapper>
        <PageTemplateContent style={{ width: '100%' }}>
          <ProductReviewWithStats
            selectedProduct={selectedProduct}
            numberOfAvailableItems={selectedProductAvailableNumber}
            numberOfBackorderItems={selectedProductBackorderNumber}
            numberOfReservedItems={selectedProductReservedNumber}
            key={
              selectedProduct
                ? `stats_${selectedProduct.productName}_${selectedProductReservedNumber}_${selectedProductBackorderNumber}_${selectedProductAvailableNumber}`
                : 'stats_product'
            }
          />
        </PageTemplateContent>
      </RightPageWrapper>
    </GeneralWrapper>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${tyrioUI.spacing.xl}px ${tyrioUI.spacing.l}px;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
`;

const TopWrapper = styled.div`
  margin: ${tyrioUI.spacing.l}px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SwitchWrapper = styled(FormControlLabel)`
  margin: 0 ${tyrioUI.spacing.l}px;
`;

const EyeWrapper = styled.div`
  width: 10%;
  display: flex;
  flex-direction: row-reverse;
`;
const SwitchesWrapper = styled.div`
  width: 87%;
`;
const GeneralWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const RightPageWrapper = styled(PageTemplateWrapper)`
  padding-left: ${tyrioUI.spacing.l}px;
  padding-right: ${tyrioUI.spacing.m}px;
  margin-right: 5px;
  display: block;
  max-width: 400px;
`;

const LeftPageWrapper = styled(PageTemplateWrapper)`
  display: block;
  max-width: calc(80vw - 130px);
`;

const LeftPageTemplateContent = styled(PageTemplateContent)`
  padding-right: 0;
  margin-right: 0;
  width: 100%;
`;

const SearchTextField = styled(TextField)`
  margin: ${tyrioUI.spacing.xl}px ${tyrioUI.spacing.xl}px;
`;
const BottomButtonsWrapper = styled.div`
  width: 100%;
  padding: ${tyrioUI.spacing.xl}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NonReceivedError = styled.div`
  border: 1.5px solid #d32f2f;
  border-radius: ${tyrioUI.borderRadius.m}px;
  color: #d32f2f;
  padding: ${tyrioUI.spacing.s}px ${tyrioUI.spacing.m}px;
  margin-top: ${tyrioUI.spacing.l}px;
  display: flex;
  flex-direction: row;
`;
const TitleAndBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;
const ErrorTextWrapper = styled.div`
  width: 100%;
  padding: ${tyrioUI.spacing.m}px 0;
`;
