import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';
import { tyrioIcons } from '@tyrio/ui-library';

//TODO: Get exact list of menu icons.
export const menuLinksAdmin = [
  {
    link: '/dashboard/admin',
    icon: tyrioIcons.adminIcon,
    label: 'Admin Users',
    isVisible: true,
    permissionPath: 'adminUsers',
  },
  {
    link: '/dashboard/supplier',
    icon: tyrioIcons.supplierIcon,
    label: 'Suppliers',
    isVisible: true,
    permissionPath: 'suppliers',
  },
  {
    link: '/dashboard/clients',
    icon: tyrioIcons.clientIcon,
    label: 'Clients',
    isVisible: true,
    permissionPath: 'clients',
  },
  {
    link: '/dashboard/shipping-couriers',
    icon: tyrioIcons.menuIcon3,
    label: 'Shipping Couriers',
    isVisible: true,
    permissionPath: 'shippingCouriers',
  },
  {
    link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
    icon: tyrioIcons.cataloguesIcon,
    label: 'Catalogues',
    isVisible: true,
    permissionPath: 'catalogues',
    subcategories: [
      {
        link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
        label: 'Product Items',
        isVisible: true,
        permissionPath: 'catalogues.productItems',
      },
      {
        link: '/dashboard/categories',
        label: 'Product Categories',
        isVisible: true,
        permissionPath: 'catalogues.productCategories',
      },
      {
        link: '/dashboard/attributes',
        label: 'Product Attributes',
        isVisible: true,
        permissionPath: 'catalogues.productAttributes',
      },
      {
        link: '/dashboard/import-status',
        label: 'Import status',
        isVisible: true,
        permissionPath: 'catalogues.importStatus',
      },
    ],
  },
  {
    link: '/dashboard/roles',
    icon: tyrioIcons.menuIcon7White,
    label: 'Settings',
    isVisible: true,
    permissionPath: 'settings',
    subcategories: [
      {
        link: '/dashboard/roles',
        label: 'Roles',
        isVisible: true,
        permissionPath: 'settings.roles',
      },

      {
        link: '/dashboard/countries',
        label: 'Countries',
        isVisible: true,
        permissionPath: 'settings.countries',
      },
    ],
  },
];

const _menuLinksClientsBase = [
  {
    link: '/dashboard/customer-orders',
    icon: tyrioIcons.menuIcon3,
    label: 'Sales',
    isVisible: true,
    permissionPath: 'sales',
    subcategories: [
      {
        link: '/dashboard/pos',
        label: 'POS',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_POS) ?? false,
        permissionPath: 'sales.pos',
      },
      {
        link: '/dashboard/appointments',
        label: 'Appointments',
        isVisible:
          isFlagEnabled(FEATURE_FLAGS.SHOW_APPOINTMENT_CALENDAR) ?? false,
        permissionPath: 'sales.appointments',
      },
      {
        link: '/dashboard/customer-orders',
        label: 'Customer Orders',
        isVisible: true,
        permissionPath: 'sales.customerOrders',
      },
      {
        link: '/dashboard/price-calculation',
        label: 'Price Calculation',
        isVisible: true,
        permissionPath: 'sales.priceCalculation',
      },
      {
        link: '/dashboard/custom-price-catalog',
        label: 'Custom Prices',
        isVisible:
          isFlagEnabled(FEATURE_FLAGS.SHOW_CUSTOM_PRICE_CATALOG) ?? false,
        permissionPath: 'sales.customPrices',
      },
      {
        link: '/dashboard/customer-rebates',
        label: 'Customer Rebates',
        isVisible: true,
        permissionPath: 'sales.customerRebates',
      },
    ],
  },
  {
    link: '/dashboard/price-comparison',
    icon: tyrioIcons.menuIcon5,
    label: 'Purchasing',
    isVisible: true,
    permissionPath: 'purchasing',
    subcategories: [
      {
        link: '/dashboard/price-comparison',
        label: 'Price Compare',
        isVisible: true,
        permissionPath: 'purchasing.priceCompare',
      },
      {
        link: '/dashboard/purchase-orders',
        label: 'Purchase Orders',
        isVisible: true,
        permissionPath: 'purchasing.purchaseOrders',
      },
      {
        link: '/dashboard/supplier',
        label: 'Supplier Settings',
        isVisible: true,
        permissionPath: 'purchasing.supplierSettings',
      },
    ],
  },
  {
    link: '/dashboard/wms',
    icon: tyrioIcons.menuIcon10,
    label: 'WMS',
    isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_WMS) ?? false,
    permissionPath: 'wms',
    subcategories: [
      {
        link: '/dashboard/location-details',
        label: 'Stock locations',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_LOCATIONS) ?? false,
        permissionPath: 'wms.stockLocations',
      },
      {
        link: '/dashboard/warehouse',
        label: 'Warehouse locations',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_LOCATIONS) ?? false,
        permissionPath: 'wms.warehouseLocations',
      },
      {
        link: '/dashboard/stock-list',
        label: 'Stock list',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_LIST) ?? false,
        permissionPath: 'wms.stockList',
      },
      {
        link: '/dashboard/transfer-orders?searchType=orderId&searchKeyword=&branchId=All&type=All&orderStatus=all&pageSize=20&page=1',
        label: 'Transfer orders',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_TRANSFER_ORDERS) ?? false,
        permissionPath: 'wms.transferOrders',
      },
      {
        link: '/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&supplierId=all&orderStatus=new',
        label: 'Stock IN',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_IN) ?? false,
        permissionPath: 'wms.stockIn',
      },
      {
        link: '/dashboard/stock-list-import',
        label: 'Stock list import',
        isVisible: isFlagEnabled(FEATURE_FLAGS.STOCK_LIST_IMPORT) ?? false,
        permissionPath: 'wms.stockListImport',
      },
      {
        link: '/dashboard/work-orders',
        label: 'Stock out dashboard',
        isVisible: true,
        permissionPath: 'wms.stockOutDashboard',
      },
      {
        link: '/dashboard/inbound-invoices',
        label: 'Inbound invoice',
        isVisible: true,
        permissionPath: 'wms.inboundInvoice',
      },
    ],
  },
  {
    link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
    icon: tyrioIcons.menuIcon8,
    label: 'Catalogues',
    isVisible: true,
    permissionPath: 'catalogues',
    subcategories: [
      {
        link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
        label: 'Product Items',
        isVisible: true,
        permissionPath: 'catalogues.productItems',
      },
      {
        link: '/dashboard/services-category',
        label: 'Services',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_SERVICES) ?? false,
        permissionPath: 'catalogues.services',
      },
      {
        link: '/dashboard/partners',
        label: 'Partners',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_PARTNERS) ?? false,
        permissionPath: 'catalogues.partners',
      },
    ],
  },
];

export const menuLinksClientSuperAdmin = [
  ..._menuLinksClientsBase,
  {
    link: '/dashboard/company-settings',
    icon: tyrioIcons.menuIcon7White,
    label: 'Settings',
    isVisible: true,
    permissionPath: 'settings',
  },
];

export const menuLinksClientOthers = [
  ..._menuLinksClientsBase,
  {
    link: '/dashboard/roles',
    icon: tyrioIcons.menuIcon7White,
    label: 'Settings',
    isVisible: true,
    permissionPath: 'settings.roles',
    subcategories: [
      {
        link: '/dashboard/roles',
        label: 'Roles',
        isVisible: true,
        permissionPath: 'settings.roles',
      },
      {
        link: '/dashboard/company-settings',
        label: 'Company Settings',
        isVisible: true,
        permissionPath: 'settings',
      },
    ],
  },
];
