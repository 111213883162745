import * as countries from './countries';
import * as languages from './languages';

export const sharedVars = {
  countries,
  languages,
};

export const RULESET_KEYS = {
  /**
   * EDI WHEEL
   */
  FIRESTONE: 'FIRESTONE',
  MICHELIN: 'MICHELIN',
  PIRELLI: 'PIRELLI',
  GOODYEAR: 'GOODYEAR',
  // VREDESTEIN: 'VREDESTEIN',
  KUMHO: 'KUMHO',
  BRIDGESTONE: 'BRIDGESTONE',
  CONTINENTAL: 'CONTINENTAL',
  APOLLOVREDESTEIN: 'APOLLOVREDESTEIN',
  HANKOOK: 'HANKOOK',
  /**
   * CUSTOM
   */
  VANDENBAN: 'VANDENBAN',
  GUNDLACH: 'GUNDLACH',
  TYRIOCUSTOM: 'TYRIOCUSTOM',
  DELDO: 'DELDO',
  INTERSPRINT: 'INTERSPRINT',
  INTERPNEU: 'INTERPNEU',
  ZBR: 'ZBR',
  TYROO: 'TYROO',
  MONGO_OLD_CATALOG: 'MONGO_OLD_CATALOG',
  GUMAX: 'GUMAX',
  GUMIIMPEX: 'GUMIIMPEX',
  VULKAL: 'VULKAL',
  BFPNEUMATIC: 'BFPNEUMATIC',
  ATRAXION: 'ATRAXION',
  WHEELTRADE: 'WHEELTRADE',
  SUPERIOR: 'SUPERIOR',
  INTERTYRE: 'INTERTYRE',
  UNIKOMERC: 'UNIKOMERC',
  AUTEC: 'AUTEC',
  PNEUMATIK: 'PNEUMATIK',
  REIFENMUELLER: 'REIFENMUELLER',
  ZPERFORMANCE: 'ZPERFORMANCE',
  NOWEGUMY: 'NOWEGUMY',
  RONAL: 'RONAL',
  RADCENTAR: 'RADCENTAR',
  GMPITALY: 'GMPITALY',
  MOTOBOX: 'MOTOBOX',
  INTERCARS: 'INTERCARS',
};

export * from './catalog';
export * from './special-markings';
