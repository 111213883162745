import { RawImport, UnfinishedData } from '@tyrio/dto';

import {
  genericGenerateSpecialMarkings,
  splitSpecialMarkingString,
} from './operations.helpers';
import { splitPCD } from '@tyrio/shared-vars';
import { TRule } from '../typings';
import { WHEEL_CATEGORY_SLUGS } from './slugs';
import _ from 'lodash';

export function generateTyrioCustomSpecialMarkings(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateTyrioCustomSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [
    input['specialMarkings'],
    input['rfd'],
    input['sidewall'],
  ].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => input['runFlat'] === 'TRUE',
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  if (input['flank'] === 'TRUE') {
    splitSpecialMarkings.push('FR');
  }

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}

export function generateMongoCatalogCustomSpecialMarkings(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateMongoCatalogCustomSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [
    input['SpecialMarking'],
    input['RFD'],
    input['Sidewall'],
    input['Flank'],
    input['Run_Flat'],
  ].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => !!input['Run_Flat'],
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}

export function generateHoleNumbersHoleCirclesPCDs(
  input: RawImport,
  rule: TRule,
  data: UnfinishedData
) {
  const trimmedPCDValues = (_.castArray(data.product['pcd']) as string[]).map(
    (pcdValue) => pcdValue.trim()
  );

  data.product['pcd'] = trimmedPCDValues;

  const splitValues = splitPCD(data.product['pcd']);

  data.product = { ...data.product, ...splitValues };

  if (WHEEL_CATEGORY_SLUGS.includes(data.model['categorySlug'])) {
    const holeNumbers = new Set<string>();
    const holeCircles = new Set<string>();
    const pcdValues = (data.product['pcd'] as string[]) ?? [];
    pcdValues.forEach((pcdValue) => {
      const vals = pcdValue
        .trim()
        .split('x')
        .map((val) => String(val));

      if (vals.length === 2) {
        holeNumbers.add(vals[0].trim());
        holeCircles.add(vals[1].trim());
      }
    });
    data.product['holeNumber'] = Array.from(holeNumbers);
    data.product['holeCircle'] = Array.from(holeCircles);
  }

  return data;
}

export function generatePcdEt(
  input: RawImport,
  rule: TRule,
  data: UnfinishedData
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error('generatePcd requires rule to be of type TOperateRule');

  let pcdList: string[] = [];

  if (WHEEL_CATEGORY_SLUGS.includes(data.model['categorySlug'])) {
    pcdList = input['pcd']
      ?.toLowerCase()
      .split('|')
      .map((item) => item.trim());
  }

  const product = {
    product: {
      pcd: pcdList,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return product;
}

export function getMongoOtherCategories(
  input: RawImport,
  _rule: TRule,
  data: UnfinishedData
) {
  let category = data.model['category_id'];

  switch (input['Category']) {
    case 'Accessories':
      if (input['Subcategory'].toUpperCase().includes('OTHER')) {
        category = 112;
      }
      break;
    case 'agroTyres':
      if (input['Subcategory'].toUpperCase().includes('FARM')) {
        category = 65;
      }
      break;
    case 'atvTyres':
      if (input['Subcategory'].toUpperCase().includes('FARM')) {
        category = 48;
      }
      break;
    case 'bicycleTyres':
      if (input['Subcategory'].toUpperCase().includes('UNRATED')) {
        category = 61;
      }
      break;
    case 'carTyres':
      if (input['Subcategory'].toUpperCase().includes('RACING')) {
        category = 33;
      }
      break;
    case 'goKart':
      if (input['Subcategory'].toUpperCase().includes('RACING')) {
        category = 63;
      }
      break;
    case 'motoTyres':
      if (input['Subcategory'].toUpperCase().includes('RACING')) {
        category = 40;
      }
      break;
    case 'snowChains':
      if (input['Subcategory'].toUpperCase().includes('UNRATED')) {
        category = 94;
      }
      break;
    case 'Thule':
      if (input['Subcategory'].toUpperCase().includes('RAZNO')) {
        category = 114;
      }
      break;
    case 'wheelBolts':
      if (input['Subcategory'].toUpperCase().includes('OTHER')) {
        category = 111;
      }
      break;
    case 'Wipers':
      category = 24;
      break;
  }

  data.model['category_id'] = category;

  return data;
}

export function fixEan13(input: RawImport, _rule: TRule, data: UnfinishedData) {
  let ean = data.product['ean'];

  if (ean && ean.trim() !== "") {
    if (
      data.product['ean'].startsWith('197') ||
      data.product['ean'].startsWith('238') ||
      data.product['ean'].startsWith('291') ||
      data.product['ean'].startsWith('9101')
    ) {
      ean = ('000' + data.product['ean']).slice(-13);
    }
  }

  data.product['ean'] = ean;

  return data;
}
