import { SearchOutlined } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { DBStockInListRequest } from '@tyrio/dto';
import _ from 'lodash';
import qs from 'qs';
import { useState } from 'react';

interface StockInSearchTypeInputProps {
  filters: qs.ParsedQs;
  setFilters: (value: Partial<qs.ParsedQs>) => void;
  listItems: { value: string; label: string }[];
}

const StockInSearchTypeInput = ({
  filters,
  setFilters,
  listItems,
}: StockInSearchTypeInputProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const getSelectFieldStyle = () => {
    const borderWeight = isClicked ? 2 : 1;
    const bordersStyle =
      isHovered || isClicked ? `${borderWeight}px solid black` : '';
    return {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: bordersStyle,
    };
  };

  const getTextFieldStyle = () => {
    const borderWeight = isClicked ? 2 : 1;
    const bordersStyle =
      isHovered || isClicked ? `${borderWeight}px solid black` : '';
    return {
      borderLeft: 'none',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderRight: bordersStyle,
      borderTop: bordersStyle,
      borderBottom: bordersStyle,
    };
  };

  const getSearchFieldProps = () => {
    return {
      onMouseOver: () => setIsHovered(true),
      onMouseOut: () => setIsHovered(false),
      onFocus: () => setIsClicked(true),
      onBlur: () => {
        setIsClicked(false);
        setIsHovered(false);
      },
    };
  };

  return (
    <Grid>
      <FormControl
        sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
      >
        <InputLabel sx={{ width: '20%' }}>Search type</InputLabel>
        <Select
          label="Search type"
          {...getSearchFieldProps()}
          sx={{
            width: '30%',
            '& fieldset': {
              ...getSelectFieldStyle(),
            },
          }}
          defaultValue={filters['searchType']}
          value={filters['searchType']}
          onChange={(e) => {
            setFilters({
              ...filters,
              searchType: e.target.value as DBStockInListRequest['searchType'],
            });
          }}
        >
          {listItems.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Tooltip
          followCursor
          title={
            _.isEmpty(filters['searchType'])
              ? 'You must choose search type!'
              : ''
          }
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            disabled={_.isEmpty(filters['searchType'])}
            type="text"
            {...getSearchFieldProps()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '70%',
              '& fieldset': {
                ...getTextFieldStyle(),
              },
            }}
            value={filters['searchKeyword']}
            onChange={(e) => {
              setFilters({
                ...filters,
                searchKeyword: e.target.value,
              });
            }}
          />
        </Tooltip>
      </FormControl>
    </Grid>
  );
};

export default StockInSearchTypeInput;
