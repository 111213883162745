import { FileControlButtonsProps } from './types';

export const getFileData = (props: FileControlButtonsProps, type?: string) => {
  const fileData = {
    customFileName: type ? type : undefined,
    supplierName: props.supplierName,
    fileFormat: props.connection.fileFormat.toLowerCase(),
    isFileZipped: props.connection.isFileZipped ?? false,
    connectionType:
      props.connection.connectionTypes.length > 1
        ? props.connection.connectionTypes.join('_').toString().toUpperCase()
        : props.connection.connectionTypes.toString().toUpperCase(),
  };
  if (props.connection.ftpConnection) {
    return {
      ...fileData,
      fileName: props.connection.ftpConnection?.fileLocation,
      ftpHost: props.connection.ftpConnection?.ftpHost,
      ftpPort: props.connection.ftpConnection?.ftpPort,
      ftpUsername: props.connection.ftpConnection?.ftpUserName,
      ftpPassword: props.connection.ftpConnection?.ftpPassword,
    };
  }
  if (props.connection.apiConnection) {
    return {
      ...fileData,
      apiUrl: props.connection.apiConnection?.apiUrl,
      apiKey: props.connection.apiConnection?.apiKey,
      apiClientId: props.connection.apiConnection?.apiClientId,
      apiClientSecret: props.connection.apiConnection?.apiClientSecret,
    };
  }
  return {
    ...fileData,
    httpsUrl: props.connection.httpsConnection?.httpsUrl,
    httpsUsername: props.connection.httpsConnection?.username,
    httpsPassword: props.connection.httpsConnection?.password,
  };
};

export const getEndpoint = (props: FileControlButtonsProps) => {
  if (props.connection.ftpConnection) return 'download_ftp';
  if (props.connection.apiConnection) return 'download_api';
  return 'download_https';
};
