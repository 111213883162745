import * as z from "zod"
import { CourierService } from ".prisma/client"
import { CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBTransferOrderItem, RelatedDBTransferOrderItemModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBTrackingInfoModel = z.object({
  id: z.string(),
  trackingNumber: z.string(),
  internalStatus: z.string().nullish(),
  courierServiceStatus: z.string().nullish(),
  statusDescription: z.string().nullish(),
  courierService: z.nativeEnum(CourierService),
  createdAt: z.date(),
  updatedAt: z.date(),
  customerOrderId: z.string().nullish(),
  transferOrderId: z.string().nullish(),
  orderDetails: jsonSchema,
  clientCourierId: z.string().nullish(),
})

export interface CompleteDBTrackingInfo extends z.infer<typeof DBTrackingInfoModel> {
  customerOrder?: CompleteDBCustomerOrderItem | null
  transferOrder?: CompleteDBTransferOrderItem | null
}

/**
 * RelatedDBTrackingInfoModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBTrackingInfoModel: z.ZodSchema<CompleteDBTrackingInfo> = z.lazy(() => DBTrackingInfoModel.extend({
  customerOrder: RelatedDBCustomerOrderItemModel.nullish(),
  transferOrder: RelatedDBTransferOrderItemModel.nullish(),
}))
