import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import DeliveryTruckIcon from './delivery-truck.svg';
import { SalesOrdersFlowContext } from '../SalesOrdersFlowContext';
import { DBProduct, DBSupplier } from '@prisma/client';
import * as dateFns from 'date-fns';
import { roundPriceToTwoDecimals } from '@tyrio/shared-vars';
import { Chip, CircularProgress, Tooltip, Typography } from '@mui/material';
import { get, sumBy } from 'lodash';

interface OrderItemProps {
  supplier: DBSupplier;
  product: DBProduct;
  selected?: boolean;
  price: number;
  originalPrice?: number;
  isLowestPrice?: boolean;
  isMainSupplier?: boolean;
  isFastestDelivery?: boolean;
  requestedQuantity: number;
  supplierQuantity: number;
  mainSupplierPrice?: number;
  lowestProductPrice?: number;
  resolvedSupplierQty?: number;
  resolvedSupplierDeliveryDate?: string;
  resolvingSupplierInquiry?: boolean;
  purchasedQuantity: number;
  inquiryError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  priceObj?: any;
  minDeliveryDate: string | null;
  maxDeliveryDate: string | null;
  isOrderLate: boolean;
  historyMissing?: boolean;
}
export const OrderItem = ({
  supplier,
  product,
  isMainSupplier,
  price,
  originalPrice,
  mainSupplierPrice,
  lowestProductPrice,
  requestedQuantity,
  supplierQuantity: supplierQuantityCatalog,
  resolvedSupplierQty,

  resolvingSupplierInquiry,
  inquiryError,
  minDeliveryDate,
  maxDeliveryDate,
  isOrderLate,
  historyMissing,
}: OrderItemProps) => {
  const supplierQuantity = resolvedSupplierQty ?? supplierQuantityCatalog;
  const ctx = useContext(SalesOrdersFlowContext);

  const canFulfillQuantity = requestedQuantity <= supplierQuantity;

  const deliveryDateString =
    ctx.data.deliveryDates?.[supplier.id]?.[product.uid];

  const deliveryDate = deliveryDateString
    ? dateFns.parseISO(deliveryDateString)
    : undefined;

  const deliveryDates = useMemo(
    () =>
      Object.values(ctx.data.deliveryDates?.[product.uid] ?? {}).sort(
        (aString, bString) => {
          const a = dateFns.parseISO(aString);
          const b = dateFns.parseISO(bString);
          if (dateFns.isSameDay(a, b)) return 0;
          if (dateFns.isBefore(a, b)) return -1;
          return 1;
        }
      ),
    [ctx.data.deliveryDates, product.uid]
  );

  const shortestDeliveryDate = deliveryDates?.[0] ?? null;
  const isFastestDelivery = useMemo(
    () =>
      deliveryDate
        ? dateFns.isSameDay(
            dateFns.parseISO(shortestDeliveryDate),
            deliveryDate
          )
        : false,
    [deliveryDate, shortestDeliveryDate]
  );

  const isLowestPrice = price?.toFixed(5) === lowestProductPrice?.toFixed(5);

  const setQuantity = useCallback(
    (value: string) => {
      const converted = Number(value);
      const parsedQty = isNaN(converted) ? 0 : converted;
      // const isManufacturer = supplier.supplierType === 'MANUFACTURER';

      const supplierQty = sumBy(Object.values(ctx.qty.supplier), product.uid);
      const stockQty = sumBy(Object.values(ctx.qty.stock), product.uid);
      const filledQty = supplierQty + stockQty;

      const requestedQty = ctx.data.requested[product.uid];

      let finalValue = Math.max(0, parsedQty);

      if (
        filledQty === requestedQty &&
        finalValue + filledQty > requestedQty &&
        finalValue > get(ctx.qty.supplier[supplier.id], `${product.uid}`, 0)
      )
        return;

      // if (!isManufacturer) {
      //   finalValue = Math.min(finalValue, supplierQuantity);
      // }

      finalValue = Math.min(finalValue, requestedQty);
      ctx.setQty(supplier.id, product.uid, finalValue, 'supplier');
    },
    [ctx, product.uid, supplier.id]
  );

  const mainSupplierHasLowestPrice = mainSupplierPrice === price;

  const mainSupplierPriceChangedToMoreExpensive = useMemo(() => {
    if (!isMainSupplier) return false;
    const safeOriginal = roundPriceToTwoDecimals(originalPrice ?? 0);
    const safePrice = roundPriceToTwoDecimals(price);

    return !!(safeOriginal && safeOriginal < safePrice);
  }, [isMainSupplier, originalPrice, price]);

  const mainSupplierPriceDifferent = useMemo(() => {
    if (!isMainSupplier) return false;
    const safeOriginal = roundPriceToTwoDecimals(originalPrice ?? 0);
    const safePrice = roundPriceToTwoDecimals(price);

    return !!(safeOriginal && safeOriginal !== safePrice);
  }, [isMainSupplier, originalPrice, price]);

  const showOriginalPrice =
    originalPrice && isMainSupplier && mainSupplierPriceDifferent;

  const mainSupplierPreferred = useMemo(() => {
    if (!isMainSupplier || mainSupplierPriceChangedToMoreExpensive)
      return false;

    if (originalPrice && originalPrice >= price) return true;
    return price === lowestProductPrice;
  }, [
    isMainSupplier,
    lowestProductPrice,
    mainSupplierPriceChangedToMoreExpensive,
    originalPrice,
    price,
  ]);

  const mainSupplierHasNoStock =
    isMainSupplier &&
    supplier.supplierType !== 'MANUFACTURER' &&
    supplierQuantity === 0;

  useEffect(() => {
    if (
      mainSupplierPreferred &&
      ctx.qty.supplier?.[supplier.id]?.[product.uid] === undefined
    ) {
      setQuantity(String(requestedQuantity));
    }
  }, [
    ctx.dirty,
    ctx.qty,
    mainSupplierPreferred,
    product.uid,
    requestedQuantity,
    setQuantity,
    supplier.id,
  ]);

  const inputQty = useMemo(() => {
    const value = ctx.qty.supplier?.[supplier.id]?.[product.uid] ?? undefined;
    return String(value);
  }, [ctx, product.uid, supplier.id]);

  useEffect(() => {
    if (!ctx.selected[product.uid] && mainSupplierPreferred && isMainSupplier) {
      ctx.setSelected(supplier.id, product.uid);
    }
  }, [
    ctx,
    ctx.selected,
    isMainSupplier,
    mainSupplierPreferred,
    product,
    supplier,
  ]);

  const isSelected = get(ctx.qty.supplier, [supplier.id, product.uid], 0) > 0;

  const pillColorClassName =
    supplierQuantity === 0
      ? 'red'
      : supplierQuantity < requestedQuantity
      ? 'orange'
      : 'dark';

  return (
    <Wrapper
      selected={isSelected}
      mainSupplierPreferred={mainSupplierPreferred}
      mainSupplierNotEligible={mainSupplierPriceChangedToMoreExpensive}
      mainSupplierHasNoStock={mainSupplierHasNoStock}
      onClick={() => {
        if (!mainSupplierHasNoStock) ctx.setSelected(supplier.id, product.uid);
      }}
    >
      {resolvingSupplierInquiry && !inquiryError ? (
        <LoadingIndicatorWrapper>
          <Title>{supplier.companyShortName}</Title>
          <CircularProgress size={'2rem'} color="info" />
          <Typography color="#1976d2">stock inquiry</Typography>
        </LoadingIndicatorWrapper>
      ) : (
        <>
          <Split>
            <Title>{supplier.companyShortName}</Title>
            <Pill canFulfillQuantity={canFulfillQuantity} id="pill">
              <input
                className="light"
                value={inputQty}
                placeholder={'0'}
                type="number"
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              ></input>
              {resolvedSupplierQty ? (
                <Tooltip title={'Showing realtime quantity from supplier'}>
                  <div className={pillColorClassName}>
                    {resolvedSupplierQty}
                  </div>
                </Tooltip>
              ) : (
                <div className={pillColorClassName}>{supplierQuantity}</div>
              )}
            </Pill>
          </Split>
          <Price
            mainSupplierPreferred={mainSupplierPreferred}
            mainSupplierNotEligible={mainSupplierPriceChangedToMoreExpensive}
            mainSupplierHasNoStock={mainSupplierHasNoStock}
            hasOriginalPrice={!!showOriginalPrice}
            isLowestPrice={isLowestPrice}
            isMainSupplier={isMainSupplier}
            mainSupplierHasLowestPrice={mainSupplierHasLowestPrice}
          >
            {price.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              currencyDisplay: 'symbol',
            })}
          </Price>
          {showOriginalPrice && (
            <OriginalPrice>
              {Number(originalPrice).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              })}
            </OriginalPrice>
          )}
          {minDeliveryDate && maxDeliveryDate && (
            <DeliveryDate
              isFastestDelivery={isFastestDelivery}
              isOrderLate={isOrderLate}
            >
              {isFastestDelivery && (
                <img alt="delivery truck icon" src={DeliveryTruckIcon} />
              )}
              <span>
                {dateFns.format(new Date(minDeliveryDate), 'dd.MM')} -{' '}
                {dateFns.format(new Date(maxDeliveryDate), 'dd.MM.yyyy')}
              </span>
            </DeliveryDate>
          )}

          {inquiryError && <StyledChip label="API ERROR" color="error" />}
        </>
      )}
      {resolvedSupplierQty !== undefined && (
        <StyledChip
          label="API"
          color="success"
          variant="outlined"
          sx={{ background: 'white' }}
        />
      )}
      {/* {historyMissing && (
        <StyledChip
          label="SUPPLIER MISSING"
          color="warning"
          variant="filled"
          style={{ background: '#e86032' }}
        />
      )} */}
    </Wrapper>
  );
};

interface WrapperProps {
  selected?: boolean;
  mainSupplierPreferred?: boolean;
  mainSupplierNotEligible?: boolean;
  hasOriginalPrice?: boolean;
  isLowestPrice?: boolean;
  isMainSupplier?: boolean;
  mainSupplierHasLowestPrice?: boolean;
  mainSupplierHasNoStock?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  border: 1px solid #dfe3e8;
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  ${(props) =>
    props.selected
      ? `padding: 5px 3px 3px 3px; border: 2px solid #1976D2;`
      : 'padding: 6px 4px 4px 4px;'}
`;

export const Title = styled.h3`
  padding: 0;
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;

  text-align: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  display: inline-block;
`;

export const Pill = styled.div<{ canFulfillQuantity: boolean }>`
  display: flex;
  width: 100px;
  min-height: 38px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 6px;

  div,
  input {
    flex: 1;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.3px;

    &.light {
      color: #212b36;
      transition: all 0.2s ease;
      padding: 0;
      margin: 0;

      text-align: center;

      &:focus {
        outline: none;
        background-color: #f8f8f8;
      }
    }

    &.blue {
      font-weight: 400;
      ${(props) =>
        props.canFulfillQuantity
          ? `
              background: #0f4d91;
              color: white;
          `
          : `
            background: #E86032;
            color: white;
      `}
    }

    &.orange {
      font-weight: 400;
      background: #e86032;
      color: white;
    }

    &.red {
      font-weight: 400;
      background: #db1e1e;
      color: white;
    }

    &.dark {
      font-weight: 400;
      ${(props) =>
        props.canFulfillQuantity
          ? `
              background: #212b36;
              color: white;
          `
          : `
            background: #E86032;
            color: white;
      `}
    }
  }
`;

const Price = styled.div<WrapperProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0.3px;

  color: #212b36;

  ${(props) =>
    props.hasOriginalPrice ? `margin-bottom: 0px;` : 'margin-bottom: 6px;'}

  ${(props) =>
    props.isMainSupplier && props.mainSupplierPreferred
      ? `color: #38A169;`
      : ''}
  ${(props) =>
    props.isMainSupplier && props.mainSupplierNotEligible
      ? `color: #E86032;`
      : ''}
  ${(props) =>
    // !props.isMainSupplier &&
    props.isLowestPrice
      ? // !props.mainSupplierHasLowestPrice
        `
    background: #56C489;
    border-radius: 8px;
    color: white !important;
    padding: 2px 8px;
    `
      : ''}

  ${(props) => (props.mainSupplierHasNoStock ? `color: #212b36;` : '')}
`;

const OriginalPrice = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: line-through;

  margin-bottom: 2px;

  color: #919eab;
`;

const DeliveryDate = styled.div<{
  isFastestDelivery?: boolean;
  isOrderLate?: boolean;
}>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  justify-content: center;

  ${(props) =>
    props.isFastestDelivery ? `color: #38a169;` : 'color: #919EAB;'}

  ${(props) =>
    props.isOrderLate &&
    `padding: 3px 10px;
    border-radius: 8px;
    color: white;
    background: #e86032;`}

    

  img {
    margin-right: 8px;
  }
`;

export const Split = styled.div`
  // height: 74px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const LoadingIndicatorWrapper = styled.div`
  // margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 10px;
`;

const StyledChip = styled(Chip)`
  position: absolute;
  bottom: -12px;
`;
