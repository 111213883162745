import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBProduct, RelatedDBProductModel, CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBSupplierLocation, RelatedDBSupplierLocationModel, CompleteDBImport, RelatedDBImportModel } from "./index"

export const DBSupplierStockModel = z.object({
  id: z.string(),
  clientId: z.string(),
  ean: z.string().nullish(),
  manufacturerCode: z.string().nullish(),
  supplierCode: z.string().nullish(),
  brand: z.string().nullish(),
  productId: z.string(),
  supplierId: z.string(),
  supplierWarehouseId: z.string(),
  supplierWarehouseZip: z.string(),
  supplierProductCode: z.string(),
  supplierProductName: z.string(),
  purchasePrice: z.number().nullish(),
  suggestedRetailPrice: z.number().nullish(),
  ecoTax: z.number(),
  packageQuantity: z.number().int(),
  transportCost: z.number(),
  quantity: z.number().int().nullish(),
  productYear: z.number().int().nullish(),
  importKey: z.string(),
  demo: z.boolean(),
  nextShippingDate: z.date(),
  remark: z.string(),
  active: z.boolean(),
  invoicePrice: z.number().nullish(),
})

export interface CompleteDBSupplierStock extends z.infer<typeof DBSupplierStockModel> {
  client: CompleteDBClient
  product: CompleteDBProduct
  supplier: CompleteDBSupplier
  supplierWarehouse: CompleteDBSupplierLocation
  importObject: CompleteDBImport
}

/**
 * RelatedDBSupplierStockModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierStockModel: z.ZodSchema<CompleteDBSupplierStock> = z.lazy(() => DBSupplierStockModel.extend({
  client: RelatedDBClientModel,
  product: RelatedDBProductModel,
  supplier: RelatedDBSupplierModel,
  supplierWarehouse: RelatedDBSupplierLocationModel,
  importObject: RelatedDBImportModel,
}))
