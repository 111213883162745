import { Alert, Grid, Tooltip } from '@mui/material';
import {
  DBCustomerOrderItemResponse,
  DBCustomerOrderUpdateAddressPayload,
  PhoneNumberDetails,
} from '@tyrio/dto';
import _, { isEqual } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useAuth } from '../../../../context/AuthContext';
import pencil_alt from '../../assets/pencil-alt.svg';
import { EditAddressModal } from '../../modals/EditAddressModal';
import {
  AddressCardWrapper,
  AddressDetails,
  RowWrapper,
  Title,
} from '../../styles/AddressCard.style';
import { AddressModal } from './Modal';

export enum AddressType {
  billing = 'Billing address',
  shipping = 'Delivery address',
}
export interface AddressProps {
  address: string;
  zip: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  phoneNumberDetails: {
    countryCode: string;
    phoneNumber: string;
  };
  email: string;
  companyInfo?: {
    companyName?: string;
    companyInvoice?: boolean;
    vat?: string;
  };
}
interface AddressCardProps {
  addressDetails: AddressProps;
  addressType: AddressType;
  selectedOrderData: DBCustomerOrderItemResponse;
  setAddressUpdateData: Dispatch<
    SetStateAction<DBCustomerOrderUpdateAddressPayload>
  >;
  isDisabled: boolean;
}

export interface EditAddressProps {
  editedBy: string;
  updatedAt: string;
}

export const AddressCard = ({
  addressDetails,
  addressType,
  selectedOrderData,
  setAddressUpdateData,
  isDisabled,
}: AddressCardProps) => {
  const { user } = useAuth();

  const objectKey =
    addressType === AddressType.billing ? 'billingAddress' : 'deliveryAddress';

  const isDataEmpty = !_.isEmpty(selectedOrderData[objectKey]?.editedAddress);

  const [editedMeta, setEditedMeta] = useState({
    editedBy: (
      selectedOrderData[objectKey]?.editedAddress as unknown as EditAddressProps
    )?.editedBy,
    updatedAt: (
      selectedOrderData[objectKey]?.editedAddress as unknown as EditAddressProps
    )?.updatedAt,
  });

  // enables or disables input field
  const [enableField, setEnableField] = useState({
    billing: true,
    shipping: true,
  });

  const [newAddress, setNewAddress] = useState(addressDetails);

  const [editedAddress, setEditedAddress] = useState({
    billingAddress: selectedOrderData?.billingAddress?.editedAddress,
    deliveryAddress: selectedOrderData?.deliveryAddress?.editedAddress,
  });

  const componentKey =
    addressType === AddressType.billing ? 'billingAddress' : 'deliveryAddress';

  const addressData = selectedOrderData[componentKey];

  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const getData = () => {
      if (isDataEmpty) return selectedOrderData[objectKey]?.editedAddress;

      return addressDetails;
    };
    const addressValues = getData();
    setNewAddress(addressValues as unknown as AddressProps);
  }, [addressDetails, isDataEmpty, objectKey, selectedOrderData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    const key = e.target.id || e.target.name;
    let address;

    if (key.includes('companyName') || key.includes('vat')) {
      address = {
        ...newAddress,
        companyInfo: {
          ...newAddress.companyInfo,
          [key]: e.target.value,
        },
      };
    } else address = { ...newAddress, [key]: e.target.value };
    if (addressType === AddressType.billing) {
      setEditedAddress({ ...editedAddress, billingAddress: address });
    } else {
      setEditedAddress({ ...editedAddress, deliveryAddress: address });
    }
    setNewAddress(address);
  };

  const checkIfAddressChanged = useCallback(() => {
    const isEditedBilling =
      isEqual(addressDetails, newAddress) ||
      isEqual(selectedOrderData?.billingAddress?.editedAddress, newAddress);

    const isEditedShipping =
      isEqual(addressDetails, newAddress) ||
      isEqual(selectedOrderData?.deliveryAddress?.editedAddress, newAddress);

    return { isEditedBilling, isEditedShipping };
  }, [
    addressDetails,
    newAddress,
    selectedOrderData?.billingAddress?.editedAddress,
    selectedOrderData?.deliveryAddress?.editedAddress,
  ]);

  const handleEdit = useCallback(() => {
    setIsModalOpen(true);
    addressType === AddressType.billing
      ? setEnableField({
          shipping: enableField.shipping,
          billing: !enableField.billing,
        })
      : setEnableField({
          shipping: !enableField.shipping,
          billing: enableField.billing,
        });
  }, [addressType, enableField.billing, enableField.shipping]);

  useEffect(() => {
    const { isEditedBilling, isEditedShipping } = checkIfAddressChanged();

    const edited = {
      updatedAt: new Date().toString(),
      editedBy: `${user?.firstName} ${user?.lastName}`,
    };

    if (!isEditedBilling && !isEditedShipping) {
      const propName =
        addressType === AddressType.shipping
          ? 'editedDeliveryAddress'
          : 'editedBillingAddress';

      setAddressUpdateData((prevState) => ({
        ...prevState,
        editedBillingAddress:
          propName === 'editedBillingAddress'
            ? editedAddress[componentKey]
            : prevState.editedBillingAddress,
        editedDeliveryAddress:
          propName === 'editedDeliveryAddress'
            ? editedAddress[componentKey]
            : prevState.editedDeliveryAddress,
        ...edited,
      }));
    }

    setEditedMeta(edited);
  }, [
    addressType,
    checkIfAddressChanged,
    componentKey,
    editedAddress,
    newAddress,
    setAddressUpdateData,
    user?.firstName,
    user?.lastName,
  ]);

  const locationDetails = `${
    newAddress?.zip !== '' && newAddress.zip ? newAddress?.zip + ',' : ''
  } ${
    newAddress?.city !== '' && newAddress?.city ? newAddress?.city + ',' : ''
  } ${newAddress?.country?.toUpperCase() ?? ''}`;

  const disabled =
    addressType === AddressType.billing
      ? enableField.billing
      : enableField.shipping;

  return (
    <div style={{ width: '48%' }}>
      {newAddress && (
        <AddressCardWrapper disabled={disabled}>
          {isModalOpen && (
            <EditAddressModal
              open={isModalOpen}
              setOpen={setIsModalOpen}
              addressData={addressData}
              addressType={addressType}
              handleChange={handleChange}
              disabled={disabled}
              newAddress={newAddress}
              setNewAddress={setNewAddress}
              editedMeta={editedMeta}
              editedAddress={editedAddress}
              setEditedAddress={setEditedAddress}
            />
          )}
          <RowWrapper>
            <Title>{addressType}:</Title>

            {!isDisabled && (
              <Tooltip title="Edit address">
                <img
                  src={pencil_alt}
                  alt="pencil_alt"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!isDisabled) handleEdit();
                  }}
                />
              </Tooltip>
            )}
          </RowWrapper>

          <div>
            <AddressDetails style={{ color: '#1976D2' }}>
              {newAddress.companyInfo?.companyName ?? ''}
            </AddressDetails>
            {addressType === AddressType.billing &&
              newAddress.companyInfo?.vat && (
                <AddressDetails>{`VAT: ${
                  newAddress.companyInfo?.vat ?? ''
                }`}</AddressDetails>
              )}
            <AddressDetails style={{ fontSize: '16px', fontWeight: 500 }}>{`${
              newAddress?.firstName?.toUpperCase() ?? ''
            } ${newAddress?.lastName?.toUpperCase() ?? ''}`}</AddressDetails>
            <AddressDetails>{newAddress?.address ?? ''}</AddressDetails>
            <AddressDetails>
              {locationDetails ? `${locationDetails}` : ''}
            </AddressDetails>
            <AddressDetails>
              {newAddress?.phoneNumberDetails
                ? `${
                    (
                      newAddress?.phoneNumberDetails as unknown as PhoneNumberDetails
                    )?.countryCode ?? ''
                  } ${
                    (
                      newAddress?.phoneNumberDetails as unknown as PhoneNumberDetails
                    )?.phoneNumber ?? ''
                  }`
                : ''}
            </AddressDetails>
            {addressType === AddressType.billing && (
              <AddressDetails style={{ color: '#1976D2' }}>{`${
                newAddress?.email ?? ''
              }`}</AddressDetails>
            )}
          </div>

          <Grid sx={{ marginTop: '10px', position: 'sticky', top: '100%' }}>
            {isDataEmpty && (
              <Alert
                severity="error"
                onClick={handleOpen}
                style={{ cursor: 'pointer' }}
              >
                Address has been edited
              </Alert>
            )}
            <AddressModal
              open={open}
              handleClose={handleClose}
              editedMeta={editedMeta}
              addressData={addressData}
            />
          </Grid>
        </AddressCardWrapper>
      )}
    </div>
  );
};
