import styled from '@emotion/styled/macro';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { DBInboundInvoice } from '@prisma/client';
import { InvoiceCreatedBy } from '@tyrio/dto';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

interface InvoiceTableProps {
  setSelectedInvoice: Dispatch<SetStateAction<DBInboundInvoice | null>>;
  selectedInvoice: DBInboundInvoice | null;
  data: DBInboundInvoice[] | undefined;
}

export const InboundInvoiceTable = ({
  selectedInvoice,
  setSelectedInvoice,
  data,
}: InvoiceTableProps) => {
  const openOrderDetails = (invoice: DBInboundInvoice) => {
    setSelectedInvoice(invoice);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '8px',
        borderRadius: 0,
      }}
    >
      <StyledTable aria-label="simple table">
        <StyledTableHead>
          <StyledTableRowHeader>
            <Tooltip
              title={
                // isOrderDesc
                //   ? 'Click to sort ascending':
                'Click to sort descending'
              }
              placement="top"
              componentsProps={{
                tooltip: { style: { background: '#1B2130' } },
                arrow: { style: { color: '#1B2130' } },
              }}
              followCursor
              arrow
            >
              <CellWithSort>ID</CellWithSort>
            </Tooltip>

            <StyledTableHeaderCell>Invoice number</StyledTableHeaderCell>

            <StyledTableHeaderCell>Supplier</StyledTableHeaderCell>
            <StyledTableHeaderCell>Branch</StyledTableHeaderCell>
            <StyledTableHeaderCell>Remark</StyledTableHeaderCell>
            <StyledTableHeaderCell>Created by</StyledTableHeaderCell>
          </StyledTableRowHeader>
        </StyledTableHead>
        <TableBody>
          {data &&
            data.map((invoice: DBInboundInvoice) => {
              const getTableRowColor = () => {
                if (selectedInvoice?.id === invoice.id) return '#E4F5FE';
                return '#fff';
              };

              const createdBy =
                invoice.createdBy as unknown as InvoiceCreatedBy;

              return (
                <StyledTableRow
                  sx={{
                    background: getTableRowColor(),
                    '&:hover': {
                      background: '#F9FAFB',
                      borderBottom: '1.5px solid rgba(224, 224, 224, 1)',
                      cursor: 'pointer',
                    },
                    position: 'relative',
                  }}
                  onClick={() => openOrderDetails(invoice)}
                  key={invoice.id}
                >
                  <StyledTableCell
                    style={{
                      width: '180px',
                    }}
                  >
                    <CenterStatus>{invoice.internalReference}</CenterStatus>
                    <CenterStatus>
                      <CellDetails>
                        {`${moment(invoice.createdAt).format(
                          'DD.MM.YYYY - h:mm'
                        )}`}
                      </CellDetails>
                    </CenterStatus>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '120px' }}>
                    <div>{invoice?.supplierInvoice ?? ''}</div>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '180px' }}>
                    <div style={{ color: 'black' }}>{invoice.supplierName}</div>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '180px' }}>
                    <div style={{ color: 'black' }}>{invoice.branchName}</div>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '150px' }}>
                    {invoice.internalRemark}
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '150px' }}>
                    {createdBy && createdBy.firstName && createdBy.lastName
                      ? `${createdBy.firstName} ${createdBy.lastName}`
                      : ''}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(Table)`
  white-space: nowrap;
`;

const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledTableRowHeader = styled(TableRow)`
  background: #ecf0f4;
`;

export const OrderStatus = styled.div<{
  color: string;
}>`
  width: fit-content;
  border-radius: 100px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 24px;
  background: ${(prop) => prop.color};
`;

const StyledTableRow = styled(TableRow)`
  height: 60px;
`;

const CellWithSort = styled(TableCell)`
  text-align: center;
`;

const CellDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const CenterStatus = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

const StyledTableCell = styled(TableCell)<{
  isFavorited?: boolean;
  opacity?: boolean;
}>`
  border-bottom: ${(props) => (props.isFavorited ? '1px solid #FFCC4D' : '')};
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-family: Barlow;
  text-align: start;
  opacity: ${(props) => (props.opacity ? '0.3' : '1')};
`;

const StyledTableHeaderCell = styled(TableCell)`
  padding: 0;
  text-align: start;
  padding-left: 16px;
`;
