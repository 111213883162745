import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { TRule } from '../typings';
import { INTERCARS_PRICELIST_RULESET } from './intercars/pricelist';
import { INTERCARS_STOCKLIST_RULESET } from './intercars/stocklist';

export const INTERCARS_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: INTERCARS_PRICELIST_RULESET,
  stockRuleset: INTERCARS_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
      dataSource: 'INTERCARS',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'INTERCARS',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {},
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const headers = [
      'Manufacturer_Code',
      'Quantity',
      'Purchase_Price_Netto',
      'Purchase_Price_MPC',
    ];
    const fileWithHeader = `${headers.join(';')}\n${file}`;

    const json = await csv2json({ delimiter: ';' }).fromString(fileWithHeader);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          (item['Manufacturer_Code'].length === 6 ||
            item['Manufacturer_Code'].length === 7) &&
          item['Manufacturer_Code'].endsWith('00')
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const headers = [
      'Manufacturer_Code',
      'Quantity',
      'Purchase_Price_Netto',
      'Purchase_Price_MPC',
    ];
    const fileWithHeader = `${headers.join(';')}\n${file}`;

    const json = await csv2json({ delimiter: ';' }).fromString(fileWithHeader);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          (item['Manufacturer_Code'].length === 6 ||
            item['Manufacturer_Code'].length === 7) &&
          item['Manufacturer_Code'].endsWith('00') &&
          Number(item['Quantity']) > 0
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          (item['Manufacturer_Code'].length === 6 ||
            item['Manufacturer_Code'].length === 7) &&
          item['Manufacturer_Code'].endsWith('00') &&
          Number(item['Quantity']) > 0
      ),
      this.preprocessKeys
    );
  },
};
