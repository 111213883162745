import styled from '@emotion/styled/macro';
import {
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import api from '@tyrio/api-factory';
import {
  BranchesEdiShape,
  DBBranchesApi,
  DBBranchResponse,
  DBSupplierClientSettingsApi,
} from '@tyrio/dto';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface SupplierClientBranchesProps {
  supplierId: string;
  initialBranchEdiConfig: Record<number, BranchesEdiShape>;
  setPayloads: Dispatch<
    SetStateAction<DBSupplierClientSettingsApi['create']['requestBody']>
  >;
}

const handleActive = (
  id: number,
  setIsActive: Dispatch<SetStateAction<Record<number, BranchesEdiShape>>>
) => {
  setIsActive((prevState) => ({
    ...prevState,
    [id]: {
      active: !prevState[id]?.active ?? true,
      partyId: prevState[id]?.partyId ?? '',
    },
  }));
};

const renderRow = (
  branchName: string,
  id: number,
  supplierId: string,
  setBranchEdiConfig: Dispatch<
    SetStateAction<Record<number, BranchesEdiShape>>
  >,
  branchEdiConfig: Record<number, BranchesEdiShape>
) => {
  return (
    <RowWrapper key={`${id}-${branchName}`}>
      <FormControlLabel
        control={
          <Switch
            data-cy="switch"
            color="info"
            onChange={() => handleActive(id, setBranchEdiConfig)}
            checked={branchEdiConfig[id]?.active ?? false}
            disabled={false}
          />
        }
        label={''}
      />
      <div style={{ width: '200px' }}>{branchName}</div>
      <div style={{ width: '300px' }}>
        <TextField
          id={`party-id-${supplierId ?? 'NONE'}-${id ?? 'NONE'}`}
          label="Party ID"
          variant="outlined"
          disabled={!branchEdiConfig[id]?.active ?? true}
          value={branchEdiConfig[id]?.partyId ?? ''}
          onChange={(e) => {
            setBranchEdiConfig((prevState) => ({
              ...prevState,
              [id]: { active: prevState[id].active, partyId: e.target.value },
            }));
          }}
        />
      </div>
    </RowWrapper>
  );
};

export const SupplierClientBranches = ({
  supplierId,
  initialBranchEdiConfig,
  setPayloads,
}: SupplierClientBranchesProps) => {
  const [branchEdiConfig, setBranchEdiConfig] = useState<
    Record<number, BranchesEdiShape>
  >(initialBranchEdiConfig ?? {});
  const [branches, setBranches] = useState<DBBranchResponse[]>([]);

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess(branchesData) {
        setBranches(branchesData.data.filter((item) => item.isActive));
      },
    }
  );

  useEffect(
    function setPayload() {
      setPayloads((prevState) => ({
        ...prevState,
        branchEdiConfiguration: branchEdiConfig,
      }));
    },
    [branchEdiConfig, setPayloads]
  );

  return (
    <Wrapper>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '28px',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.3px',
          color: '#212B36',
          marginBottom: '9px',
        }}
      >
        EDI Configuration
      </Typography>
      <Divider light />
      <ItemsWrapper>
        {branches &&
          branches.map((item) =>
            renderRow(
              item.branchName,
              item.id,
              supplierId,
              setBranchEdiConfig,
              branchEdiConfig
            )
          )}
      </ItemsWrapper>
    </Wrapper>
  );
};

const RowWrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Wrapper = styled.div`
  height: 100%;
  padding: 16px;
`;

const ItemsWrapper = styled.div`
  margin-top: 20px;
`;
