import { InboundInvoiceOverview } from '../../../features/inbound-invoice-dashboard/InboundInvoiceOverview';

interface InboundInvoiceProps {
  sidebarOpen: boolean;
}

const InboundInvoice = ({ sidebarOpen }: InboundInvoiceProps) => {
  return <InboundInvoiceOverview sidebarOpen={sidebarOpen} />;
};

export default InboundInvoice;
