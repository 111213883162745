import styled from '@emotion/styled';
import { SeasonIcon } from '@tyrio/products';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useFilter } from '../../../../../hooks/useFilter';
import {
  ColumnCardWrapper,
  HeaderText,
} from '../../../steps/Search/SelectStepper';
import { PosSearchContext } from '../SearchContext';
import { POSContext } from '../../../../../context/POSContext';

export const SeasonSelector = () => {
  const { ...filtersHook } = useFilter();
  const { selectedSizes } = useContext(PosSearchContext);
  const { setSupplierStockItems } = useContext(POSContext);

  const [summer, setSummer] = useState(false);
  const [allSeason, setAllSeason] = useState(false);
  const [winter, setWinter] = useState(false);

  const get = (path: string) =>
    _.get(filtersHook.filterValues, path, []) as string[];

  const selectedSeasons: string[] = get('season');

  const handleChangeSeasonValue = (value: boolean, key: string) => {
    let seasonValues = selectedSeasons;

    if (value) seasonValues.push(key);
    else seasonValues = selectedSeasons.filter((el) => el !== key);

    filtersHook.setFilterValue({
      ...filtersHook.filterValues,
      size: selectedSizes as string[],
      season: seasonValues,
      supplierStockPage: undefined,
    });

    setSupplierStockItems({ matchedSets: [], unmatchedItems: [] });
  };

  useEffect(() => {
    setSummer(selectedSeasons.includes('summer'));
    setWinter(selectedSeasons.includes('winter'));
    setAllSeason(selectedSeasons.includes('allseason'));
  }, [selectedSeasons]);

  return (
    <ColumnCardWrapper>
      <HeaderText>Filter season</HeaderText>

      <SeasonCard
        onClick={() => {
          setSummer(!summer);
          handleChangeSeasonValue(!summer, 'summer');
        }}
        selected={summer}
      >
        <StyledCheckbox
          type="checkbox"
          checked={summer}
          onChange={() => null}
        />
        Summer tires
        <ImageWrapper>
          <SeasonIcon season="summer" />
        </ImageWrapper>
      </SeasonCard>

      <SeasonCard
        onClick={() => {
          setAllSeason(!allSeason);
          handleChangeSeasonValue(!allSeason, 'allseason');
        }}
        selected={allSeason}
      >
        <StyledCheckbox
          type="checkbox"
          checked={allSeason}
          onChange={() => null}
        />
        All-Season tires
        <ImageWrapper>
          <SeasonIcon season="all" />
        </ImageWrapper>
      </SeasonCard>

      <SeasonCard
        onClick={() => {
          setWinter(!winter);
          handleChangeSeasonValue(!winter, 'winter');
        }}
        selected={winter}
      >
        <StyledCheckbox
          type="checkbox"
          checked={winter}
          onChange={() => null}
        />
        Winter tires
        <ImageWrapper>
          <SeasonIcon season="winter" />
        </ImageWrapper>
      </SeasonCard>
    </ColumnCardWrapper>
  );
};

const SeasonCard = styled.button<{ selected: boolean }>`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-radius: 8px;
  margin-top: 16px;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? 'rgba(25, 118, 210, 0.15)' : 'none'};
  border: ${(props) =>
    props.selected ? '2px solid #1976d2' : '1px solid #dfe3e8'};

  &:hover {
    border: 2px solid #1976d2;
  }
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
`;

const ImageWrapper = styled.div`
  margin-top: 15px;
`;
