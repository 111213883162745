import { DBUserRole } from '@prisma/client';
import { UserRolePermissions } from '@tyrio/forms';

export interface FormData {
  roleName: string;
  roleDescription: string;
  type: DBUserRole;
  permissions: UserRolePermissions[];
  active?: boolean;
}

export const defaultAdminPermissions = [
  {
    id: '1',
    label: 'Admin Users',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: '2',
    label: 'Suppliers',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: '3',
    label: 'Clients',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: '4',
    label: 'Shipping Couriers',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: '5',
    label: 'Catalogues',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '51',
        label: 'Product Items',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '52',
        label: 'Product Categories',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '53',
        label: 'Product Attributes',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '54',
        label: 'Import Status',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: '6',
    label: 'Settings',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '61',
        label: 'Roles',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '62',
        label: 'Countries',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
];

export const defaultClientPermissions = [
  {
    id: '1',
    label: 'Sales',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '11',
        label: 'POS',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '12',
        label: 'Appointments',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '13',
        label: 'Customer Orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '14',
        label: 'Price Calculation',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '15',
        label: 'Custom Prices',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '16',
        label: 'Customer Rebates',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: '2',
    label: 'Purchasing',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '21',
        label: 'Price Compare',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '22',
        label: 'Purchase Orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '23',
        label: 'Supplier Settings',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: '3',
    label: 'WMS',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '31',
        label: 'Stock locations',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '32',
        label: 'Warehouse locations',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '33',
        label: 'Stock list',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '34',
        label: 'Transfer orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '35',
        label: 'Stock IN',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '36',
        label: 'Stock list import',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '37',
        label: 'Stock out dashboard',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '38',
        label: 'Inbound invoice',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: '4',
    label: 'Catalogues',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '41',
        label: 'Product Items',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '42',
        label: 'Services',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '43',
        label: 'Partners',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: '5',
    label: 'Settings',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: '51',
        label: 'General',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '52',
        label: 'Company Info',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '53',
        label: 'Printers',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '54',
        label: 'Documents look',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '55',
        label: 'Branches',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '56',
        label: 'EDI Order configuration',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '57',
        label: 'Users',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '58',
        label: 'Roles',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '59',
        label: 'Delivery methods',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '591',
        label: 'Payment methods',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: '592',
        label: 'Shipping couriers',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
];
