import { TRule } from '../../typings';

export const INTERCARS_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.operate',
    sourceKey: "c.['Manufacturer_Code']",
    destination: 'product',
    targetKey: 'ean',
    operationName: 'getIntercarsEAN',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Manufacturer_Code']",
    destination: 'product',
    targetKey: 'manufacturerCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Purchase_Price_Netto']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Purchase_Price_MPC']",
    destination: 'product',
    targetKey: 'suggestedRetailPrice',
    outputType: 'number',
  },
];
