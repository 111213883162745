import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import {
  DBCurrency,
  DBInvoiceType,
  DBParnerDeliveryTermsTypes,
  DBPartnerSupplierType,
  DBRebateGroupType,
  DBTrafficType,
} from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBCountryApi,
  DBPartnerApi,
  DBSupplierClientSettingsApi,
  PartnerBanksShape,
  PartnerContactsShape,
  PartnerLocationsShape,
} from '@tyrio/dto';
import {
  FormController,
  GetValueProps,
  generatePartnerForm,
} from '@tyrio/forms';
import {
  DeleteModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioSelectInputOption,
} from '@tyrio/ui-library';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import * as z from 'zod';
import { parseEnumToArray } from '../../../../../../helpers/enum-parser';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../../../components/PageTemplate/PageTemplate';
import { remapToIds } from '../../../../features/client-form/ClientForm';
import { useGetRebates } from '../../../../features/price-calculation/queries/get-rebates';
import _ from 'lodash';
import { transportModeData } from '../../../../helpers/transport-mode';
import { AxiosError } from 'axios';

const PartnerDetailsForm = () => {
  const { partnerId } = useParams<{ partnerId: string }>();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: initialPartnerData,
    isFetching,
    refetch: getPartnerById,
  } = useQuery(
    ['get_partner_by_id', partnerId, 'update_partner'],
    () =>
      api.fetch<DBPartnerApi['getOne']>(`get_partner_by_id`, {
        partnerId,
      }),
    {
      enabled: !!partnerId,
    }
  );

  const [suppliersData, setSuppliersData] = useState<TyrioSelectInputOption[]>(
    []
  );

  useQuery(
    ['get_supplier_client_settings'],
    async () => {
      return await api.fetch<DBSupplierClientSettingsApi['list']>(
        'get_supplier_client_settings',
        {
          search: '',
          pageSize: 100,
        }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.data.map((item) => ({
          label: item.supplier?.companyShortName || '',
          value: item.id,
        }));

        setSuppliersData(dropdownItems);
      },
    }
  );

  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);

  const { rebatesData } = useGetRebates({
    enabled: true,
  });

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  const createPartnerMutation = useMutation(
    (partner: DBPartnerApi['create']['requestBody']) =>
      api.fetch<DBPartnerApi['create']>('create_partner', {
        ...partner,
      }),
    {
      mutationKey: 'create_partner',
      onSuccess: (data) => {
        queryClient.refetchQueries('get_partners');
        ToastHelper.showToast(
          'Partner',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/partners/${data.id}`);
      },

      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Partner',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  // UPDATE PARTNER data
  const updatePartnerMutation = useMutation(
    (partner: DBPartnerApi['updateOne']['request']) =>
      api.fetch<DBPartnerApi['updateOne']>('update_partner', {
        partnerId: partnerId,
        ...partner,
      }),
    {
      mutationKey: 'update_partner',
      onSuccess: () => {
        queryClient.refetchQueries('get_partners');
        queryClient.refetchQueries('get_partner_by_id');

        getPartnerById();

        ToastHelper.showToast(
          'Partner',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Partner',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const deletePartnerMutation = useMutation(
    () =>
      api.fetch<DBPartnerApi['getOne']>('delete_partner', {
        partnerId,
      }),
    {
      mutationKey: 'delete_partner',
      onSuccess: () => {
        queryClient.invalidateQueries('get_partners');
        history.push(`/dashboard/partners`);
        ToastHelper.showToast(
          'Partner',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Partner',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const [selectedType, setSelectedType] = useState<{
    customer: boolean;
    supplier: boolean;
  }>({
    customer: false,
    supplier: false,
  });

  useEffect(() => {
    setSelectedType({
      customer: initialPartnerData?.customerPartnerType as boolean,
      supplier: initialPartnerData?.supplierPartnerType as boolean,
    });
  }, [initialPartnerData]);

  const onChangeValue = (d: GetValueProps) => {
    if (d.id === 'supplierPartnerType')
      setSelectedType({
        ...selectedType,
        supplier: d.value,
      });
    else if (d.id === 'customerPartnerType')
      setSelectedType({
        ...selectedType,
        customer: d.value,
      });
  };

  const deliveryTermsTypes = Object.values(DBParnerDeliveryTermsTypes).map(
    (item) => ({
      label: item.toUpperCase(),
      value: item,
    })
  );

  const isVisible = _.isUndefined(partnerId);

  const { form, zodSchema } = useMemo(
    () =>
      generatePartnerForm(
        initialPartnerData?.viesValidated ?? false,
        countries,
        suppliersData,
        rebatesData,
        parseEnumToArray(DBRebateGroupType),
        parseEnumToArray(DBCurrency),
        parseEnumToArray(DBInvoiceType),
        parseEnumToArray(DBPartnerSupplierType),
        deliveryTermsTypes,
        transportModeData,
        isVisible,
        selectedType,
        initialPartnerData?.countryId ?? ''
      ),
    [
      initialPartnerData?.viesValidated,
      initialPartnerData?.countryId,
      countries,
      suppliersData,
      rebatesData,
      deliveryTermsTypes,
      isVisible,
      selectedType,
    ]
  );

  const handleUpsertPartnerForm = async (data: z.infer<typeof zodSchema>) => {
    const transportMode = transportModeData
      .find((item) => item.value === data.transportMode)
      ?.label.replace(/\d+ - /, '')
      .replaceAll(' ', '_')
      .toUpperCase() as DBTrafficType;

    const body = {
      active: true,
      supplierId: data.supplierId,
      companyOfficialName: data.companyOfficialName,
      businessType: data.businessType,
      companyDisplayName: data.companyDisplayName,
      vatPostingGroup: data.vatPostingGroup,
      viesValidated: data.viesValidated ?? false,
      companyRegistrationNumber: data.companyRegistrationNumber,
      viesVatNumber: data.viesVatNumber,
      zipCode: data.zipCode,
      city: data.city,
      address: data.address,
      remark: data.remark,
      erpId: data.erpId,
      countryId: data.countryId,
      DBPartnerContact:
        data.partnerContacts &&
        Object.values(data.partnerContacts).map((contact) => {
          return {
            partnerId: null,
            id: contact.id ?? '',
            department: contact.department ?? null,
            businessPhone: contact.businessPhone ?? null,
            mobilePhone: contact.mobilePhone ?? null,
            remark: contact.remark ?? null,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
          };
        }),
      DBPartnerBank:
        data.partnerBanks &&
        Object.values(data.partnerBanks).map((bank) => {
          return {
            partnerId,
            id: bank.id ?? '',
            bankName: bank.bankName,
            iban: bank.iban,
            swift: bank.swift,
            currency:
              (bank.currency as unknown as DBCurrency) ?? DBCurrency.EUR,
          };
        }),
      customerPartnerType: data.customerPartnerType,
      supplierPartnerType: data.supplierPartnerType,
      rebateCalculationId: data.rebateCalculationId,
      customerType: data.customerType as DBRebateGroupType,
      paymentDelay: Number(data.paymentDelay),
      creditLimit: Number(data.creditLimit),
      currency: data.currency as DBCurrency,
      invoices: data.invoices?.map((item) => item.value) as DBInvoiceType[],
      emails: data.emails,
      supplierType: data.supplierType as DBPartnerSupplierType,
      deliveryTermsType: data.deliveryTermsType as DBParnerDeliveryTermsTypes,
      transactionNature: Number(data.transactionNature),
      transportMode: transportMode ?? undefined,
      paymentTerms: data.paymentTerms as DBCurrency,
      DBPartnerLocation:
        data.partnerLocations &&
        Object.values(data.partnerLocations).map((location) => {
          return {
            partnerId,
            id: location.id ?? '',
            locationName: location.locationName,
            zipCode: location.zipCodeLocation,
            address: location.addressLocation,
            city: location.cityLocation,
            email: location.emailLocation ?? '',
            businessPhone: location.businessPhoneLocation ?? '',
            invoiceAddress: location.invoiceAddress ?? true,
            shippingAddress: location.shippingAddress ?? true,
            erpId: location.erpId ?? '',
          };
        }),
      customerVat: data.customerVat,
      supplierVat: data.supplierVat,
    };

    let result = undefined;
    if (partnerId) {
      const partner_data: DBPartnerApi['updateOne']['request'] = {
        ...body,
        partnerId,
      };

      await updatePartnerMutation.mutateAsync(partner_data).then((res) => {
        result = res;
      });
    } else {
      const partner_data: DBPartnerApi['create']['request'] = {
        ...body,
        active: true,
      };

      await createPartnerMutation.mutateAsync(partner_data).then((res) => {
        result = res;
      });
    }
    return result !== undefined;
  };

  const handleOnCancel = () => {
    history.push(`/dashboard/partners`);
  };

  const handleDeletePartner = () => {
    if (partnerId) setIsDeleteModalVisible(true);
  };

  const handleChangePartnerStatus = () => {
    if (partnerId)
      updatePartnerMutation.mutate({
        partnerId,
        active: !initialPartnerData?.active,
      });
  };

  const initialModeOfTransport = useMemo(() => {
    return transportModeData.find((item) =>
      item.label.toLowerCase().includes(
        initialPartnerData?.transportMode?.toLowerCase().replaceAll('_', ' ') ??
          '  ' // leave this as it is (3 spaces)
      )
    );
  }, [initialPartnerData?.transportMode]);

  return (
    <PageTemplateContent>
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            deletePartnerMutation.mutate();
            setIsDeleteModalVisible(false);
          }}
          itemName={initialPartnerData?.companyOfficialName ?? 'company'}
        />
      )}
      {isFetching && partnerId ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <FormController<z.infer<typeof zodSchema>>
          form={form}
          key={initialPartnerData?.id || 'new'}
          initialValues={{
            companyOfficialName: initialPartnerData?.companyOfficialName,
            businessType: initialPartnerData?.businessType ?? '',
            vatPostingGroup: initialPartnerData?.vatPostingGroup ?? 'domestic',
            companyRegistrationNumber:
              initialPartnerData?.companyRegistrationNumber,
            viesVatNumber: initialPartnerData?.viesVatNumber,
            zipCode: initialPartnerData?.zipCode,
            city: initialPartnerData?.city,
            address: initialPartnerData?.address,
            // For now only option is Croatia from seeds.
            countryId: initialPartnerData?.countryId ?? '',
            remark: initialPartnerData?.remark,
            erpId: initialPartnerData?.erpId,
            companyDisplayName: initialPartnerData?.companyDisplayName ?? '',
            customerPartnerType:
              initialPartnerData?.customerPartnerType ?? false,
            supplierPartnerType:
              initialPartnerData?.supplierPartnerType ?? false,
            rebateCalculationId: initialPartnerData?.rebateCalculationId ?? '',
            customerType: initialPartnerData?.customerType ?? undefined,
            paymentDelay: initialPartnerData?.paymentDelay ?? 0,
            creditLimit: initialPartnerData?.creditLimit ?? 0,
            currency: initialPartnerData?.currency ?? undefined,
            invoices: initialPartnerData?.invoices
              ? (
                  initialPartnerData?.invoices as unknown as DBInvoiceType[]
                ).map((item) => ({
                  label: item.toString(),
                  value: item,
                }))
              : [],
            emails: initialPartnerData?.emails ?? '',
            supplierType: initialPartnerData?.supplierType ?? undefined,
            supplierId: initialPartnerData?.supplierId ?? undefined,
            deliveryTermsType:
              initialPartnerData?.deliveryTermsType ?? undefined,
            transactionNature:
              initialPartnerData?.transactionNature?.toString() ?? undefined,
            transportMode:
              initialModeOfTransport && initialModeOfTransport !== null
                ? initialModeOfTransport
                : undefined,
            paymentTerms: initialPartnerData?.paymentTerms ?? undefined,
            partnerContacts: initialPartnerData?.DBPartnerContact
              ? (remapToIds(
                  initialPartnerData?.DBPartnerContact
                ) as unknown as PartnerContactsShape)
              : undefined,
            partnerBanks: initialPartnerData?.DBPartnerBank
              ? (remapToIds(
                  initialPartnerData?.DBPartnerBank
                ) as unknown as PartnerBanksShape)
              : undefined,
            partnerLocations: initialPartnerData?.DBPartnerLocation
              ? (remapToIds(
                  initialPartnerData?.DBPartnerLocation.map((location) => {
                    return {
                      id: location.id,
                      locationName: location.locationName,
                      zipCodeLocation: location.zipCode,
                      addressLocation: location.address,
                      cityLocation: location.city,
                      invoiceAddress: location.invoiceAddress,
                      shippingAddress: location.shippingAddress,
                      emailLocation: location.email,
                      businessPhoneLocation: location.businessPhone,
                      erpId: location.erpId,
                    };
                  })
                ) as unknown as PartnerLocationsShape)
              : undefined,
          }}
          zodSchema={zodSchema}
          onSubmit={(data) => handleUpsertPartnerForm(data)}
          onCancel={handleOnCancel}
          onDelete={handleDeletePartner}
          onChangeStatus={handleChangePartnerStatus}
          active={initialPartnerData ? initialPartnerData?.active : true}
          title={
            initialPartnerData?.companyOfficialName ?? 'Create new partner'
          }
          onChangeValue={onChangeValue}
          objectId={initialPartnerData?.id}
          shouldReset={false}
        />
      )}
    </PageTemplateContent>
  );
};
export default PartnerDetailsForm;
