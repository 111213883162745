import { DBTrafficType } from '@prisma/client';
import { parseEnumToArray } from '../../../helpers/enum-parser';

const getValueByTrafficType = (key: DBTrafficType) => {
  switch (key) {
    case DBTrafficType.SEA_TRANSPORT:
      return 1;
    case DBTrafficType.RAIL_TRANSPORT:
      return 2;
    case DBTrafficType.ROAD_TRANSPORT:
      return 3;
    case DBTrafficType.AIR_TRANSPORT:
      return 4;
    case DBTrafficType.POSTAL_CONSIGNMENT:
      return 5;
    case DBTrafficType.FIXED_TRANSPORT_INSTALLATIONS:
      return 7;
    case DBTrafficType.INLAND_WATERWAY_TRANSPORT:
      return 8;
    case DBTrafficType.OWN_PROPULSION:
      return 9;

    default:
      return 0;
  }
};

export const transportModeData = parseEnumToArray(DBTrafficType)
  .map((item) => ({
    value: getValueByTrafficType(item.value as DBTrafficType) as number,
    label: `${getValueByTrafficType(item.value as DBTrafficType) as number} - ${
      item.label
    }`,
  }))
  .sort((a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });
