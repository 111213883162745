import { Box, Typography } from '@mui/material';
import { Code, DBWarehouseSubzones } from '@prisma/client';
import { SubZones } from '@tyrio/dto';
import _ from 'lodash';
import { useContext } from 'react';
import { WarehouseContext } from '../../../../context/WarehouseContext';
import {
  ActiveStep,
  codesByStagingType,
  Container,
  stagings,
  types,
} from './SelectCode';

interface SelectStagingTypeProps {
  code: Code | null;
  setCode: (val: Code | null) => void;
  type: string | null;
  setType: (val: string | null) => void;
  step: ActiveStep;
  setStep: (val: ActiveStep) => void;
}

export const SelectStagingType = (props: SelectStagingTypeProps) => {
  const { warehouse, selected } = useContext(WarehouseContext);

  const { type, setType, step, setCode } = props;

  const _zones = () => {
    return warehouse?.subzones?.filter(
      (item) => item.zoneId === selected.locations.zone?.id
    ) as DBWarehouseSubzones[];
  };

  const isDisabled = (i: string, t: string) => {
    return (
      _zones().filter(
        (item) =>
          item.code === i ||
          (item as unknown as SubZones).settings.stagingType === t
      ).length > 0
    );
  };

  return (
    <Box>
      {step === 'type' && (
        <Box sx={{ width: '100%', display: 'flex', gap: '5px' }}>
          {types.map((item) => {
            const isSelected = item === type;

            return (
              <Container
                sx={{ width: '150px' }}
                key={item}
                onClick={() => {
                  setType(item as string);
                }}
                background={isSelected ? '#1976D2' : 'white'}
                isPredefined={false}
                isDisabled={false}
                isSelected={isSelected}
              >
                <Typography
                  fontSize={20}
                  fontWeight={400}
                  color={isSelected ? '#fff' : '#152B47'}
                >
                  {item.toUpperCase()}
                </Typography>
              </Container>
            );
          })}
        </Box>
      )}
      {step === 'staging' && (
        <Box
          display="flex"
          flexWrap="wrap"
          gap="8px"
          // padding="8px"
          // paddingRight="4px"
        >
          {stagings
            .filter(
              (item) =>
                !['STATIC_STORAGE', 'MOBILE_STORAGE'].includes(
                  item.value as unknown as string
                )
            )
            .map((item) => {
              const isSelected = (item.value as unknown as string) === type;

              const code = _.get(codesByStagingType, item.value);

              const _isDisabled = isDisabled(code, item.value);

              const getBgColor = () => {
                return _isDisabled
                  ? '#F9FAFB'
                  : isSelected
                  ? '#1976D2'
                  : 'white';
              };

              return (
                <Container
                  sx={{ width: '171px' }}
                  key={item.value as unknown as string}
                  onClick={() => {
                    if (!_isDisabled) {
                      setType(item.value as unknown as string);
                      setCode(code);
                    }
                  }}
                  background={getBgColor()}
                  isPredefined={false}
                  isDisabled={_isDisabled}
                  isSelected={isSelected}
                >
                  <Typography
                    fontSize={24}
                    fontWeight={600}
                    color={isSelected ? '#fff' : '#152B47'}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={600}
                    color={isSelected ? '#fff' : '#152B47'}
                  >
                    {code}
                  </Typography>
                </Container>
              );
            })}
        </Box>
      )}
    </Box>
  );
};
