import styled from '@emotion/styled/macro';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Divider } from '@mui/material';
import { CancelModal } from '@tyrio/ui-library';
import { useContext } from 'react';
import { InboundInvoiceContext } from '../../../context/InboundInvoiceContext';

interface FooterProps {
  onSubmit: (data: unknown) => void;
  onCancel: () => void;
}

export const Footer = ({ onSubmit, onCancel }: FooterProps) => {
  const { isCancelModalOpen, setIsCancelModalOpen } = useContext(
    InboundInvoiceContext
  );

  return (
    <FooterWrapper>
      {isCancelModalOpen && (
        <CancelModal
          LBAction={() => setIsCancelModalOpen(false)}
          RBAction={() => {
            setIsCancelModalOpen(false);
            onCancel();
          }}
        />
      )}
      <Wrapper>
        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: '2px',
            borderColor: '#E6E8F0',
          }}
        />

        <ButtonWrapper>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsCancelModalOpen(true)}
            sx={{ width: '150px', height: '35px' }}
            disableElevation
            startIcon={<CancelIcon />}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={onSubmit}
            sx={{ width: '150px', height: '35px' }}
            disableElevation
          >
            SUBMIT
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  position: sticky;
  top: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  width: 100%;
`;
